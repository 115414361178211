.website-footer {
  font-size: 0.7rem;
  font-style: italic;
  text-align: center;
  padding: 0 5% 1% 5%;
  p {
    padding-bottom: 1%;
  }
}

#page-with-sidebar {
  margin-left: calc(220px + 5%);
}

#page-without-sidebar {
  margin-left: calc(5%);
}


@media screen and (max-width: 820px) {
  
  .website-footer {
    p {
      padding: 3% 0% 3% 0%;
    }
  }

}