.fade.agent-modal.modal.show {
  margin-left: 0%;
  margin-top: 0% !important;
  width: 40%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  border-radius: 0px !important;

  // box-shadow: 0px 10px 10px 100vh rgba(0, 0, 0, 0.6) !important;
  .modal-dialog {
    padding: 2% 2% 2% 2%;

    .modal-content {

      .modal-body {
        color: #1A276C;

        .header-section {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5%;

          h3 {
            font-size: 1rem;
          }

          .close-button {
            cursor: pointer;
          }
        }

        .body-section {
          .body-row {
            margin-bottom: 5%;

            h4 {
              font-size: 0.9rem;
              margin-bottom: 1%;
              font-weight: 600;

            }

            input {
              width: 100%;
              font-size: 0.8rem;
              padding: 5px;
              border: 1px solid #1A276C;
              color: #1A276C;
            }

          }

          h3 {
            font-size: 1rem;
          }
        }

        .waitlist-close {
          background-color: #ED6B86;
          border-radius: 5px;
          border: 1px solid #ED6B86;
          padding: 5px;
          color: #FDF7F0;
          font-weight: bold;
          font-size: 1rem;
          margin-top: 2%;
          cursor: pointer;
        }

        .error {
          color: #ED6B86;
          font-weight: bold;
          font-size: 0.7rem;
          margin-top: 1%;
        }

        #submit {
          color: #1A276C;
        }

        .agent-footer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 5%;
          padding-top: 10%;
          height: 130px;


          .logo-section {
            margin-bottom: 10%;
            width: 260px;
            height: 110px;
            display: flex;
            flex-direction: row;
            justify-content: center;

            .wittle-logo {
              width: 80%;
              height: 80%;
              background-image: url('../../../../public/white_icons/login-logo.svg');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              cursor: pointer;
            }
          }
        }

      }
    }

  }
}



@media screen and (max-width: 1100px) {
  .fade.agent-modal.modal.show {
    // margin-top: 40% !important;
    width: 60% !important;
  }
}



@media screen and (max-width: 600px) {
  .fade.agent-modal.modal.show {
    border-radius: 0px !important;
    margin-top: 0% !important;
    margin-left: 0% !important;
    width: 100% !important;
    height: 100% !important;
  }
}