// Overall page wrapper containing all content
.property-detail-pages {
  background-color: #FDF7F0;
  min-height: 100vh;


  .property-main-page {
    display: flex;
    flex-direction: column;

    // title section showing the variables users have selected
    .title-section {
      position: fixed;
      width: 200px;
      top: 0%;
      background-color: #FFA7E5;
      border-right: 2px solid #efe7df;
      padding-top: 0%;
      height: 100vh;
      color: #051885;

      .logo-section {
        background-color: #FFA7E5;
        top: 0;
        height: 72px;
        display: flex;
        padding-left: 15px;
        align-items: center;

        .logo {
          h2 {
            font-size: 2.2rem;
            color: #051885;
          }
        }
      }

      .closed-sidebar {
        background-color: #FFA7E5;
        height: 100vh;

        .sidebar-button {
          position: fixed;
          left: 5px;
          bottom: 10px;

          button {
            background-color: #FFA7E5;
            border: 1px solid white;
            border-radius: 4px;
            padding: 8px 10px 8px 10px;
            color: white;
            font-weight: 700;
            cursor: pointer;
          }
        }
      }

      h3 {
        padding-bottom: 10%;
        padding-top: 30px;
        padding-left: 15px;
        color: #051885;
      }

      .input-sections {
        margin-bottom: 10%;
        padding-left: 15px;
      }

      h5 {
        font-weight: 600;
        margin-bottom: 5%;
      }

      .poi {
        display: flex;
        align-items: center;
        margin-bottom: 4%;

        p {
          font-size: 0.6rem;
        }

        .tickbox {
          width: 10px;
          height: 10px;
          border: 1px solid #FFA7E5;
          margin-right: 5%;
        }
      }

      .sidebar-button {
        position: fixed;
        left: 165px;
        bottom: 10px;

        button {
          background-color: #051885;
          border: 1px solid #FDF7F0;
          border-radius: 4px;
          padding: 8px 10px 8px 10px;
          color: #FDF7F0;
          font-weight: 700;
          cursor: pointer;
        }
      }

      .edit-button {
        background-color: #051885;
        border: 1px solid #FFA7E5;
        color: #FDF7F0;
        border-radius: 10px;
        padding: 5px;
        margin-left: 15px;
        font-weight: bold;
        cursor: pointer;
      }
    }


    // main body of page with property results
    .property-results {
      padding-top: 25px;
      display: flex;
      flex-direction: column;
      margin-left: 200px;
      .property-results-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 5%;
        padding-right: 5%;
        
        .title-centre {
          width: 57%;
          .property-count {
            text-align: center;
            color: black;
            font-weight: 700;
            font-size: 1.5rem;
            padding-left: 8%;
            color: #051885;

          }
        }
    
        .title-buttons {
          width: 250px;
          display: flex;
          button {
            // background-color: rgba(255, 167, 229, 0.2);
            background-color: transparent;
            border: 2px solid #FFA7E5;
            padding: 5px;
            border-radius: 10px;
            font-weight: bold;
            margin-right: 2%;
            width: 120px;
            cursor: pointer;
            color: #051885;

          }
          .mobile-filter-button {
            display: none;
          }
        }
  
      }

      .property-grid {
        max-width: 100%;
        margin-top: 2%;

        .property-card {
          width: 100%;
          padding: 0% 5% 0% 5%;
          margin-bottom: 3%;
          display: flex;
          flex-direction: row;
          height: 350px;

          .mobile-name {
            display: none;
          }
          .slide-section-desktop {
            width: 50%;
            height: 350px;

            button {
              // background-color: #FDF7F0;
            }
          }
          .slide-section-mobile {
            display: none;
          }

          .indicators {
            display: none;
          }
          .image-card {
            width: 100%;
            height: 350px;

            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .favourite-section {
              display: flex;
              justify-content: flex-end;
              padding-right: 2%;
              padding-bottom: 2%;
              .favourite-button-off {
                background-image: url('../../../../public/website_icons/beige-heart.png');
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 40px;
                height: 40px;
                cursor: pointer;
              }
              .favourite-button-on {
                background-image: url('../../../../public/website_icons/pink-heart.png');
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 40px;
                height: 40px;
                cursor: pointer;
              }
            }
            .property-image {}

            .property-image-details {
              height: auto;
              color: black;
              display: flex;
              width: 100%;
              justify-content: center;
              padding: 2% 5% 2% 5%;
              background-color: rgba(253, 247, 240, 0.9);
              cursor: pointer;
              color: #051885;

            }
          }


          .detail-section {
            width: 50%;
            padding-left: 5%;

            h2 {
              font-size: 1.3rem;
              margin-bottom: 1%;
              overflow: hidden;
              white-space: nowrap;
              color: #051885;
              cursor: pointer;
            }

            h4 {
              font-size: 1.1rem;
              color: #FFA7E5;
              cursor: pointer;
            }

            .property-buttons {
              display: flex;
              flex-direction: row;
              margin-top: 2%;
              margin-bottom: 2%;
              overflow-x: scroll;
              cursor: pointer;
              color: #051885;

              h5 {
                margin-right: 5%;
                font-size: 1rem;
                color: #051885;
              }
            }

            .property-button-expansion {
              overflow-y: scroll;
              height: 240px;

              .description-stats {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              }
              .insight-bullets {
                font-size: 0.8rem;
                color: #051885;

                font-weight: 700;
                margin-bottom: 2%;
                overflow: hidden;
                white-space: nowrap;

                span {
                  font-weight: 400;
                }
              }

              .description-text {
                font-size: 0.8rem;
                color: #051885;
                font-weight: 400;
                margin-bottom: 2%;
                overflow: scroll;


              }
            }
          }
        }

        hr {
          margin: 0 auto;
          margin-bottom: 3%;
          margin-left: 5%;
          margin-right: 5%;
          border: 1px solid #efe7df;
          visibility: visible;
        }
      }

    }
  }
}


.mapboxgl-popup-content {
  background-color: #FDF7F0 !important;
  border-radius: 10px !important;
  padding: 5px 18px 5px 10px !important;
  

  // text-align: left !important;
  .property-popup{
    display: flex;
    .property-image {
      width: 70px;
      height: 60px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 10px;
    }
  }
  .property-details {
    margin-left: 10px;
    h1 {
      font-size: 0.65rem !important;
      font-weight: 600 !important;
      color: #051885;

    }
    h4 {
      font-size: 0.55rem !important;
      font-weight: 400 !important;
      color: #051885;

    }
  }
}

.loading-screen {
  display: flex;
  margin-top: 15%;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  h1 {
    font-size: 2rem;
    color: #051885;
  }
  h3 {
    font-size: 1.3rem;
    color: #FFA7E5;
    padding-left: 15%;
    padding-right: 15%;
    text-align: center;
  }
  button {
    margin-top: 2%;
    width: 150px;
    padding: 5px;
    background-color: #FFA7E5;
    border: 2px solid #FFA7E5;
    border-radius: 10px;
    color: white;
    font-weight: 700;
    cursor: pointer;
  }
}


@media screen and (max-width: 1000px) {
  .property-detail-pages {
    .nav-section {
      border-bottom: 0px;
    }

    .property-main-page {
      .title-section {
        display: none;
        visibility: none;
        width: 0px;
        margin: 0px;
      }

      .property-results {
        padding-top: 10px !important;
        margin-left: 0px !important;
        border-left: 0px !important;

        .property-results-title {
          display: flex;
          flex-direction: column;
          align-items: center;

          .title-centre{
            width: 100%;
            .property-count {
              margin-top: 3%;
              margin-bottom: 3%;
              font-size: 1.0rem;
              padding: 0% 1%;
            }
          }

          .title-buttons{
            width: 100%;
            text-align: center;
            justify-content: center;
            .mobile-filter-button {
              display: block;
              width: 120px;
            }
            .modal-map {
              width: 120px;
            }
          }
    

          .filter-button {
            display: none;
          }
        }

        .property-grid {
          width: auto;

          .property-card {
            display: flex;
            flex-direction: column;
            height: auto;
            padding-left: 0%;
            padding-right: 0%;

            .mobile-name {
              visibility: visible;
              display: flex;
              justify-content: center;
              margin-bottom: 6%;
              // margin-left: 2%;
              // margin-right: 2%;

              h2 {
                font-size: 0.8rem;
                color: #051885;

                a {
                  width: 75%;
                }
              }

              h4 {
                font-size: 0.8rem;
                color: #FFA7E5;
                margin-left: 3%;
              }
            }

            .slide-section-mobile{
              display: block;
            }
            .slide-section-desktop {
              display: none;
            }

            .image-card {
              width: 100%;
              height: 350px;
              // border-radius: 20px;
            }
            .indicators {
              display: flex;
              margin-left: 0%;
              padding-left: 0%;
              padding-top: 2%;
              margin-top: 0%;
              background-color: #efe7df;
              li {
                padding: 0% 1.5% 0% 1.5%;
              }
            }

            .detail-section {
              width: 100%;
              background-color: #efe7df;
              border-bottom-left-radius: 7px;
              border-bottom-right-radius: 7px;
              padding-bottom: 5px;
              .property-desktop {
                display: none;
              }

              .property-buttons {
                margin-top: 5%;
                margin-bottom: 5%;
                justify-content: space-between;

                h5 {
                  font-size: 0.8rem;

                }
              }
              .property-button-expansion {
                padding-right: 5%;
              }
            }
          }

          .mobile-line {
            visibility: hidden;
          }
        }
      }
    }
  }

  .fade.modal.show {
    margin-top: 15%;
  }

  .loading-screen {
    display: flex;
    margin-top: 35%;

    flex-direction: column;
    justify-items: center;
    align-items: center;
    h1 {
      font-size: 2rem;
      color: #051885;
      padding-left: 2%;
      padding-right: 2%;
      text-align: center;
    }
    h3 {
      font-size: 1.3rem;
      color: #FFA7E5;
      padding-left: 7%;
      padding-right: 7%;
      margin-top: 2%;
      text-align: center;
    }
    button {
      margin-top: 7%;
    }
  }
}
