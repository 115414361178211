.fade.menu-modal.modal.show {
  border-radius: 0px;
  margin-top: 0%;
  margin-left: 60%;
  width: 40%;
  height: 100vh;
  // background-color: rgba(255, 167, 229, 0.8);
  background-color: #1A276C;
  // background-color: rgba(253, 247, 240, 0.9);
  border: none;
  background-image: url('../../../../public/white_icons/login-w.svg');
  background-position: bottom; /* position to the right and center vertically */
  background-repeat: no-repeat;
  background-size: 150% 100%;



  .modal-dialog {
    -webkit-transition: -webkit-transform 1s ease-out;
    -o-transition: -o-transform 1s ease-out;
    transition: transform 1s ease-out;
    .modal-content {
      .modal-body {
        border-radius: 0px;
        background: transparent;
        // background-color: rgba(5, 24, 133, 0.8) ;
        height: 100vh;
        text-align: right;
        margin-right: 5%;
        color: #ED6B86;
        font-size: 0.8rem;
        .menu-header {
          margin-top: 5%;
          margin-bottom: 10%;
          h1 {
            cursor: pointer;
            font-weight: 500;
            width: auto;
            color: #FDF7F0;
            
          }
        }
        .menu-body {
          h1 {
            margin-bottom: 8%;
            cursor: pointer;
            font-weight: 500;
          }
          .action {
            color: #FDF7F0 ;
          }
        }
      }
    }
  }
}




@media screen and (max-width: 900px) {
  .fade.menu-modal.modal.show {
    width: 100%;
    margin-left: 0%;
  }
}