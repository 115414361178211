.property-search {
  // box-shadow: 1px 2px 1px 0 rgba(0, 0, 0, 0.1), -1px 2px 1px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  color: #1A276C;

  .section-selectors.single {
    display: none;
  }

  .section-selectors {
    display: flex;
    flex-direction: row;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 1%;
    overflow: auto;

    .property-insight-buttons {
      display: flex;
      flex-direction: row;

    }

    .selector-button {
      text-wrap: nowrap;
      font-size: 0.75rem;
      color: #1A276C;
      font-weight: 500;
      padding: 10px 30px 10px 30px;
      cursor: pointer;
    }

    .selector-button.active {
      background-color: white;
      font-weight: 600;
    }

    .selector-button.inactive {
      background-color: white;
      font-weight: 600;
      background-color: rgba(26, 39, 108, 0.07);
      color: rgba(26, 39, 108, 0.6);
      ;

    }

    #left {
      border-top-left-radius: 15px;
    }

    #right {
      border-top-right-radius: 15px;
    }

  }
}






@media (max-width: 600px) {
  .selector-button {
    min-width: 170px;
    text-align: center;
  }
}