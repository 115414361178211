// KYC modal 
.fade.KYC-input-modal.modal.show {
  margin-left: 0%;
  margin-top: 0%;
  width: 45%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  // background-color: #ED6B86;
  background-color: #FDF7F0;
  // padding: 15px;
  overflow: auto;

  .modal-dialog {
    padding: 15px;

    .modal-body {}
  }

  .overall-body {
    background-color: white;
    padding: 15px;
    border-radius: 10px;


    .input-header {
      .input-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h1 {
          color: #1A276C;
          font-size: 1rem;
        }

        .close-modal {
          cursor: pointer;
        }
      }

    }

    .input-main {
      margin-top: 3%;

      .input-section {
        .section-sub-title {
          margin-bottom: 1%;
          margin-top: 3%;
          color: #ED6B86;
          font-size: 1rem;
        }

        h3 {
          width: 40%;
          font-size: 0.8rem;
          color: #1A276C;
        }

        .selection-block-single {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          margin-bottom: 2%;

          .dropdown {
            width: 60%;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid #1A276C;
          }
        }

        .selection-block-double {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          margin-bottom: 1%;

          .double-dropdowns {
            width: 60%;

            .dropdown {
              width: 50%;
              padding: 5px;
              border-radius: 10px;
              border: 1px solid #1A276C;
            }
          }
        }
        .selection-block-single.features {
          align-items: flex-start;
          .feature-list {
            display: flex;
            flex-direction: column;
          }
        }
    

        .select-box {
          // margin-tosp: 4%;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          white-space: nowrap;

          label {
            font-size: 0.8rem;
            font-weight: 500;
            margin-left: 5%;
          }
        }

        .explanation {
          font-size: 0.7rem;
          color: #1A276C;
          margin-bottom: 2%;
        }

        .selection-block-single.lifestyle {
          margin-bottom: 1%;
        }

        .slider-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          // margin-top: 1% !important;
          margin-bottom: 1% !important;

          .slider {
            margin-right: 5%;
            height: auto !important;
            width: 70%
          }

          h3 {
            width: 10%;
          }

          input[type=range]::-webkit-slider-runnable-track {
            width: 100%;
            height: 5px;
            cursor: pointer;
            animation: 0.2s;
            box-shadow: 1px 1px 1px #1A276C;
            background: #1A276C;
            border-radius: 5px;
            border: 0px solid #1A276C;
          }

          input[type=range]::-webkit-slider-thumb {
            // box-shadow: 5px 5px 5px #FFFFFF;
            border: 1px solid #ED6B86;
            height: 20px;
            width: 20px;
            border-radius: 15px;
            background: #ED6B86;
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -7px;
          }
        }

        .section-search-buttons {
          display: flex;
          flex-direction: row;

          button {
            background-color: #1A276C;
            color: #FDF7F0;
            font-weight: 600;
            width: 100px;
            border-radius: 10px;
            border: 1px solid #1A276C;
            cursor: pointer;
          }
          .selector:nth-of-type(odd) {
            margin-right: 10px;
          }
          .selector:nth-of-type(even) {
            margin-left: 10px;
          }

        }


        .select-dropdown {
          width: 60%;
          font-size: 0.7rem;
          border-radius: 10px;
          border: 1px solid #1A276C;
          .css-13cymwt-control {
            border-radius: 10px;
          }
        }
      }
    }

    .search-name-section {
      h3 {
        margin-bottom: 1%;
        margin-top: 3%;
        color: #ED6B86;
        font-size: 1rem;
      }
      input {
        width: 300px;
        border: 1px solid #1A276C;
        padding: 5px;
        border-radius: 10px;
      }
    }

    .input-footer {
      margin-top: 5%;
      margin-bottom: 5%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .load-properties {
        width: 30%;
        background-color: #ED6B86;
        color: #FDF7F0;
        font-size: 0.8rem;
        font-weight: 600;
        padding: 5px;
        border-radius: 10px;
        border: 1px solid #FDF7F0;
        cursor: pointer;
      }
    }
  }

}


@media screen and (max-width: 900px) {

  .fade.KYC-input-modal.modal.show {
    margin-left: 0%;
    margin-top: 0%;
    width: 100vw;
    height: 100%;
  }

}