  .living-wrapper {
    max-width: 100vw;

    .living-opening {
      background-color: #FDF7F0;
      min-height: 100vh;
      .headline-section-living {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 94%;
        margin-left: 3%;
        margin-right: 3%;
        margin-top: 5%;
        h1 {
          text-align: center;
          color: #051885;
          font-size: 3rem;
        }
        h3 {
          text-align: center;
          color: #FFA7E5;
          font-size: 1.8rem;
        }
        .subscribe-action {
          margin-top: 5%;
          margin-bottom: 1%;
          color: #051885;
          font-size: 1.2rem;
        }
        .living-input {
          width: 150px;
          margin-bottom: 1%;
          padding: 5px;
          border-radius: 5px;
          border: 1px solid #051885;
          color: #051885;
        }
        .sign-up-action {
          margin-top: 3%;
          margin-bottom: 0%;
          color: #051885;
          font-size: 1.2rem;
        }
      }


      .headline-features {
        display: flex;
        flex-direction: row;
        margin-top: 14%;
        // min-height: 100vh;

        .feature{
          width: 275px;
          margin-left: 60px;
          margin-right: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 15%;

          .icon-box {
            width: 100px;
            height: 100px;
            background-color: #051885;
            border-radius: 50px;
            margin-bottom: 5%;
            .icon{
              width: 100%;
              padding: 20%;
            }
          }

          h5 {
            text-align: center;
            color: #051885;
            padding-left: 5%;
            padding-right: 5%;
            font-size: 1rem;
          }

          h4 {
            color: #051885;
            margin-bottom: 5%;
          }
        }
      }
      .living-button {
        width: 150px;
        padding: 5px;
        background-color: #FFA7E5;
        border: 2px solid #FFA7E5;
        border-radius: 10px;
        color: white;
        font-weight: 700;
        cursor: pointer;
        margin-bottom: 6%;
      }
      .mobile-button {
        margin-top: 0%;
        width: 150px;
        padding: 5px;
        background-color: #FFA7E5;
        border: 2px solid #FFA7E5;
        border-radius: 10px;
        color: white;
        font-weight: 700;
        cursor: pointer;      
      }
      .living-question {
        margin-top: 1%;
        font-size: 0.8rem !important;
        color: #051885 !important;
        font-style: italic;
        cursor: pointer;
        margin-bottom: 1%;
      }
    }
  }


  @media screen and (max-width: 800px) {

    .living-opening {
      .headline-section-living{
        margin-top: 10% !important;
        margin-left: 2% !important;
        margin-right: 2% !important;
        width: 96% !important;
        h1 {
          font-size: 2.0rem !important;
          margin-bottom: 3%;

        }
        h3{
          font-size: 1.2rem !important;
        }
        .living-button {
          margin-top: 2% !important;
          margin-bottom: 15% !important;
        }
        
      }
      .mobile-button {
        margin-top: 0% !important;
      }
      .living-input {
        margin-bottom: 3% !important;
      }
      .living-question {
        display: block !important;
        color: #051885 !important;
        margin-top: 3% !important;
        margin-bottom: 3% !important;
        font-size: 1rem !important;
      }
      .sign-up-action {
        text-align: center;
        margin-top: 10% !important;
        font-size: 1rem !important;
      }
      .subscribe-action {
        text-align: center;
        font-size: 1rem !important;
      }
    }

    .headline-features {
      margin-top: 20% !important;
      flex-direction: column !important;
      
      .feature {
        margin-bottom: 20% !important;
        h5 {
          color: #FFA7E5 !important;
        }
      }
      .mobile-separator {
        margin-top: 10%;
        margin-bottom: 10%;
        margin-left: 10% !important;
        margin-right: 10% !important;
        border: 1px solid #FFA7E5;
      }
    
    }


    
  }