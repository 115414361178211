.profile-page {
  .normal-nav-wrap {
    display: block;
  }
  .centre-nav-wrap {
    display: none;
  }

  .holding-loading {
    display: flex;
    justify-content: center;
    margin-top: 20%;
    h1 {
      font-size: 2rem;
      // color: #FFA7E5;
      color: #051885;
    }
  }

  .profile-sidebar-open {
    width: 225px;
    display: block;
    background-color: #051885;
    // background-color: #FFA7E5;
    // border-right: 2px solid #FFA7E5;
    // border-right: 2px solid #051885;
    position: fixed;
    top: 0%;
    height: 100vh;
    .logo {
      color: #FFA7E5;
      padding-top: 3px;
      padding-left: 5%;
      cursor: pointer;
      margin-left: 3%;
      margin-top: 5%;
  
      h2 {
        font-size: 2.2rem;
        // color: #FDF7F0;
        cursor: pointer;
      }
    }
    .profile-buttons {
      margin-top: 15%;
      margin-left: 7%;
      // color: #051885;
      color: #FFA7E5;
      // color: #FDF7F0;
      .profile-button-title {
        margin-top: 5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 5%;
        h2 {
          font-size: 1.0rem;
          cursor: pointer;
        }
        h4 {
          font-size: 0.7rem;
        }
      }
      .profile-button-title:hover {
        color: #FDF7F0;
      }
      #second-title {
        margin-top: 10%;
      }
      .profile-button-sub {
        margin-top: 5%;
        margin-left: 10%;
        h3 {
          font-size: 0.8rem;
          font-weight: 500;
          cursor: pointer;
          margin-bottom: 7%;
        }
        h3:hover {
          color: #FDF7F0;
        }
      }
     
    }
  }



  .profile-main-section {
    margin-left: 225px;
    margin-top: 2%;
    .mobile-title-slider {
      display: none;
    }
    .profile-content {
      display: flex;
      flex-direction: column;
      // margin-top: 1.2%;
      margin-left: 5%;

      .selection-detail {
        max-width: 100%;
        .section-title-box {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-right: 6.5%;
          .mobile-area-block {
            display: none;
          }
          .desktop-search-block {
            display: flex;
            flex-direction: row;
            height: 25px;
            position: absolute;
            right: 5%;
            .edit-area-block {
              display: flex;
            }

    
            .search-box {
              padding-left: 2%;
              width: 163px;
              border-radius: 5px;
              border: 2px solid #051885;
            }
            button {
              width: 30px;
              padding: 0%;
              background-color:#FFA7E5;
              color: #FDF7F0;
              font-weight: bold;
              border: 2px solid #FFA7E5;
              border-radius: 5px;
              margin-left: 3px;
              cursor: pointer;
            }
            .map-button {
              width: 100px;
              background-color: rgba(255, 167, 229, 0.0);
              color: #FFA7E5;
              padding-left: 5px;
              padding-right: 5px;
              border: 2px solid #FFA7E5;
            }
            .reset-button {
              margin-right: 3px;
              background-image: url('../../../../public/website_images/reset.png');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
            }
            .mobile-view-toggle {
              display: none;
            }
            .desktop-view-toggle {
              display: block;
            }
          }
        }

        .profile-top {
          display: flex;
          width: 100%;
          // margin-top: 1.2%;
          button {
            margin-top: 3%;
            margin-left: 0%;
            width: 150px;
            padding: 5px;
            background-color: #FFA7E5;
            color: #FDF7F0;
            font-weight: bold;
            border-radius: 10px;
            border: 1px solid #FFA7E5;
            cursor: pointer;
          }
          .profile-intro {
            width: 20%;
            margin-right: 2%;
            // padding-left: 1%;
            padding-right: 1%;
            color: #051885;
    
            .profile-bio {
              margin-top: 2%;
              font-size: 0.8rem;
              color: #051885;
    
            }
          }
          .top-insights {
            display: flex;
            width: 60%;

            .box-insights {
              width: 28%;
              border: 1px solid #051885;
              border-radius: 30px;
              margin-right: 3%;
              height: 130px;
              text-align: center;
              background-color: #FDF7F0;
              color: #051885;
              cursor: pointer;
              // text-decoration:
              h1 {
                font-size: 2.6rem;
                margin-top: 10px;
                margin-bottom: 5px;
                color: #051885;
    
              }
      
              p {
                font-size: 0.9rem;
                padding-left: 3%;
                padding-right: 3%;
              }
            }
          }
        }

        .profile-bottom {
          margin-top: 2%;
          h3 {
            color: #051885;
          }
          p {
            color: #051885;
            font-size: 0.8rem;
          }
          .comparison-table {
            margin-top: 2%;
            width: 500px;
            .feature {
              width: 50%;
            }
            .detail {
              width: 25%;
              text-align: center;
              display: flex;
              justify-content: center;
            }
            .title-row {
              display: flex;
              margin-bottom: 1%;
              color: #051885;
              border-bottom: 1px solid #051885;

            }
            .body-row {
              display: flex;
              align-items: center;
              margin-bottom: 0.5%;
              margin-top: 0.5%;
              color: #051885;
              border-bottom: 1px dashed #051885;

              .feature {
                font-weight: 500;
                font-size: 0.8rem;
              }
            }
            .body-row:last-of-type {
              border-bottom: 0px;
            }
            #tick {
              background-image: url('../../../../public/website_images/tick-box.png');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              width: 25px;
              height: 25px;
              text-align: center;
            }
            #cross {
              background-image: url('../../../../public/website_images/blue-cross.png');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              width: 25px;
              height: 25px;
              text-align: center;
            }
          }
        }

        .property-choice {
          display: flex;
          margin-bottom: 1%;
          select {
            padding: 7px;
            width: 100px;
            background-color: transparent;
            border: 2px solid #051885;
            border-radius: 7px;
            color: #051885;
            font-weight: bold;
            cursor: pointer;
            margin-right: 75px;
          }
        }
        .property-grid {
          .property-card {
            display: flex;
            flex-wrap: wrap;

            .property-detail {
              height: 300px;
              width: calc((30%));
              margin-right: 3.33%;
              margin-bottom: 3%;
              border: 1px solid #051885;
              border-radius: 15px;
              background-color: #FDF7F0;
              color: #051885;

              .property-image {
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 100%;
                height: 225px;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                border-bottom: 2px solid #051885;
              }

              .property-text-top {
                display: flex;
                justify-content: space-between;
                font-size: 0.85rem;

                h5 {
                  padding: 7.5px;
                  color: #051885;
                }
              }

              .property-text-bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-right: 7.5px;
                font-size: 0.85rem;

                h5 {
                  padding: 7.5px;
                }

                button {
                  background-color: white;
                  border-radius: 5px;
                  // padding: 5px;
                  border: 2px solid #051885;
                  color: #051885;
                  font-size: 0.85rem;
                  cursor: pointer;
                }
              }
            }
          }
        }

        .no-properties {
          .no-properties-text {
            color: #051885;
            margin-top: 1%;
            margin-bottom: 1%;
          }
          .no-properties-subtext {
            color: #FFA7E5;
            width: 40%;
            margin-bottom: 1%;
          }
          button {
            // margin-top: 6%;
            width: 150px;
            padding: 5px;
            background-color: #FFA7E5;
            border: 2px solid #FFA7E5;
            border-radius: 10px;
            color: white;
            font-weight: 700;
            cursor: pointer;
          }
          .favourite-instructions {
            display: flex;
            flex-direction: row;
            align-items: center;
            h3 {
              color: #FFA7E5;
              font-size: 0.9rem;
            }
            .favourite-button-on {
              background-image: url('../../../../public/website_icons/pink-heart.png');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              width: 40px;
              height: 40px;
              cursor: pointer;
            }
            h4 {
              color: #FFA7E5;
              margin-left: 1%;
            }
          }
        }

        .section-title {
          color: #051885;
          font-size: 1.0rem;
          margin-bottom: 2%;

          span {
            color: #FFA7E5;
          }
        }
        #property-section {
          margin-top: 1.2%;

        }
        // my searches detail section
        .search-grid {
          .search-card {
            display: flex;
            flex-wrap: wrap;

            .search-detail {
              background-color: #FDF7F0;
              // height: 250px;
              width: calc((100%/ 2) - 4%);
              margin-bottom: 2%;
              margin-right: 4%;
              border: 1px solid #051885;
              border-radius: 15px;
              padding-left: 1.5%;

              .search-title {
                margin-top: 2%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #051885;

                .search-buttons {
                  margin-right: 1.5%;

                  .transparent-btn {
                    border: 2px solid #051885;
                    background-color: white;
                    border-radius: 5px;
                    width: 75px;
                    padding: 5px;
                    margin-left: 15px;
                    cursor: pointer;
                    color: #051885;

                  }

                  .block-btn {
                    background-color: #051885;
                    border: 2px solid #051885;
                    color: white;
                    border-radius: 5px;
                    width: 75px;
                    padding: 5px;
                    margin-left: 15px;
                    cursor: pointer;
                  }
                }
              }

              .search-content {
                display: flex;
                flex-direction: row;
                margin-right: 0px;

                .search-left {
                  margin-top: 1%;
                  width: 30%;
                }

                .search-right {
                  margin-top: 1%;
                  width: 70%;
                }

                h4 {
                  font-weight: 600;
                  font-size: 0.8rem;
                  color: #051885;
                }

                .search-section-detail {
                  margin-top: 10px;
                  margin-right: 10px;
                  margin-bottom: 15px;

                  .pills {
                    background-color: rgba(255, 167, 229, 0.2);
                    padding: 3px;
                    margin-right: 3px;
                    border-radius: 10px;
                    margin-bottom: 3px;
                    border: 1px solid #FFA7E5;
                    font-size: 0.8rem;
                    margin-top: 0px;
                    color: #051885;

                  }

                  p {
                    font-size: 0.75rem;
                    margin-bottom: 7%;
                    color: #051885;
                  }
                }
              }

            }

            .search-footer {
              display: flex;
              margin-top: 5px;
              margin-bottom: 10px;

              .search-summary {
                text-align: center;
                padding-right: 15px;
                display: flex;
                align-items: center;

                .search-count {
                  font-size: 0.75rem;
                  color: #FFA7E5;
                }

                .search-description {
                  font-size: 0.75rem;
                  margin-left: 5px;
                  color: #051885;

                }
              }
              .search-footer-scores {
                display: flex;
              }
            }

          }
        }

        // comparison detail section
        .comparison-grid {
          margin-right: 5%;
          margin-bottom: 5%;
          .comparison-properties-mobile {
            display: none;
          }
          .comparison-title {
            display: flex;
            justify-content: space-between;
            color: #051885;
            font-size: 0.9rem;
            h1 {
              font-size: 1rem;
            }
          }

          .comparison-subtitle {
            margin-top: 1%;
            display: flex;
            justify-content: space-between;

            .comparison-dropdown {
              padding: 5px;
              border-radius: 5px;
              border: 1px solid #051885;
              background-color: rgba(255, 167, 229, 0.2);
              width: 17.5%;
              color: #051885;

            }
          }

          .comparison-property {
            width: 19%;
            cursor: pointer;
            .comparison-image {
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              width: 100%;
              height: 200px;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              border: 1px solid #051885;
            }

            .comparison-content {
              height: 110px;
              width: 100%;
              background-color: #FDF7F0;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              border: 1px solid #051885;
              color: #051885;

              h4 {
                font-size: 0.6rem;
                padding: 5px;
              }

              h5 {
                font-size: 0.6rem;
                font-weight: 400;
                padding: 5px;
              }
            }
          }

          .comparison-body {
            margin-top: 1%;
            display: flex;
            // justify-content: spac;
            .comparison-property {
              // width: 15%;
            }
            .comparison-results {
              width: 68%;
              text-align: center;
              font-size: 0.7rem;
              margin-left: 1%;
              margin-right: 1%;
              color: #051885;

              .left-score {
                margin-right: 10px;
              }
              .right-score {
                margin-left: 10px;
                
              }
              .results-rows {
                display: flex;
                justify-content: center;
                // flex-direction: column;
                width: 100%;
                .results-left {
                  display: flex;
                  flex-direction: row;
                  width: 50%;
                  justify-content: flex-end;
                  .bars {
                    display: flex;
                    flex-direction: row;
                    width: 1%;
                    color: transparent;
                    // background-color: #FFA7E5;
                  }
                  .blank-bars {
                    display: flex;
                    flex-direction: row;
                    width: 1%;
                    color: transparent;
                    background-color: transparent;
                  }
                }
                .results-right {
                  display: flex;
                  flex-direction: row;
                  width: 50%;
                  .bars {
                    display: flex;
                    flex-direction: row;
                    width: 1%;
                    color: transparent;
                    // background-color: #FFA7E5;
                  }
                  .blank-bars {
                    display: flex;
                    flex-direction: row;
                    width: 1%;
                    color: transparent;
                    background-color: transparent;
                  }
                }
              }
            }
          }
        }
        .lifestyle-table {
          .table-filters {
            display: flex;
            flex-direction: row;
            margin-right: 5%;
            margin-bottom: 1%;
            button {
              height: 30px;
            }
            .filter {
              width: 20%;
              .desktop-dropdown {
                display: block;
              }
              .mobile-dropdown {
                display: none;
              }
              .filter-title {
                font-size: 0.9rem;
                color: #051885;
                margin-left: 1%;
              }
              .slider-container{
                width:90%;
                margin-bottom: 0%;
                input {
                  margin: 0%;
                }
              }
              .Dropdown-root {
                .Dropdown-control{
                  margin-top: 2%;
                  width: 90%;
                  padding: 3px 3px 3px 10px;
                  // border-radius: 5px;
                  border: 1px solid #051885;
                  // border: 1px solid rgba(5, 24, 133, 0.6);
                  background-color: #FDF7F0;
                  color: #051885;
                  font-weight: 400;
                  .Dropdown-placeholder.is-selected {
                    font-size: 0.85rem;
                  }
                }
                .Dropdown-menu {
                  width: 90%;
                  background-color: #FDF7F0;
                  border: 1px solid #051885;
                  font-size: 0.85rem;
                }
                .Dropdown-placeholder {
                  font-size: 0.85rem;
                }
              }
              .filter-dropdown {
                margin-top: 2%;
                width: 90%;
                padding: 3px;
                // border-radius: 5px;
                border: 1px solid #051885;
                // border: 1px solid rgba(5, 24, 133, 0.6);
                background-color: #FDF7F0;
                color: #051885;
                font-weight: 400;
              }
            }
          }
          .sub-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
 
            h1 {
              font-size: 1rem;
              color: #051885;
            }
            select {
              width: 100px;
              background-color: #FDF7F0;
              border-radius: 10px;
              border: 1px solid #051885;
              padding: 5px;
              color: #051885;
            }
          }
          .table-content {
            margin-top: 1%;
            margin-right: 5%;

            // border: 1px solid #051885;
            // padding-left: 1%;
            // padding-right: 1%;
            width:98%;
         
            .table-titles {
              display: flex;
              align-items: center;
              color: #051885;
              border-bottom: 2px solid #FFA7E5;
              padding: 1%;
            }
            .table-details-wrap {
              overflow-y: scroll;
              max-height: 63vh;
            }
            .table-details {
              display: flex;
              align-items: center;
              color: #051885;
              padding-left: 1%;
              padding-right: 1%;
              padding-top: 0.5%;
              padding-bottom: 0.5%;
              h5 {
                font-weight: 400;
                font-size: 0.75rem;
              }
            }
            .table-details:nth-of-type(odd){
              background-color: rgba(255, 167, 229, 0.3);
              // background-color: rgba(5, 24, 133, 0.3);
            }
            .column-1 {
              width: 3%;
            }
            .column-2 {
              width: 50%;
            }
            .column-3 {
              width: 27%;
            }
            .column-4 {
              width: 20%;
              text-align: right;
            }
            .column-5 {
              width: 15%;
            }
            .column-6 {
              width: 20%;
            }
            #gym-option {
              width: 35%;
            }
            #school-option {
              width: 57%;
            }
          }
        }

      }
    }
  }
  .pagination {
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    margin-left: 3%;
    margin-right: 5%;
    padding: 0%;
    font-size: 0.7rem;
    .next, .previous, li {
      background-color: #FDF7F0;
      width: 30px;
      height: 30px;
      margin-top: 0%;
      padding: 0%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFA7E5;
    }
    .next, .previous {
      border-radius: 15px;
      border: 2px solid #FFA7E5;
      color: #FFA7E5;
      font-size: 1rem;
    }
    li {
      font-size: 0.7rem;
      cursor: pointer;
    }
    .active {
      border-radius: 15px;
      border: 2px solid #FFA7E5;
      // background-color: #FDF7F0;
      background-color: #FFA7E5;
      // color: #FFA7E5;
      color: #FDF7F0;
      font-weight: bold;

    }
  }

    .selection-detail {
      .map-list-title {
        .sub-title {
          margin-right: 5%;
        }
      }
      .sub-title{
        h1 {
          width: 80%;
        }
        // margin-right: 5%;
        .icon-selector-section {
          display: flex;
          .icon-selector {
            width: 30px;
            height: 30px;
            margin-left: 10px;
            cursor: pointer;
            .table-icon {
              height: 100%;
              width: 100%;
              background-image: url('../../../../public/website_images/table.png');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
            }
            .map-icon {
              height: 100%;
              width: 100%;
              background-image: url('../../../../public/website_images/map.png');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
            }
            .grid-icon {
              height: 100%;
              width: 100%;
              background-image: url('../../../../public/website_images/tile.png');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
            }
            .icon {
              height: 100%;
              width: 100%;
            }
          }
          #map-icon {
            display: none;
          }
        }
      }
      .lifestyle-map {
        display: flex;
        flex-direction: row;
        margin-top: 1%;
     
        .content-section {
          width: 30%;
          .map-list-title {
            // margin-top: 1%;
            margin-left: 1%; 
            h1 {
              font-size: 1rem;
              color: #051885;
            }
       
            }
            .item-list {
              margin-top: 5%;
              height: 70vh;
              overflow-y: scroll;
              hr {
                margin-top: 5%;
                margin-bottom: 5%;
                margin-right: 5%;
                // border: 1px solid rgba(5, 24, 133, 0.3);
                border: 1px solid rgb(147, 145, 136, 0.15);
                visibility: visible;
              }
              .item {
                // margin-bottom: %;
                display: flex;
                cursor: pointer;

                .item-left {
                  width: 25%;
                  height: 60px;
                  border: 1px solid #051885;
                  border-radius: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .icon-image {
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                  }
                  h1 {
                    font-size: 0.8rem;
                  }
                }
                .item-right {
                  margin-left: 4%;
                  width: 75%;
                  h1 {
                    font-size: 0.8rem;
                    padding-right: 5%;
                  }
                  h3 {
                    font-size: 0.7rem;
                    color: #051885;
                    font-weight: 500;
                    
                  }
                }
              }
            }
          }
        }
        .map-section {
          width: 70%;
          height: auto;
          .mapboxgl-map {
            height: 80vh !important;
            border: none;
            .mapboxgl-canvas {
              width: 100% !important;
              height: 100%;
            }
          }
          .icon-popup {
            width: 225px;
            height: 200px;
            .top-box {
              width: 200px;
              height: 100px;
              padding: 0%;
              .icon-image {
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 100%;
                height: 100%;
                padding: 5px;
                // border: 1px solid #051885;
                // border-radius: 5px;
              }
            }
            .bottom-box {
              color: #051885;
              margin-top: 1%;
              h1 {
                font-size: 0.8rem;
                font-weight: bold;
              }
              h3, a {
                font-size: 0.65rem;
                font-weight: 400;
              }
            }
          }
      
        }
        .poi-background {
          // background-color: rgba(255, 167, 229, 0.9);
          background-color: #051885;
          // padding: 5px;
          width: 30px;
          height: 30px;
          border-radius: 15px;
          z-index: -1;
          cursor: pointer;
          font-size: 0.75rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #FFA7E5;
          // color: #051885;
          font-weight: bold;
  
          .restaurant-btn {
            background-image: url('../../../../public/website_icons/restaurant.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 15px;
            height: 15px;
          }
        }
      }

      .admin-portal {
        display: flex;
        .admin-title {
          margin-bottom: 2%;
          font-size: 1rem;
          color: #051885;
        }
        .left-detail {
          width: 35%;
          color: #051885;
          border-right: 1px solid rgb(147, 145, 136, 0.5);
        }
        .admin-intro-section {
          padding-right: 10%;
          h3 {
            font-weight: 500;
            font-size: 0.9rem;
            padding-right: 5%;
          }
          .top-insights {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 5%;
            
            .insight {
              border: 1px solid #051885;
              width: 30%;
              text-align: center;
              border-radius: 20px;
              h1 {
                font-size: 1.5rem;
                margin-top: 10%;
                margin-bottom: 5%;
              }
              p {
                font-size: 0.7rem;
                padding-left: 5%;
                padding-right: 5%;
                margin-bottom: 10%;
              }
            }
          }
        }
        .admin-list-section {
          margin-top: 5%;
          border-top: 1px solid rgb(147, 145, 136, 0.5);
          .bills-table-title {
            padding-top: 5%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-right: 10%;
            margin-bottom: 2%;
            h1 {
              margin-bottom: 0%;
            }
            button {
              width: 50px;
              border-radius: 5px;
              border: 1px solid #FFA7E5;
              background-color: #FFA7E5;
              color: #FDF7F0;
              font-weight: bold;
            }
          }
          .bills-table-headers {
            border-top: 2px solid #FFA7E5;
            border-bottom: 2px solid #FFA7E5;
            margin-right: 10%;
            font-size: 0.7rem;
            // color: #FFA7E5;
            // background-color: rgba(5, 24, 133, 1);
            // background-color: rgba(255, 167, 229, 0.5);

          }
          .bills-table-content {
            margin-right: 10%;
            .bills-row {
              border-bottom: 1px solid #FFA7E5;
              .bills-columns {
                font-size: 0.6rem;
                h3 {
                  font-weight: 400;
                }
                .column-1 {
                  font-weight: 600;
                  // background-color: rgba(255, 167, 229, 0.2);
                  // background-color: rgba(5, 24, 133, 0.2);
                }
      
              }
            }
          }
          .bills-row {

            .bills-columns {
              display: flex;
              align-items: center;
              h3 {
                padding-top: 2%;
                padding-bottom: 2%;
              }
              .column-1 {
                width: 40%;
                padding-left: 2%;
              }
              .column-2 {
                width: 20%;
                padding-left: 1%;
                text-align: right;
                // padding-right: 5%;
              }
              .column-3 {
                width: 35%;
                text-align: right;
                padding-right: 3%;
              }
              .column-4 {
                width: 5%;
                text-align: center;
                cursor: pointer;
              }
            }
            .notes-divider {
              // border:1px dashed rgb(147, 145, 136, 0.2);
              border:1px dashed rgba(255, 167, 229, 0.4);
              visibility: visible;
              margin-left: 2%;
              margin-right: 2%;
              margin-bottom: 1%;
            }
            .bills-notes {
              width: 100%;
              display: flex;
              font-size: 0.6rem;
              margin-bottom: 1%;
              .notes-details {
                width: 66%;
                margin-left: 2%;
              }
              .actions {
                width: 32%;
                h3 {
                  font-weight: 500;
                  font-size: 0.6rem;
                }
                h3:hover {
                  color: #FFA7E5;
                  cursor: pointer;
                }
              }
            }
          }
        
        }
        .right-detail {
          width: 60%;
          margin-right: 0%;
          padding-right: 0%;
          // margin-left: 2.5%;
          .bar-section {
            padding-left: 3%;
            .recharts-rectangle {
              // width: 30;
            }
            .recharts-text.recharts-cartesian-axis-tick-value {
              fill: #051885;
              font-weight: 400;
            }
            .admin-title {
              margin-left: 3%;
            }
          }
          .donut-section {
            border-top: 1px solid rgb(147, 145, 136, 0.5);
            padding-left: 3%;
            padding-top: 2%;

            .admin-title {
              margin-left: 3%;
            }
            .donut-detail {
              margin-left: 2%;
              .desktop-donut {
                display: block;
              }
              .mobile-donut {
                display: none;
              }
            }
            .recharts-text.recharts-label {
              fill: #051885;
              font-weight: bold;
              font-size: 2rem;
            }
            .donut-box {
              display: flex;
              flex-direction: row;
              align-items: center;
              .donut-content {
                margin-left: 10%;
                width: 100%;
                .legend-row {
                  margin-top: 10%;
                  margin-bottom: 10%;
                  display: flex;
                  align-items: center;
                  .legend-box {
                    width: 20px;
                    height: 20px;
                    background-color: #FFA7E5;
                    margin-right: 3%;
                  }
                  h3 {
                    color: #051885;
                    font-size: 0.9rem;
                  }
                  #one {
                    background-color: #051885;
                  }
                  #two {
                    background-color: rgba(5, 24, 133, 0.5);
                  }
                  #three {
                    background-color: rgba(255, 167, 229, 1);
                  }
                  #four {
                    background-color: rgba(255, 167, 229, 0.6);
                  }
                  #five {
                    background-color: rgba(255, 167, 229, 0.3);
                  }
                }
              }
            }
          }
        }
        .bottom-detail {
          .admin-list-section {
            display: none;
          }
        }
      }
      .admin-portal-no-account {
        display: flex;
        .no-account-left {
          width: 50%;
          display: flex;
          flex-direction: column;
          // align-items: center;
          margin-top: 15%;
          padding-right: 20%;
          h1 {
            color: #051885;
            margin-bottom: 5%;
          }
          button {
            width: 200px;
            padding: 5px;
            background-color: #FFA7E5;
            color: #FDF7F0;
            font-weight: bold;
            border-radius: 10px;
            border: 1px solid #FFA7E5;
            cursor: pointer;
          }
        }
        .no-account-right {
          width: 50%;
          display: flex;
          justify-content: center;
          // margin-top: 5%;
          // align-items: center;
          .living-admin-image {
            background-image: url('../../../../public/website_images/wittle-living-admin-figma.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            // background-attachment: fixed;
            height: 600px;
            width: 295px;
          }
        }
      }
    }

  




@media screen and (max-width: 800px) {
  .profile-page {
    .normal-nav-wrap {
      display: none;
    }
    .centre-nav-wrap {
      display: block;
    }
    .profile-sidebar-open {
      display: none;
      width: 0px;
    }

    .profile-main-section {
      margin-right: 5%;
      margin-left: 0px;
      margin-top: 0%;
      .mobile-selector-section {
        display: block;
        .mobile-title-dropdown {
          // margin-top: 4%;
          .Dropdown-control {
            border-radius: 15px;
            background-color: #FDF7F0;
            border: 1px solid #051885;
            color: #051885;
          }
          .Dropdown-menu {
            border-radius: 15px;
            background-color: #FDF7F0;
            border: 1px solid #051885;
            color: #051885;
          }
        }

        .mobile-title-slider {
          margin-top: 4%;
          display: flex;
          flex-direction: row;
          width: 100%;
          overflow-x: scroll;
          margin-bottom: 3%;
          .pills {
            // background-color: #FDF7F0;
            background-color: rgba(255, 167, 229, 0.1);
            padding:3px 10px 3px 10px;
            margin-right: 3%;
            border-radius: 10px;
            margin-bottom: 3px;
            border: 1px solid #FFA7E5;
            font-size: 0.8rem;
            margin-top: 0px;
            color: #051885;
            white-space: nowrap;
          }
        }
      }
      .profile-top {
        display: flex;
        flex-direction: column;
        width: 100%;
        button {
          margin-left: 0% !important;
          margin-top: 5% !important;
        }
        .profile-intro {
          width: 100% !important;
          text-align: center;
          .profile-name {
            margin-top: 10%;
          }
          .profile-bio {
            margin-top: 10%;
          }
        }
        .top-insights {
          width: 100% !important;
          justify-content: space-around;
          margin-top: 10%;
          .box-insights {
            display: flex;
            flex-direction: column;
            border-radius: 15px !important;
            width: 22.5% !important;
            margin-right: 0% !important;
            height: 110px !important;
            h1 {
              font-size: 1.3rem !important;
              margin-top: 0%;
            }
            p {
              font-size: 0.8rem !important;
            }
          }
        }
      }
      .profile-bottom {
        margin-top: 10% !important;
        padding-left: 1%;
        padding-right: 1%;
        .comparison-table {
          width: 340px !important;
          margin-top: 5% !important;
          .feature {
            width: 40% !important;
          }
          .detail {
            width: 30% !important;
          }
        }
      }

 
      
      .profile-content {
        // margin-top: 5%;
        padding-bottom: 5%;
        .selection-buttons {
          overflow-x: scroll;
          scrollbar-width: none;
          h4 {
            width: 200px;
            font-size: 0.9rem;
          }
        }
        .selection-detail {
          padding-right: 0%;
          .property-choice {
            margin-top: 3%;
          }
          .property-grid {
            margin-top: 5%;
            .property-card {
              display: block;
              width: 100%;
              .property-detail {
                width: 100%;
                margin-bottom: 5%;
              }
            }
          }
          
          .no-properties {
            margin-top: 5%;
            text-align: center;
            .no-properties-text {
              color: #051885;
              margin-top: 5%;
              margin-bottom: 5%;
            }
            .no-properties-subtext {
              color: #FFA7E5;
              margin-bottom: 5%;
              padding-left: 5%;
              padding-right: 5%;
              width: 100%;
            }
            .favourite-instructions {
              display: flex;
              flex-direction: column;
              align-items: center;
              .favourite-button-on {
                margin-bottom: 5%;
              }
              h4 {
                margin-left: 0%;
              }
            }
          }

          // my searches section
          .search-grid {
            .search-card {
              flex-wrap: nowrap;
              flex-direction: column;
              margin-top: 5%;
              .search-detail {
                width: 100%;
                margin-bottom: 5%;
                margin-right: 0%;
                .search-title {
                  flex-direction: column;
                  margin-right: 2%;
                  h3 {
                    margin-bottom: 2%;
                    margin-top: 2%;
                  }
                  .search-buttons {
                    margin-bottom: 2%;
                  }
                  .search-buttons {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-right: 4%;
                  }
                }
                .search-footer {
                  flex-direction: column;
                  align-items: center;
                  .search-footer-scores{
                    display: flex;
                  }
                  .search-summary {
                    padding-right: 15px;
                    margin-top: 2%;
                  }
                }
                .search-content {
                  .search-left{
                    width: 35%;
                    .search-section-detail {
                      p {
                        font-size: 0.7rem;
                      }
                    }
                  }
                  .search-right{
                    width: 65%;
                    .search-section-detail {
                      .pills {
                        font-size: 0.7rem;
                      }
                    }
                  }
                }
              }
            }
          }

          // comparison section {
            .comparison-grid {
              margin-top: 5%;
              margin-right: 0%;
              margin-bottom: 5%;
              .comparison-title {
                h1 {
                  font-size: 1rem;
                  margin-bottom: 2%;
                }
                .desktop-results {
                  display: none;
                }
              }
              .comparison-subtitle {
                .comparison-dropdown {
                  width: 48%;
                }
              }
              .comparison-properties-mobile {
                display: block;
                margin-top: 5%;
                display: flex;
                justify-content: space-between;
                .comparison-property {
                  width: 48%;
                  // display: none;
                  .comparison-image {
                    height: 170px;
                  }
                  .comparison-content {
                    height: 120px;
                  }
                }
              }
              .comparison-body {
                margin-top: 5%;
                .comparison-property {
                  display: none;
                }
                .comparison-results {
                  // display: none;
                  width: 95%;
                  .results-rows {
                    .results-left {
                      width: 45%;
                      margin-left: 5%;
                    }
                    .results-right {
                      width: 45%;
                    }
                  }
                  .result-title {
                    margin-left: 5%;
                  }
                }
              }
            }
            .mobile-view-toggle {
              display: block;
            }
           
            .section-title-box {
              margin-bottom: 1%;
              margin-right: 0%;
              flex-direction: column;
              .section-title {
                margin-bottom: 0%;
              }

              .edit-area-block {
                display: none !important;
              }

              .mobile-area-block {
                display: flex;
                margin-top: 2%;
                button {
                  width: 30px;
                  padding: 0%;
                  background-color:#FFA7E5;
                  color: #FDF7F0;
                  font-weight: bold;
                  border: 2px solid #FFA7E5;
                  border-radius: 5px;
                  margin-left: 3px;
                  cursor: pointer;
                }
                .map-button {
                  width: 100px;
                  background-color: rgba(255, 167, 229, 0.0);
                  color: #FFA7E5;
                  padding-left: 5px;
                  padding-right: 5px;
                  border: 2px solid #FFA7E5;
                }
                .reset-button {
                  margin-right: 3px;
                  background-image: url('../../../../public/website_images/reset.png');
                  background-position: center;
                  background-size: cover;
                  background-repeat: no-repeat;
                }
              }

              .search-block {
                margin-top: 1%;
                margin-bottom: 1%;
                // align-items: center;
                button {
                  margin-top: 1%;
                  
                }
                .search-box {
                  width: 100px;
                }
              }
       
              .desktop-view-toggle {
                display: none;
              }
            }
            .lifestyle-table{
              .table-filters {
                margin-top: 2%;
                margin-bottom: 4%;
                margin-right: 0%;
                overflow: auto;
                // transform: rotateX(180deg);

                align-items: center;
                padding-bottom: 2%;
                position: relative;

                .filter {
                  // width: 50%;
                  min-width: 40%;
                  border-radius: 10px;
                  // transform: rotateX(180deg);
                  z-index: 99;
                  margin-right: 3%;

                  .Dropdown-control {
                    border-radius: 10px !important;
                    overflow: auto;
                  }
                  .filter-title {
                    display: none;
                  }
                  .desktop-dropdown {
                    display: none;
                  }
                  .mobile-dropdown {
                    display: block;
                    width: 100%;
                    select {
                      width: 100%;
                      border-radius: 10px;
                      padding: 6px;
                      white-space: nowrap;
                      background-color: #FDF7F0;
                      font-size: 0.9rem;
                      color: #051885;
                      border: 1px solid #051885;
                    }
                  }
                } 

                #mobile-rating{
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  width: 50%;

                  .filter-title{
                    display: block;
                    white-space: nowrap;
                    margin-right: 10%;
                  }
                  .slider-container{
                    min-width: 150px;
                  }
              
                }
              }
              .table-content {
                margin-top: 1%;
                margin-left: 1%;
               
                .column-1 {
                  width: 10%;
                }
                .column-2 {
                  width: 47.5%;
                  padding-right: 10px;
                }
                .column-3 {
                  width: 27.5%;
                  padding-right: 10px;

                }
                .column-4 {
                  width: 15%;
                }
                #gym-option {
                  width: 40%;
                }
                #school-option {
                  width: 45%;
                  text-align: left;
                }
                // .column-5 {
                //   width: 15%;
                // }
                .table-details {
                  padding: 5px 5px 5px 5px;
                }
              }
              .sub-title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-right: 0%;
                h1 {
                  font-size: 1rem;
                  color: #051885;
                }
                select {
                  width: 100px;
                  background-color: #FDF7F0;
                  border-radius: 10px;
                  border: 1px solid #051885;
                  padding: 5px;
                  color: #051885;
                }
                #map-icon {
                  display: block;
                }
              }
              .lifestyle-map {
                margin-top: 0%;
                width: 100% !important;
                display: block;
                .mobile-content {
                  display: flex;
                  flex-direction: column;
                  .map-section {
                    display: block;
                    width: 100% !important;
                    height: auto;
                    margin-top: 3%;
                    margin-bottom: 2%;
                    .mapboxgl-canvas {
                      width: 100% !important;
                      height: 100%;
                    }
                  }
                }
                .content-section {
                  width: 100%;
                  .map-list-title {
                    .sub-title {
                      margin-right: 0%;
                    }
                  }
                }
                .map-section {
                  display: none;
                }
              }
            }

            .admin-portal {
              display: flex;
              flex-direction: column;
              .admin-list-section {
                display: none;
              }
              .admin-title {
                margin-left: 0%;
                font-size: 1rem;
              }
              .left-detail {
                width: 100%;
                border-right: none;
                .admin-intro-section {
                  padding-right: 0%;
             
                }
              }
              .bottom-detail {
                .admin-list-section {
                  display: block;
                  border-top: none;
                  .bills-table-title {
                    margin-right: 0%;
                  }
                  .bills-table-headers {
                    margin-right: 0%;
                    color: #051885;
                    margin-top: 5%;

                  }
                  .bills-table-content {
                    margin-right: 0%;
                    color: #051885;
                  }
                  .notes-divider {
                    margin-top: 1%;
                  }
                }
              }
              .right-detail {
                width: 100%;
                margin-top: 10%;
                .bar-section {
                  padding-left: 0%;
                  .bar-wrapper {
                    overflow-x: scroll;
                    overflow-y: hidden;
                  }

          
                  .recharts-wrapper {
                    margin-top: 5%;


                  }
                }
                .donut-section {
                  width: 100%;
                  border-top: none;
                  margin-top: 5%;
                  padding-left: 0%;
                  .admin-title {
                    margin-left: 0%;
                  }
                  .donut-box {
                    .donut-detail {
                      width: 55%;
                      .desktop-donut{
                        display: none;
                      }
                      .mobile-donut{
                        display: block;
                      }
                    
                    }
                    .recharts-text.recharts-label {
                      font-size: 1.3rem;
                    }
                    .donut-content {
                      margin-left: 0%;
                      width: 45%;
                      .legend-row {
                        margin-top: 7.5%;
                        margin-bottom: 7.5%;
                        .legend-box {
                          width: 15px;
                          height: 15px;
                          background-color: #FFA7E5;
                          margin-right: 5%;
                        }
                        h3 {
                          color: #051885;
                          font-size: 0.8rem;
                        }
                      }
                    }
                  }
                }
              }
            }
            .admin-portal-no-account {
              flex-direction: column;
              .no-account-left {
                width: 100% !important;
                margin-top: 0% !important;
                padding-right: 5% !important;
                button {
                  width: 150px;
                }
              }
              .no-account-right {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 10%;
                margin-bottom: 10%;
                .living-admin-image {
                  width: 295px !important;
                }
              }
            }
        }
      }
    }
  }


}