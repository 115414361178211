

.single-school-profile {
  margin-right: 2%;
  margin-top: 1%;
  padding-bottom: 5%;
  background-color: white;
  padding: 2%;
  padding-left: 3%;
  // max-height: 55vh;
  overflow: auto;
  border-radius: 25px;

  .school-core-info {
    display: flex;
    flex-direction: row;

    .info-left {
      width: 50%;
      color: #1A276C;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .normal {
        font-weight: 500;
        font-size: 0.8rem;
      }
      h1 {
        font-size: 0.9rem;
      }
      .website {
        font-style: italic;
        font-weight: 700;
        font-size: 0.8rem;
        color: #1A276C;
      }
    }

    .info-right {
      width: 50%;
      height: 225px;
      .school-image {

        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.4;
        height: 100%;
        width: 100%;
        border-radius: 25px;
      }
      #secondary {
        background-image: url('../../../../public/website_images/secondary-image.png');
      }
      #primary {
        background-image: url('../../../../public/website_images/primary-image.png');
      }

    }
  }

  .school-academic-highlights {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    h1 {
      color: #1A276C;
      font-size: 0.9rem;
    }


    .row {
      display: flex;
      flex-wrap: wrap;
      // flex-direction: row;
      // justify-content: space-between;
      margin-top: 1%;

      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 48%;
        min-width: 33%;
        margin-top: 2%;
        .circle {
          min-width: 65px;
          height: 65px;
          h1 {
            background-color: #1A276C;
            width: 100%;
            height: 100%;
            color: #ED6B86;
            font-size: 0.8rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 35px;
          }
        }
        p {
          padding-left: 5%;
          padding-right: 5%;
          color: #1A276C;
          font-size: 0.8rem;
        }
      }
    }
  }

  .school-results {
    margin-top: 5%;
    color: #1A276C;
    h1 {
      color: #1A276C;
      font-size: 0.9rem;
    }
    #column1 {
      width: 3%;
    }
    #column2 {
      width: 57%;
    }
    #column3 {
      width: 20%;
    }
    #column4 {
      width: 20%;
    }
    .school-table-headers {
      display: flex;
      flex-direction: row;
      font-weight: 700;
      margin-top: 1%;
      margin-bottom: 0.75%;
      font-size: 0.9rem;
    }
    .no-results {
      margin-top: 1%;
      color: #1A276C;
      font-weight: 500;
      font-style: italic;
    }


    .school-table-details {
      display: flex;
      flex-direction: column;

      .school-content {
        display: flex;
        flex-direction: row;
        margin-top: 0.75%;
        margin-bottom: 0.75%;
        h5 {
          font-weight: 500;
          font-size: 0.7rem;
        }
      }
      .dividing-line {
        visibility: visible;
        border: 0.5px solid rgba(5, 24, 133, 0.2);

      }
      .dividing-line:last-of-type {
        visibility: hidden;
      }
    }
  }
}






@media screen and (max-width: 800px) {

  .desktop-nav {
    display: none !important;
  }
  .mobile-nav {
    display: block !important;
  }
  .single-school-profile {
    margin-left: 5% !important;
    margin-top: 3% !important;

    .school-core-info {
      .info-left {
        h1 {
          font-size: 0.8rem !important;
        }
        .normal, a {
          font-size: 0.75rem !important;
        }
      }
      .info-right {
        height: 250px !important;
      }
    }

    .school-academic-highlights {
      margin-top: 10% !important;
      .row {
        flex-wrap: wrap;
        .item {
          min-width: 50% !important;
          max-width: 50% !important;
          margin-bottom: 2%;
          p {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
  .school-results {
    margin-top: 10% !important;

    overflow-x: scroll !important;
    #column1 {
      min-width: 10%;
    }
    #column2 {
      min-width: 57%;
    }
    #column3 {
      min-width: 30%;
    }
    #column4 {
      min-width: 40%;
    }
    .dividing-line {
      margin-top: 1% !important;
      margin-bottom: 1% !important;
      width: 130%;
    }
  }
}




@media screen and (max-width: 500px) {
  .go-back-button {
    margin-top: 3% !important;
    margin-bottom: 0% !important;
    margin-left: 5% !important;
  }
  .school-core-info {
    .info-left {
      width: 100% !important;
      height: 200px;
    }
    .info-right {
      display: none;
    }
  }

}