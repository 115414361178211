.fade.normal-search-modal.modal.show {
  margin-left: 0%;
  margin-top: 0%;
  width: 40%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  border-radius: 0px !important;

  .modal-dialog {
    padding: 3%;

    .modal-body {
      .content-sections {
        margin-top: 5%;
      }
      .search-bar-input {
        width: 100%
      }

      .normal-search-header {
        display: flex;
        flex-direction: column;

        .title-box {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .title {
            color: #FFA7E5;
            font-size: 2rem;
          }

          .close {
            color: #051885;
            cursor: pointer;
          }
        }

        p {
          color: #051885;
          font-size: 0.8rem;
        }
      }


    }
  }
}

.normal-search-content {

  // margin-top: 5%;
  .content-sections {
    margin-bottom: 1.5%;

    .title {
      color: #FFA7E5;
      margin-bottom: 1%;
      font-size: 1rem;
    }

    h3 {
      font-size: 0.9rem;
      color: #051885;
      font-weight: 700;
    }

    .search-bar-input {
      width: 55%;
    }

    .button-selectors {
      button {
        margin-top: 0.5%;
        width: 100px;
        border: 1px solid #051885;
        padding: 5px;
        color: #051885;
      }

      #buy {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }

      #rent {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    .location-box {
      display: flex;
      margin-top: 0.5%;
      align-items: center;
      width: 40%;

      h1 {
        margin-right: 0.5%;
        font-size: 1.3rem;
      }

      input {
        border-radius: 5px;
        border: 1px solid #051885;
        padding-left: 5px;
      }
    }

    .dropdown-sections {
      display: flex;
      margin-top: 0.5%;

      .dropdowns {
        margin-right: 5%;

        .property-control {
          width: 100px;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid #051885;
          color: #051885;
        }
      }
    }

    .search-button {
      margin-top: 1%;
      width: 100px;
      border: 1px solid #051885;
      padding: 5px;
      color: #FDF7F0;
      font-weight: bold;
      background-color: #051885;
      border-radius: 10px;
      cursor: pointer;
    }
  }

}


@media screen and (max-width: 800px) {

  .fade.normal-search-modal.modal.show {
    margin-left: 0%;
    margin-top: 0%;
    width: 100%;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    border-radius: 0px !important;

    .modal-dialog {
      .modal-body {
        .normal-search-header {

          .title-box {

            .title {
              color: #FFA7E5;
              font-size: 1.7rem;
            }
          }
        }

      }
    }

  }

  .normal-search-content {

    // margin-top: 5%;
    .content-sections {
      margin-bottom: 5%;
    }

    .location-box {
      width: 100% !important;
    }
  }

}