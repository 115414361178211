// KYC modal 
.fade.add-client-modal.modal.show {
  margin-left: 0%;
  margin-top: 0%;
  width: 45%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  // background-color: #ED6B86;
  background-color: #FDF7F0;
  // padding: 15px;
  overflow: auto;

  .modal-dialog {
    padding: 15px;


    .modal-body {}
  }

  .overall-body {
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    min-height: 95vh;



    .input-header {
      .input-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h1 {
          color: #1A276C;
          font-size: 1rem;
        }

        .close-modal {
          cursor: pointer;
        }
      }

    }

    .input-main {
      margin-top: 3%;

      .input-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-top: 2%;
        margin-bottom: 2%;

        h3 {
          width: 30%;
          font-size: 0.9rem;
          color: #1A276C;
        }

        input {
          width: 40%;
          padding: 5px;
          border:  1px solid #1A276C;
          border-radius: 10px;
        }
      }
    }

    .input-footer {
      width: 70%;
      display: flex;
      justify-content: flex-end;
      button {
        background-color: #ED6B86;
        color: #FDF7F0;
        font-size: 0.8rem;
        font-weight: 600;
        border: 1px solid #ED6B86;
        border-radius: 10px;
        cursor: pointer;
        padding: 5px;
        width: 125px;
      }
    }
  }
}

@media screen and (max-width: 900px) {

  .fade.add-client-modal.modal.show {
    margin-left: 0%;
    margin-top: 0%;
    width: 100vw;
    height: 100%;
    

    .input-row {
      h3 {
        width: 30% !important;
      }
      input {
        width: 40% !important;
      }
    }
  }

}

@media screen and (max-width: 600px) {

  .fade.add-client-modal.modal.show {
    margin-left: 0%;
    margin-top: 0%;
    width: 100vw;
    height: 100%;
    

    .input-row {
      h3 {
        width: 40% !important;
      }
      input {
        width: 60% !important;
      }
    }
    .input-footer {
      width: 100% !important;
    }
  }

}