.blog-page  {
  min-height: 100vh;
  .borough-guide-header {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    height: 600px;
    width: 100%;
    // margin-left: 5%;
    // margin-right: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 2%;

    .borough-title {
      font-size: 3.5rem;
      // margin-top: 5%;
      color: #ED6B86 ;
      z-index: 99;
    }
    .borough-sub-title {
      z-index: 99;
      color: #ED6B86;
      font-size: 1rem;
      margin-top: 10px;
      text-transform:uppercase;

    }

    .borough-stats {
      z-index: 99;
      margin-top: 20px;
      .single-stat {
        margin-bottom: 6px;
        // color: #1A276C;
        color: #FDF7F0;
        font-size: 1rem;
      }
    }

    .bottom-insights {
      position: absolute;
      bottom: 0;
      padding-bottom: 1%;
      padding-top: 1%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #FDF7F0;
      z-index: 1;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.1);  
    h4 {
        width: 33%;
      }
    }
  }

  .borough-guide-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); 
    // background-color: rgba(255, 255, 255, 0.7);  
    z-index: 1;
  }

  .borough-detail-section {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2%;
    h1 {
      color: #ED6B86;
      font-size: 1.2rem;
    }
    .summary-section {
      margin-top: 1%;
      display: flex;
      flex-direction: row;
      margin-bottom: 4%;
      .summary-bio {
        color: #1A276C;
        width: 100%;
        font-size: 0.9rem;
      }
    }

    .property-highlight {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #1A276C;
        font-size: 0.9rem;
        cursor: pointer;
      }

      .highlight-separator {
        visibility: visible;
        margin-top: 2%;
        margin-bottom: 2%;
        border: 1px solid rgba(0, 0, 0, 0.05);  

      }

      .school-section {
        h1 {
          margin-top: 0.5%;
          font-size: 1rem;
        }
        .primary-detail {
          margin-top: 0.5%;
          display: flex;
          flex-direction: row;
          .primary-stats {
            color: #1A276C;
            font-size: 0.9rem;
            width: 30%;
            padding-right: 2%;

            h3 {
              font-weight: bold;
              color: #ED6B86;
            }
            h5 {
              font-weight: 400;
              margin-top: 5%;
              margin-bottom: 5%;
              font-size: 0.8rem;
              padding-right: 1%;
            }
          }

          .independent-title {
            margin-top: 5% !important;
          }

          .table {
            overflow-x: scroll;
          }
          .primary-table-section {
            color: #1A276C;
            font-size: 0.9rem;
            width: 70%;
            // overflow-x: scroll;
            h3 {
              font-weight: bold;
              color: #ED6B86;
            }

            .secondary-title {
              margin-top: 3%;
              // font-size: 0.9rem;
            }
            .description {
              margin-top: 2%;
              font-size: 0.8rem;

            }

            h6 {
              font-size: 0.9rem;
              font-style: italic;
              margin-top: 1.5%;
            }


            .state {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 100%;
              .column {
                margin-top: 0.5%;
                margin-bottom: 0.5%;
              }
              #column0 {
                width: 5%;
                min-width: 5%;
                padding-right: 2%;
              }
              #column1 {
                width: 45%;
                min-width: 45%;
                padding-right: 2%;
              }
              #column2 {
                width: 30%;
                min-width: 30%;
              }
              #column3 {
                width: 30%;
                min-width: 30%;
              }
              #column4 {
                width: 30%;
                min-width: 30%;
              }
              #column5 {
                width: 30%;
                min-width: 30%;
              }
              #column6 {
                width: 15%;
                min-width: 15%;
              }

            }
            .table-separator {
              visibility: visible;
              margin-top: 0.5%;
              margin-bottom: 0.5%;
              border: 1px solid rgba(0, 0, 0, 0.05);  
            }
            .table-separator:last-of-type {
              visibility: hidden;
            }

            #state-separator {
              width: 180%;
            }
            #independent-separator {
              width: 130%;
            }




            #content {
              h5 {
                font-weight: 400;
              }
            }
            #headers {
              font-weight: 700;
            }

            .detail-content {
              display: flex;
              flex-wrap: wrap;
              margin-top: 2%;
              margin-bottom: 2%;
              .line-item{
                width: 50%;
                font-size: 0.8rem;
                font-weight: 400;
                margin-bottom: 2%;
                padding-right: 2%;
              }
            }

            .chart-container {
              overflow-x: scroll;
              width: 100%;
            }
          }
        }
      }
    }
    .school-block {
      margin-top: 2%;
  
  
      .map-grid-view {
        display: flex;
        flex-direction: row;
        height: 550px;
  
        .grid-list {
          width: 30%;
          overflow-y: scroll;
          padding-right: 2%;
          color: #1A276C;
  
          .school-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            .grid-left {
              width: 10%;
              display: flex;
              justify-content: center;
            }
            .grid-right {
              .title {
                font-size: 0.8rem;
                font-weight: 700;
                cursor: pointer;
              }
              h5 {
                font-size: 0.7rem;
                font-weight: 500;
              }
            }
          }
  
          .dividing-line {
            border: 0.5px solid rgba(5, 24, 133, 0.1);
            visibility: visible;
            margin-top:2%;
            margin-bottom: 2%;
          }
          .dividing-line:last-of-type {
            visibility: hidden;
          }
        
        }
        .map-section {
          width: 70%;
          height: 550px !important;
          margin-right: 0% !important;
  
          .mapboxgl-map {
            height: 100% !important;
            border: 0px !important;
            .poi-background {
              background-color: #1A276C;
              // padding: 5px;
              width: 30px;
              height: 30px;
              border-radius: 15px;
              z-index: 1;
              cursor: pointer;
              font-size: 0.75rem;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #ED6B86;
              // color: #1A276C;
              font-weight: bold;
            }
              .property-icon {
                font-size: 2rem; 
              }
  
  
            .catchment-area {
              z-index: 99;
              fill-color: #ED6B86; /* Adjust the fill color as desired */
              fill-opacity: 0.1; /* Adjust the fill opacity as desired */
              stroke-width: 2px; /* Adjust the stroke width as desired */
              stroke-color: #ED6B86; /* Adjust the stroke color as desired */
              stroke-opacity: 0.8; /* Adjust the stroke opacity as desired */
            }
  
            .popup-content {
              .popup-border {
                text-align: center;
                p {
                  font-size: 0.6rem;
                }
              }
            }
          }
        }
  
    
      }
  
 
    }
    .detail-slider {
      margin-left:0% !important;
      margin-top: 1%;
      display: flex;
      overflow-x: scroll;
      h5 {
        margin-right: 1%;
        border: 1px solid #ED6B86;
        padding: 3px 10px 3px 10px;
        border-radius: 10px;
        color: #1A276C;
        cursor: pointer;
        background-color: rgba(255, 167, 229, 0.2);
        font-weight: 400;
        font-size: 0.8rem;
      }
    }
    .pagination {
      margin-top: 2%;
      // margin-bottom: 2%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style-type: none;
      margin-left: 3%;
      margin-right: 5%;
      padding: 0%;
      font-size: 0.7rem;
      .next, .previous, li {
        background-color: #FDF7F0;
        width: 30px;
        height: 30px;
        margin-top: 0%;
        padding: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ED6B86;
      }
      .next, .previous {
        border-radius: 15px;
        border: 2px solid #ED6B86;
        color: #ED6B86;
        font-size: 1rem;
      }
      li {
        font-size: 0.7rem;
        cursor: pointer;
      }
      .active {
        border-radius: 15px;
        border: 2px solid #ED6B86;
        // background-color: #FDF7F0;
        background-color: #ED6B86;
        // color: #ED6B86;
        color: #FDF7F0;
        font-weight: bold;
  
      }
    }
  }



  @media screen and (max-width: 850px) {

    .primary-detail {
      flex-direction: column !important;
      .primary-stats {
        width: 100% !important;
        h5 {
          margin-top: 2% !important;
          margin-bottom: 2% !important;
        }
      }
      .primary-table-section {
        width: 100% !important;
      }
    }

  }



  @media screen and (max-width: 600px) {
    .borough-title {
      font-size: 2.5rem !important;
    }

    .borough-stats {
      margin-bottom: 10% !important;
      h3 {
        font-size: 0.8rem !important;
      }
    }
    .bottom-insights {
      h4 {
        font-size: 0.8rem !important;
      }
    }

    .borough-detail-section {
      margin-top: 5% !important;
      margin-left: 5% !important;
      margin-right: 5% !important;
      padding-bottom: 5%;
      .summary-section {
        .summary-bio {
          font-size: 0.8rem !important;
        }
      }
    }

    .primary-detail {
      .primary-stats {
        h3 {
          margin-top: 2% !important;
          font-size: 0.9rem !important;
        }
        h5 {
          font-size: 0.7rem !important;
          // margin-top: 5% !important;
          margin-bottom: 5% !important;
        }
      }
      .primary-table-section {
        h3 {
          margin-top: 2% !important;
          font-size: 0.9rem !important;
        }
        h5 {
          font-size: 0.7rem !important;
          margin-top: 1% !important;
          margin-bottom: 1% !important;
        }
        h6 {
          margin-bottom: 2% !important;
        }
        p {
          font-size: 0.7rem !important;
        }
        .table-content {
          .table-separator:last-of-type {
            visibility: hidden !important;
          }
        }
      }
    }
    .detail-slider {
      margin-top: 3% !important;
      margin-bottom: 3% !important;
    }

  }