.property-finder {
  color: #1A276C;

  // margin-right: 5%;
  // margin-bottom: 5%;
  margin-top: 1%;
  margin-left: 1%;
  // min-height:100vh;
  // height: 100vh;
  h1 {
    margin-top: 5%;
  }

  .limit-reached {
    font-size: 0.9rem;
    font-weight: 500;
    width: 30%;
    margin-top: 1%;
  }

  .epc-matcher {
    display: flex;
    flex-direction: row;
    height: 100%;


    .input-section {
      width: 40%;
    }
  
    
    .input-section, .manual-property-results {
      margin-top: 2%;
      padding-left: 2%;
      padding-right: 0%;
      width: 40%;

      // height: 80vh;
      // box-shadow: 1px 2px 1px 0 rgba(0, 0, 0, 0.1), -1px 2px 1px 0 rgba(0, 0, 0, 0.1);
      // border-radius: 10px;

      .sub-title {
        color: black;
        font-weight: 500;
        margin-bottom: 5%;
        margin-top: 5%;
        font-size: 0.8rem;
      }

      .double-input-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 3%;
        margin-bottom: 3%;
        margin-right: 3%;
        .input-block:first-of-type {
          margin-right: 7.5px;
        }
        .input-block:nth-of-type(2) {
          margin-left: 7.5px;
        }
  
  
  
  
      }
      .single-input-block {
        margin-bottom: 3%;
      }
      .input-block.medium {
        width: 65%;
      }
      .input-block.small {
        width: 35%;
        // margin-right: 15px;
  
      }
      .input-block.large {
        width: 97%;
      }
      .input-block.half {
        width: 50%;
      }
  
      .input-block {
        display: flex;
        flex-direction: column;
        // align-items: center;
        margin-bottom:7%;
        h3 {
          color: #1A276C;
          font-weight: 600;
          font-size: 0.8rem;
          margin-bottom: 10px
        }
        input {
          padding: 7.5px;
          border-radius: 7.5px;
          border: 1px solid #1A276C;
          font-size: 0.8rem;
        }
        select {
          padding: 7.5px;
          border-radius: 7.5px;
          border: 1px solid #1A276C;
          font-size: 0.8rem;
          width: 100%;
        }
      }
      .search-section {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 3%;
        .load-insights {
          width: 175px;
          padding: 7.5px;
          border-radius: 7.5px;
          background-color: #ED6B86;
          border: 1px solid #ED6B86;
          color: #FDF7F0;
          font-weight: 700;
          cursor: pointer;
        }
      }

      .loading-gif {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .tracking-results {
        // margin-top: 55%;
        position: absolute;
        bottom: 2%;
        .sub-title {
          margin-bottom: 1%;
          color: #1A276C;
          font-size: 0.8rem;
        }
        p {
          font-size: 0.8rem;
          font-weight: 700;
          color: #ED6B86;
          // margin-top: 2%;
        }
      }
      
    }

    .manual-property-results {
      width: 60%;
      margin-left: 2%;
      margin-right: 2%;
      overflow: auto;
      border-left: 1px solid rgba(5, 24, 133, 0.15);



      .results-block {
        .column {
          margin-bottom: 1%;
        }
        
        #column1 {
          width: 5%;
        }
        #column2 {
          width: 50%;
        }
        #column3 {
          width: 17%;
        }
        #column4 {
          width: 18%;
        }
        #column5 {
          width: 10%;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          button {
            width: 25px;
            height: 25px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .add {
            background-color: #1A276C;
            color: #ED6B86;
            border: 1px solid #1A276C;

          }
          .added {
            background-color: #ED6B86;
            color: #1A276C;
            border: 1px solid #ED6B86;
            
          }
        }
        .results-headers {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 3%;
          margin-right: 2%;
          h5 {
            font-size: 0.75rem;
            color: rgba(0, 0, 0, 0.4);

          }
        }
        .results-details {
          max-height: 75vh;
          z-index: 1;
          .results-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .row-value {
              width: 5%;
              text-align: center;
              font-size: 0.8rem;
              font-weight: 500;
              color: rgba(0, 0, 0, 1);


            }
            .results-items {
              width: 85%;
              display: flex;
              flex-wrap: wrap;
              .results-line {
                display: flex;
                flex-direction: row;
                align-items: center;
                .result-icon {
                  width: 22px;
                  height: 20px;
                  background-position: center;
                  background-size: cover;
                  background-repeat: no-repeat;
                  margin-right: 5px;
                }
                .results-text {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                }
                h5 {
                  font-weight: 500;
                  font-size: 0.7rem;
                  color: #1A276C;
                  margin-top: 1.5%;
                  margin-bottom: 1.5%;
                  padding-right: 2%;
                }
              }
              .results-line:nth-child(odd) {
                width: 65%;
                .text-title {
                  width: 35%;
                  font-weight: 600;
                }
                .text-result {
                  width: 65%;
                  font-weight: 400;

                }
              }
              .results-line:nth-child(even) {
                width: 35%;
                .text-title {
                  width: 60%;
                  font-weight: 600;

                }
                .text-result {
                  width: 40%;
                  font-weight: 400;

                }
              }
            }
            .expansion {
              width: 20px;
              height: 20px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
              border: none;
              color: #FDF7F0;
              font-weight: 700;
            }
          }
        }

        .property-divider {
          visibility: visible;
          border: 0.5px solid rgba(5, 24, 133, 0.15);
          margin-top: 1%;
          margin-bottom: 1%;
          margin-left: 3%;
          margin-right: 3%;
        }

        .buttons {
          display: flex;
          flex-direction: column;
          justify-content:space-between;
          align-items: flex-end;
          // margin-right: -0.5%;
        }

        .heart-icon {
          width: 20px;
          height: 20px;
          background-position: center;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-image: url('../../../../public/white_icons/heart.png');
          margin-bottom: 1%;

        }

        .expanded-section-titles, .expanded-content, .matching-title {
          margin-left: 9%;
          margin-right: -2%;
          display: flex;
          flex-direction: row;
          align-items: center;
          .column {
            font-size: 0.65rem;
          }
          #column1 {
            width: 5%;
            text-align: center;
          }
          #column2 {
            width: 45%;
          }
          #column3 {
            width: 13%;
          }
          #column4 {
            width: 13%;
          }
          #column5 {
            width: 13%;
          }
          #column6 {
            width: 11%;
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
      

          }
          
        }
        .matching-title {
          font-size: 0.7rem;
          margin-top: 1%;
          margin-bottom: 1%;
          // margin-left: 6%;
          color:#ED6B86;

        }

        .expanded-section-titles {
          // color:rgba(5, 24, 133, 0.85);
          color:#ED6B86;

          font-weight: 600;
        }
        .expanded-content {
          // color:rgba(5, 24, 133, 0.85);
          color:#ED6B86;
          cursor: pointer;
          font-weight: 500;

        }

        .expanded-section {
          .denied {
            margin-left: 5%;
            color: #ED6B86;
            font-size: 0.7rem;
            font-weight: 600;
          }
        }
      }
    }
  }




}