
.fade.agent-success-modal.modal.show {
  // margin-left: 0%;
  // margin-top: 50% !important;
  // width: 40%;
  // height: 20% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  border-radius: 10px !important;
  box-shadow: 0px 10px 10px 200vh rgba(0, 0, 0, 0.6) !important;

  .modal-dialog {
    padding: 2% 2% 2% 2%;

    .modal-content {

      .modal-body {
        color: #1A276C;


        .waitlist-close{
          background-color: #ED6B86;
          border-radius: 5px;
          border: 1px solid #ED6B86;
          padding: 5px;
          color: #FDF7F0;
          font-weight: bold;
          font-size: 1rem;
          margin-top: 2%;
          cursor: pointer;
          width: 100%;
        }
      }
    }
  }


}