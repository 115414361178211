.fade.waitlist-modal.modal.show {
  // margin-left: 0%;
  // margin-top: 0%;
  // width: 40%;
  // height: 20% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  border-radius: 10px !important;
  box-shadow: 0px 10px 10px 100vh rgba(0, 0, 0, 0.3) !important;
  .modal-dialog {
    padding: 2% 2% 2% 2%;

    .modal-content {

      .modal-body {
        color: #1A276C;
  
        .body-section {
          text-align: left;
          h3 {
            font-size: 1rem;
          }
          h2 {
            color: #ED6B86;
            margin: 0%;
            font-size: 1rem;
          }
          .preferences {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }
          .waitlist-close{
            background-color: #ED6B86;
            border-radius: 5px;
            border: 1px solid #ED6B86;
            padding: 5px;
            color: #FDF7F0;
            font-weight: bold;
            font-size: 1rem;
            margin-top: 2%;
            cursor: pointer;
            width: 100%;
          }
        }
      }
    }

  }
}



@media screen and (max-width: 820px) {
  .fade.waitlist-modal.modal.show {
    margin-top: 40% !important;
  }
}



@media screen and (max-width: 600px) {
  .fade.waitlist-modal.modal.show {
    margin-top: 50% !important;
  }
}