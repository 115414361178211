.fade.register-modal.modal.show {
  margin-left: 60%;
  margin-top: 0%;
  width: 40%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  border-radius: 0px !important;
  // background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url('../../../../public/website_images/islington.png');
  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  .modal {
    box-shadow: 0 10px 10px 100vw rgb(0 0 0 / 70%) !important;
  }
}



.modal-body {
  .form-detail {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 10%;
    margin-bottom: 0%;
    .register-title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .x-close {
        font-size: 1.1rem;
        cursor: pointer;
      }
    }
    hr {
      margin: 0%;
    }
    .input {
      margin-left: 0%;
      margin-right: 0%;
      width: 100%;
      margin-top: 5%;
      // background-color: #FDF7F0;
      background-color: rgb(232, 240, 254, 0.4);

    }
    .login-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 0%;
      .password-input-register {
        margin-top: 5%;
        width: 85%;
        margin-left: 0%;
        color: #051885;
        border-top-right-radius: 0%;
        border-bottom-right-radius: 0%;
        border-right: none;
        background-color: rgb(232, 240, 254);

      }
      .password-icon-container {
        border-right: 1px solid #051885;
        border-top: 1px solid #051885;
        border-bottom: 1px solid #051885;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        border-left: none;
        width: 15%;
        height:30px;
        margin-top: 5%;
        background-color: rgb(232, 240, 254);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .password-icon {
          background-image: url('../../../../public/website_icons/password.png');
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          // margin-top: 8%;
          // padding: 5px;
        }
      }
    }
    .error {
      margin-bottom: 0%;
      font-size: 0.8rem;
      font-weight: bold;
    }

    h1 {
      font-size: 1.2rem;
      margin-bottom: 5%;
      color: #051885;
    }
    p {
      margin-bottom: 5%;
      color: #051885;
    }
    .denied-text {
      font-size: 0.7rem;
      margin-left: 3%;
      margin-bottom: 0%;
      font-style: italic;
    }
    button {
      margin-top: 5%;
      border-radius: 7px;
      background-color: #FFA7E5;
      padding: 5px;
      border: 2px solid #FFA7E5;
      color: white;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .register-bottom {
    .register-close {
      margin-left: 10%;
      margin-top: 5%;
      background-color: black;
      border-radius: 7px;
      border: 2px solid black;
      padding: 5px;
      color: white;
      font-weight: bold;
      width: 100px;
      cursor: pointer;
    }
  }
}




@media screen and (max-width: 900px) {
  .fade.register-modal.modal.show {
    margin-left: 0%;
    margin-top: 0%;
    width: 100%;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    border-radius: 0px !important;
    // background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url('../../../../public/website_images/islington.png');
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-attachment: fixed;
    .modal {
      box-shadow: 0 10px 10px 100vw rgb(0 0 0 / 70%) !important;
    }
  }


}