.top-properties.single {
  height: auto;
  margin-top: 2%;
}


.top-properties {
  margin: 0% 3% 2% 3%;
  box-shadow: 1px 2px 1px 0 rgba(0, 0, 0, 0.1), -1px 2px 1px 0 rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-bottom: 2%;
  height: 77vh;

  .top-properties-filters {
    display: flex;
    flex-direction: row;
    margin-left: 1%;
    margin-right: 1%;
    padding-top: 1%;

    .filter-block.mobile {
      display: none;
    }

    .filter-block {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-right: 2%;

      button {
        width: 125px;
        background-color: #FDF7F0;
        color: #ED6B86;
        font-size: 0.8rem;
        font-weight: 600;
        padding: 5px;
        border-radius: 10px;
        border: 1px solid #ED6B86;
        cursor: pointer;
      }
    }

    .load-properties {
      background-color: #ED6B86;
      color: #FDF7F0;
      border-radius: 10px;
      border: 1px solid #FDF7F0;
      width: 5%;
      font-weight: 600;

    }
  }

  .top-property-results {
    margin-top: 1%;
    margin-left: 1%;

    .top-property-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 3%;
      margin-left: 1%;

      .view-selectors {
        display: flex;
        flex-direction: row;

        button {
          width: 125px;
          background-color: #ED6B86;
          color: #FDF7F0;
          font-size: 0.8rem;
          font-weight: 600;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid #ED6B86;
          cursor: pointer;
        }
      }

      .icon-box {
        width: 30px;
        height: 30px;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: 1%;

        .icon {
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
        }

        #table {
          background-image: url('../../../../public/white_icons/Blue/list.svg');

        }

        #map {
          background-image: url('../../../../public/white_icons/map.svg');
        }

        #grid {
          background-image: url('../../../../public/white_icons/grid-blue.png');
        }
      }

      .icon-box.active {
        background-color: #1A276C;

        #table {
          background-image: url('../../../../public/white_icons/table-beige.svg');
        }

        #map {
          background-image: url('../../../../public/white_icons/White/location.svg');
        }

        #grid {
          background-image: url('../../../../public/white_icons/grid-beige.png');
        }
      }

      .icon-box.inactive {
        background-color: rgba(26, 39, 108, 0.05);
      }

      h3 {
        font-size: 0.8rem;
      }
    }

    .top-property-table {
      overflow: auto;

      .table-detail {
        height: 56vh;

        // overflow: auto;
        .table-content {
          display: flex;
          flex-direction: row;
          font-size: 0.75rem;

        }
      }

      .table-headers {
        display: flex;
        flex-direction: row;
        font-size: 0.85rem;

        .column {
          color: black;
        }
      }

      .column {
        margin-top: 0.75%;
        margin-bottom: 0.75%;
        padding-right: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: rgba(0, 0, 0, 0.5);

      }

      #column1 {
        min-width: 3%;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      #column2 {
        width: 20%;
      }

      #column3 {
        width: 9%;
      }

      #column4 {
        width: 9%;
      }

      #column5 {
        width: 9%;
      }

      #column6 {
        width: 7%;
      }

      #column7 {
        width: 7%;
      }

      #column8 {
        width: 20%;
      }

      #column9 {
        width: 7%;
        text-align: left;
      }

      #column10 {
        width: 8%;
        text-align: left;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .favourite-heart {
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          cursor: pointer;
          margin-right: 5%;
        }
        #filled-heart {
          background-image: url('../../../../public/white_icons/heart-filled.png');
        }
        #empty-heart {
          background-image: url('../../../../public/white_icons/heart-outline.png');
        }
        button {
          background-color: #ED6B86;
          border-radius: 5px;
          border: 1px solid #FDF7F0;
          font-weight: 600;
          font-size: 0.7rem;
          color: #FDF7F0;
          cursor: pointer;
        }
      }

      .property-divider {
        visibility: visible;
        border: 0.5px solid rgba(26, 39, 108, 0.15);
        // margin-left: 2%;
        margin-right: 2%;
        cursor: auto;
      }

    }

  }

  .property-table-loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .property-map-view {
    margin-top: 1%;

    .map-block {
      display: flex;
      flex-direction: row;

      .grid-list {
        width: 45%;
        height: 60vh;
        overflow: auto;

        .property-content {
          margin-bottom: 3%;
          display: flex;
          flex-direction: row;

          .grid-left {
            width: 40%;

            .property-image {
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              width: 100%;
              height: 110px;
              border-radius: 20px;
            }
          }

          .grid-right {
            width: 55%;
            padding-left: 2%;

            .title {
              font-size: 0.7rem;
            }

            .sub-title {
              font-weight: 500;
              font-size: 0.7rem;

            }
            .favourite-heart {
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              width: 20px;
              height: 20px;
              cursor: pointer;
              margin-right: 5%;
            }
            #filled-heart {
              background-image: url('../../../../public/white_icons/heart-filled.png');
            }
            #empty-heart {
              background-image: url('../../../../public/white_icons/heart-outline.png');
            }
          }
        }
      }

      .map-section {
        width: 55%;
        margin-right: 3%;
        height: 60vh;

        .mapboxgl-map {
          height: 100% !important;
          border-radius: 20px;

          .poi-background {
            background-color: #1A276C;
            color: #ED6B86;
            font-size: 0.7rem;
            font-weight: 700;
            width: 25px;
            height: 25px;
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }


  .property-grid-view {
    overflow: auto;
    max-height: 68vh;

    .property-grid-items {
      display: flex;
      flex-direction: column;
      margin-top: 1%;

      .property-item {
        display: flex;
        flex-direction: row;
        margin-bottom: 2%;

        .property-item-left {
          width: 50%;

          .property-item-image {
            width: 100%;
            min-height: 280px;
            display: block;
          }

          .property-core-info {
            background-color: rgba(0, 0, 0, 0.07);
            height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            // padding-left: 10%;
            font-size: 0.8rem;
            font-weight: 500;

            h3 {
              margin-left: 5%;
              margin-right: 5%;
              font-size: 0.8rem;
              font-weight: 600;
            }

            .match-score {
              color: #ED6B86;
              // margin-right: 20%;
            }
          }

          .favourite-heart {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 30px;
            height: 30px;
            cursor: pointer;
            margin-right: 5%;
          }
          #filled-heart {
            background-image: url('../../../../public/white_icons/heart-filled.png');
          }
          #empty-heart {
            background-image: url('../../../../public/white_icons/heart-outline.png');
          }
        }

        .property-item-right {
          display: flex;
          flex-direction: column;
          border-top: 1px solid rgba(0, 0, 0, 0.07);
          border-right: 1px solid rgba(0, 0, 0, 0.07);
          border-bottom: 1px solid rgba(0, 0, 0, 0.07);
          width: 49%;

          .detail-selections {
            height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            overflow-x: auto;
            border-bottom: 1px solid rgba(0, 0, 0, 0.07);
            padding-left: 5%;
            overflow: auto;
            .key-selectors {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 80%;
              button {
                background-color: #ED6B86;
                color: #FDF7F0;
                font-size: 0.8rem;
                font-weight: 600;
                border: 1px solid #ED6B86;
                border-radius: 5px;
                cursor: pointer;
              }
            }

            h3 {
              font-size: 0.8rem;
              font-weight: 500;
              margin-right: 10%;
              cursor: pointer;
            }
          }

          .property-body {
            padding: 1% 5% 1% 5%;

            .property-title {
              font-size: 0.9rem;
            }

            .added-details {
              font-size: 0.8rem;
              margin-top: 1%;
              font-weight: 500;
            }

            .key-elements {
              display: flex;
              flex-wrap: wrap;
              margin-top: 2%;

              .property-element-item {
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 2%;

                h3 {
                  font-size: 0.8rem;
                  font-weight: 600;
                  margin-left: 5%;
                }

                .element-icon {
                  background-position: center;
                  background-size: cover;
                  background-repeat: no-repeat;
                  width: 20px;
                  height: 20px;
                }

                #bed {
                  background-image: url('../../../../public/white_icons/blue-bed.png');
                }

                #bath {
                  background-image: url('../../../../public/white_icons/bath-blue.png');
                }

                #size {
                  background-image: url('../../../../public/white_icons/area-blue.png');
                }

                #type {
                  background-image: url('../../../../public/white_icons/property-blue.png');
                }
              }
            }

            .property-features {
              .features-title {
                font-size: 0.9rem;
                margin-bottom: 1%;
              }

              .feature-list {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                .feature-item {
                  width: 50%;
                  padding-right: 2%;
                  font-size: 0.7rem;
                  font-weight: 500;
                  margin-bottom: 1%;
                }
              }
            }


            .insights-body {
              margin-top: 1%;
              .insight-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: 2%;
                margin-bottom: 1%;


                .insight-icon {
                  background-position: center;
                  background-size: cover;
                  background-repeat: no-repeat;
                  width: 25px;
                  height: 25px;
                  // border-radius: 20px;
                  padding: 2px;
                }

                .insight {
                  font-size: 0.8rem;
                  font-weight: 500;
                  margin-left: 2%;
                }

                #primaries {
                  background-image: url('../../../../public/white_icons/Blue/child.svg');
                }

                #secondaries {
                  background-image: url('../../../../public/white_icons/Blue/school-building.svg');
                }

                #tubes {
                  background-image: url('../../../../public/white_icons/Blue/tube.svg');
                }

                #trains {
                  background-image: url('../../../../public/white_icons/Blue/train.svg');
                }

                #evs {
                  background-image: url('../../../../public/white_icons/Blue/electricity.svg');
                }

                #restaurants {
                  background-image: url('../../../../public/white_icons/Blue/food.svg');
                }

                #pubs {
                  background-image: url('../../../../public/white_icons/Blue/beer.svg');
                }

                #parks {
                  background-image: url('../../../../public/white_icons/Blue/park.svg');
                }

                #gyms {
                  background-image: url('../../../../public/white_icons/Blue/gym.svg');
                }

                #supermarkets {
                  background-image: url('../../../../public/white_icons/Blue/supermarket.svg');
                }

                #crime {
                  background-image: url('../../../../public/white_icons/Blue/handcuffs.svg');
                }
              }

            }

            .floorplan-body {
              background-position: center;
              background-size: 60% 100%;
              background-repeat: no-repeat;
              width: 100%;
              height: 270px;
              cursor: pointer;
            }

          }
        }
      }
    }
  }
  .no-properties {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 55vh;
    h1 {
      font-size: 1.1rem;
      margin-bottom: 1%;
    }
    button {
      background-color: #ED6B86;
      border-radius:10px;
      border: 1px solid #FDF7F0;
      padding: 5px;
      font-weight: 600;
      font-size: 0.9rem;
      width: 125px;
      color: #FDF7F0;
      cursor: pointer;
    }
  }
}


@media screen and (max-width: 1300px) {
  .property-table-view {
    overflow: auto;
  }

  .top-property-table {
    display: flex !important;
    flex-direction: column !important;
  }

  .table-detail {
    overflow-y: visible !important;
  }

  .table-headers,
  .table-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;

    .column {
      margin-top: 0.75%;
      margin-bottom: 0.75%;
      padding-right: 5px;
    }

    #column1 {
      min-width: 3%;
      text-align: center;
    }

    #column2 {
      min-width: 30%;
    }

    #column3 {
      min-width: 10%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    #column4 {
      min-width: 20%;
    }

    #column5 {
      min-width: 20%;
    }

    #column6 {
      min-width: 10%;
    }

    #column7 {
      min-width: 10%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    #column8 {
      min-width: 30%;
    }

    #column9 {
      min-width: 6%;
      text-align: left;
    }

    #column10 {
      min-width: 10%;
      text-align: center;
      color: #ED6B86;
    }


  }

  .property-divider {
    width: 145% !important;
  }
}





@media screen and (max-width: 900px) {

  .top-properties {
    height: auto !important;
  }

  .top-properties-filters {
    .filter-block.mobile {
      display: flex !important;
    }

    .filter-block {
      justify-content: center !important;

      button {
        background-color: #ED6B86 !important;
        color: #FDF7F0 !important;
      }
    }
  }

  .filter-block.desktop {
    display: none;
  }

  .top-property-results {
    margin-right: 1%;

    .top-property-title {
      margin-left: 5% !important;
      margin-right: 5% !important;
      margin-top: 2% !important;

      .view-selectors {
        button {
          display: none;
        }
      }
    }
  }

  .property-table-view {

    .table-detail {
      
    }

    overflow: auto;
    width: 100%;

    .top-property-table {
      margin-top: 2%;

      .property-divider {
        width: 320% !important;
      }

      .column {
        margin-top: 0.75%;
        margin-bottom: 0.75%;
        padding-right: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: rgba(0, 0, 0, 0.5);
      }

      #column1 {
        min-width: 7% !important;
      }

      #column2 {
        min-width: 70% !important;
      }

      #column3 {
        min-width: 25% !important;
      }

      #column4 {
        min-width: 35% !important;
      }

      #column5 {
        min-width: 30% !important;
      }

      #column6 {
        min-width: 30% !important;
      }

      #column7 {
        min-width: 30% !important;
      }

      #column8 {
        min-width: 60% !important;
      }

      #column9 {
        min-width: 20% !important;
        text-align: left;
      }

      #column10 {
        min-width: 25% !important;
        text-align: center;

        button {
          background-color: #ED6B86;
          border-radius: 5px;
          border: 1px solid #FDF7F0;
          font-weight: 600;
          font-size: 0.7rem;
          color: #FDF7F0;
          cursor: pointer;
        }
      }

      .property-divider {
        visibility: visible;
        border: 0.5px solid rgba(26, 39, 108, 0.15);
        // margin-left: 2%;
        margin-right: 2%;
        cursor: auto;
        margin-top: 1%;
        margin-bottom: 1%;
      }

      .table-detail {
        overflow: visible !important;

      }
    }
  }


  .property-map-view {
    .map-block {
      flex-direction: column-reverse !important;

      .grid-list {
        width: 100% !important;
        height: 50vh !important;
        margin-top: 5%;

        .dividing-line {
          margin: 4% !important;
        }

        .property-content {}
      }

      .map-section {
        width: 100% !important;
        margin-top: 1% !important;
        margin-right: 0% !important;
      }
    }
  }

}


@media screen and (max-width: 700px) {
  .top-properties {
    .property-grid-view {
      max-height: unset !important;
      height: auto !important;
      .property-grid-items{
        .property-item {
          flex-direction: column !important;
          .property-item-left{
            width: 100% !important;
          }
          .property-item-right {
            width: 100% !important;
            border-left: 1px solid rgba(0, 0, 0, 0.07);
          }
        }
      } 
    }
  }


}