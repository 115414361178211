* {
  box-sizing: border-box;
  margin: 0;
  // font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  
}


@import './components/home.scss';
@import './components/agents.scss';
@import './components/pricing.scss';
@import './components/search/wittleSearchHome.scss';
@import './components/tools/navbar.scss';
@import './components/tools/navBarRevised.scss';

@import './components/tools/accessDenied.scss';
@import './components/tools/autocompleteSearch.scss';
@import './components/tools/footer.scss';
@import './components/auth/login.scss';
@import './components/auth/register.scss';
@import './components/auth/profile.scss';
@import './components/auth/profileHomepage.scss';
@import './components/wittlesearch/propertydetailsearch.scss';
@import './components//wittlesearch/fieldselection.scss';
@import './components//wittlesearch/form-pages.scss';
@import './components//wittlesearch/travel.scss';
@import './components//modals/timeline1.scss';
@import './components//modals/timeline2.scss';
@import './components//modals/timeline3.scss';
@import './components//modals/timeline4.scss';
@import './components//modals/timeline5.scss';
@import './components//modals/submit.scss';
@import './components//modals/map.scss';
@import './components//modals/propertyInsights.scss';
@import './components//modals/edit.scss';
@import './components//modals/registration.scss';
@import './components//modals/accessModal.scss';
@import './components//modals/searchResults.scss';
@import './components//modals/heatmap.scss';
@import './components//modals/favouriting.scss';
@import './components//modals/NormalPropertySearchModal.scss';
@import './components//modals/livingFull.scss';
@import './components//modals/livingSignup.scss';
@import './components//modals/editAdminInputs.scss';
@import './components//modals/menuModal.scss';
@import './components//modals/waitlistSignup.scss';
@import './components//modals/agentsignup.scss';
@import './components/modals/agentSubmitted.scss';
@import './components/modals/savedPropertiesModal.scss';
@import './components/modals/KYCInput.scss';
@import './components/modals/addClientModal.scss';


@import './components//living/livingHome.scss';

@import './components/propertyResults/wittleAllProperties.scss';
@import './components/propertyResults/wittleSingleProperty.scss';
@import './components/propertyResults/NormalAllProperties.scss';
@import './components/propertyResults/propertyComponents/propertyDetails.scss';


@import './components/whiteLabel/whiteHome.scss';
@import './components/whiteLabel/landing.scss';
@import './components/whiteLabel/comparison.scss';
@import './components/whiteLabel/propertySummary.scss';
@import './components/whiteLabel/propertyDetails.scss';
@import './components/whiteLabel/singleVariables.scss';
@import './components/whiteLabel/sidebar.scss';
@import './components/whiteLabel/propertyFinder.scss';
@import './components/whiteLabel/leadGenerator.scss';
@import './components/whiteLabel/createTemplate.scss';
@import './components/whiteLabel/createCampaign.scss';
@import './components/whiteLabel/basicTemplate.scss';
@import './components/whiteLabel/letterSection.scss';
@import './components/whiteLabel/listingGenerator.scss';
@import './components/whiteLabel/favourites.scss';
@import './components/whiteLabel/account.scss';
@import './components/whiteLabel/insightsResults.scss';
@import './components/whiteLabel/howToGuide.scss';
@import './components/whiteLabel/topProperties.scss';
@import './components/whiteLabel/propertySearch.scss';
@import './components/whiteLabel/agentSavedProperties.scss';
@import './components/whiteLabel/clientSearchPage.scss';
@import './components/whiteLabel/agentSearchHome.scss';
@import './components/whiteLabel/scrollbar.scss';
@import './components/whiteLabel/landRegTest.scss';
@import './components/tools/buttons.scss';

@import './components/print/print.scss';

@import './components/blogs/blogStyling.scss';
@import './components/blogs/boroughGuideStyles.scss';






