.centered-nav-bar {
  display: none;
}




@media screen and (max-width: 900px) {
  .centered-nav-bar {
    display: block;

    .top-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      .menu-container {
        position: absolute;
        right: 0%;
        top: 4.75%
      }
      .logo {
        text-align: center;
        width: 120px;
        height: 50px;
        margin-top: 7%;

        .wittle-logo {
          width: 90% !important;
          height: 90% !important;
          background-image: url('../../../../public/white_icons/login-logo.svg');
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          cursor: pointer;
        }
        h2 {
          margin-bottom: 0%;
          font-size: 1.5rem;
        }
        h3 {
          font-size: 0.9rem;
          margin-top: -10%;
          font-style: italic;
        }
      }
      .menu-trigger {
        display: block;
        width: 40px;
        background-color: transparent;
        cursor: pointer;
      
      
        span {
          margin: 0%;
          font-size: 0px;
      
        }
      
        .burger-icon {
          display: flex;
          flex-direction: column;
      
          .burger-icon-line {
            margin-top: 2.5px;
            margin-bottom: 2.5px;
            border: 1.5px solid #FFA7E5;
            border-radius: 3px;
            width: 80%;
            margin-left: 10%;
            visibility: visible;
          }
        }
      }
    }

  }
  .nav-slider {
    display: flex;
    overflow-x: scroll;
    margin-top: 6.5%;
    h4 {
      margin-right: 6%;
      margin-left: 3%;
      font-size: 0.8rem;
      margin-bottom: 0.8%;
      white-space: nowrap;
    }
    
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
}
