.fade.search-details.modal.show {
  margin-left: 5%;
  // margin-top: 0%;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  border-radius: 10px !important;
  padding: 5%;
  
  .input-sections {
    margin-bottom: 5%;
  }
  h3 {
    margin-bottom: 4%;
    color: #FFA7E5;
    font-size: 1.4rem;
  }
  h5 {
    color: #051885;
    font-size: 1.1rem;
    margin-bottom: 2%;
  }
  p {
    font-size: 1rem;
    margin-bottom: 2%;
  }

  .bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .edit-button {
      width: 100px;
      height: 30px;
      background-color: #FFA7E5;
      color: white;
      font-weight: bold;
      border-radius: 10px;
      border: 2px solid #FFA7E5;
      cursor: pointer;
    }
    .close-button {
      width: 100px;
      height: 30px;
      background-color: black;
      color: white;
      font-weight: bold;
      border-radius: 10px;
      border: 2px solid black;
      cursor: pointer;
    }
  }

}