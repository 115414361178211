@media print {
  .no-print {
    display: none;
  }
  .remove-margin {
    margin-left: 0% !important;
    margin-top: 0% !important;
  }
  .property-image {
    display: block;
  }
  .print-container {
    // padding: 10mm;
  }

  .remove-some-margin {
    margin-left: 2% !important;
    width: 95% !important;
    justify-content: center;

  }
}
