// Primary Schools
.primary-details-section {
  margin-left:3%;
  margin-top: 1%;
  margin-right: 3%;
  padding-bottom: 2%;
  color: #1A276C;
  font-size: 0.9rem;
  .go-back-button {
    display: none;
  }



  .school-block {
    margin-top: 1%;
    .column {
      margin-top: 0.75%;
      margin-bottom: 0.75%;
      padding-right: 5px;
      a {
        color: black;
        font-size: 0.65rem;
        text-decoration: none;
      }
    }
    #column1 {
      min-width: 3%;
    }
    #column2 {
      width: 25%;
    }
    #column3 {
      width: 13%;
    }
    #column4 {
      width: 15%;
    }
    #column5 {
      width: 11%;
    }
    #column6 {
      width: 11%;
    }
    #column7 {
      width: 11%;
    }
    #column8 {
      width: 13%;
    }
    #column9 {
      width: 8%;
    }
    .tube-line {
      width: 40% !important;
    }
    .gym-name {
      width: 30% !important;
    }
    .gym-group {
      width: 15% !important;
    }
    .gym-final {
      width: 35% !important;
    }
    .supermarket {
      width: 22% !important;
    }
    .pub-group {
      width: 30% !important;
    }

    .school-table-headers {
      display: flex;
      align-items: center;
      margin-bottom: 0.5%;
      // padding-right: 5%;
      h5 {
        font-size: 0.7rem;
        color: rgba(26, 39, 108, 0.5);;
        padding-right: 5px;
      }

      .sort-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .sort-button {
          padding-right: 5%;
          font-size: 0.7rem;
        }
      }
    }
    .school-table-details {

      .school-content {
        display: flex;
        align-items: center;
        cursor: pointer;
        h5 {
          font-weight: 500;
          font-size: 0.6rem;
          color: black;
        }
      }
      .dividing-line {
        visibility: visible;
        border: 0.5px solid rgba(26, 39, 108, 0.15);

      }
      .dividing-line:last-of-type {
        visibility: hidden;
      }
    }


    .map-grid-view {
      display: flex;
      flex-direction: row;
      height: 450px;

      .grid-list {
        width: 35%;
        overflow-y: scroll;
        padding-right: 2%;

        .school-content {
          display: flex;
          flex-direction: row;
          align-items: center;

          .grid-right {
            width: 100%;
            .title {
              font-size: 0.7rem;
              font-weight: 600;
              cursor: pointer;
              color: #1A276C;
              padding-left: 0%;
              margin-bottom: 2%;
            }
            h5 {
              font-size: 0.65rem;
              font-weight: 500;
              color: black;
              width: 100%;
              text-wrap: nowrap;
            }
          }
          .details {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 5%;
            width: 100%;
            margin-top: 1%;
            margin-bottom: 1%;
            .icon {
              width: 20px;
              height: 17px;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              margin-right: 10px ;
            }
          }
        }

        .dividing-line {
          border: 0.5px solid rgba(5, 24, 133, 0.1);
          visibility: visible;
          margin-top:2%;
          margin-bottom: 2%;
        }
      
      }
      .map-section {
        width: 65%;
        height: 450px !important;
        margin-right: 0% !important;


        .mapboxgl-map {
          height: 100% !important;
          border: 0px !important;
          border-radius: 30px;

          .poi-background {
            background-color: #1A276C;
            // padding: 5px;
            width: 30px;
            height: 30px;
            border-radius: 15px;
            z-index: 1;
            cursor: pointer;
            font-size: 0.75rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FDF7F0;
            // color: #1A276C;
            font-weight: bold;
          }
            .property-icon {
              font-size: 2rem; 
            }


          .catchment-area {
            z-index: 99;
            fill-color: #FFA7E5; /* Adjust the fill color as desired */
            fill-opacity: 0.1; /* Adjust the fill opacity as desired */
            stroke-width: 2px; /* Adjust the stroke width as desired */
            stroke-color: #FFA7E5; /* Adjust the stroke color as desired */
            stroke-opacity: 0.8; /* Adjust the stroke opacity as desired */
          }

          .popup-content {
            .popup-border {
              text-align: center;
              p {
                font-size: 0.6rem;
              }
            }
          }
        }
      }


    }

    
  }
  .pagination {
    margin-top: 2%;
    // width: 20%;
    // margin-bottom: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    margin-left: 3%;
    margin-right: 5%;
    padding: 0%;
    font-size: 0.7rem;
    .next, .previous, li {
      background-color: #FDF7F0;
      width: 30px;
      height: 30px;
      margin-top: 0%;
      padding: 0%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ED6B86;
      margin-left: 0.25%;
      margin-right: 0.25%;
      border-radius: 10px;

    }
    .next, .previous {
      border-radius: 10px;
      border: 2px solid white;
      background-color: white;
      color: #ED6B86;
      font-size: 1rem;
    }
    li {
      font-size: 0.7rem;
      cursor: pointer;
    }
    .active {
      border-radius: 10px;
      border: 2px solid #ED6B86;
      background-color: #ED6B86;
      color: #FDF7F0;
      font-weight: bold;

    }
  }

}
.pagination {
  margin-top: 2%;
  // width: 20%;
  // margin-bottom: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  margin-left: 3%;
  margin-right: 5%;
  padding: 0%;
  font-size: 0.7rem;
  .next, .previous, li {
    background-color: #FDF7F0;
    width: 30px;
    height: 30px;
    margin-top: 0%;
    padding: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ED6B86;
    margin-left: 0.25%;
    margin-right: 0.25%;
    border-radius: 10px;

  }
  .next, .previous {
    border-radius: 10px;
    border: 2px solid white;
    background-color: white;
    color: #ED6B86;
    font-size: 1rem;
  }
  li {
    font-size: 0.7rem;
    cursor: pointer;
  }
  .active {
    border-radius: 10px;
    border: 2px solid #ED6B86;
    background-color: #ED6B86;
    color: #FDF7F0;
    font-weight: bold;

  }
}



@media screen and (max-width: 1200px) {
  .primary-details-section {


    .school-block {
      overflow-x: scroll;
      margin-right: 5% ;
      #column1 {
        min-width: 6%;
      }
      #column2 {
        min-width: 40%;
      }
      #column3 {
        min-width: 20%;
      }
      #column4 {
        min-width: 20%;
      }
      #column5 {
        min-width: 20%;
      }
      #column6 {
        min-width: 20%
      }
      #column7 {
        min-width: 20%
      }
      #column8 {
        min-width: 20%
      }
      #column9 {
        min-width: 20%
      }
      .gym-name {
        width: 30% !important;
      }
      .gym-group {
        width: 15% !important;
      }
      .gym-final {
        width: 35% !important;
      }
      .supermarket {
        width: 22% !important;
      }
      .pub-group {
        min-width: 30%;
      }

      .school-table-details {
        .dividing-line {
          margin-top: 1% !important;
          margin-bottom: 1% !important;
          min-width: 100% !important;
          width: 186% !important;
        }
      }
    }
  }
}


@media screen and (max-width: 800px) {

  .desktop-nav {
    display: none !important;
  }
  .mobile-nav {
    display: block !important;
  }
  .primary-details-section {
    .go-back-button {
      display: block;
      color: #FFA7E5;
      margin-top: 3% !important;
      margin-bottom: 3% !important;
    }
  }


  .detail-slider {
    margin-left: 5% !important;
    margin-top: 2% !important;
    margin-bottom: 2% !important;
    overflow-x: scroll !important;
    h5 {
      white-space: nowrap;

    }
  }

  .primary-details-section {
    margin-left: 0% !important;
    margin-top: 1% !important;

    .title-buttons {
      margin-left: 5% !important;
    }

    .search-section {
      margin-left: 5% !important;
      margin-top: 1%;
      margin-bottom: 1%;
    }

    .school-block {
      margin-left: 5% !important;
    }

    .primary-title {
      font-size: 0.8rem !important;
    }

    .school-block {
      overflow-x: scroll;
      margin-right: 5% ;
      #column1 {
        min-width: 10%;
      }
      #column2 {
        min-width: 75%;
      }
      #column3 {
        min-width: 30%;
      }
      #column4 {
        min-width: 25%;
      }
      #column5 {
        min-width: 30%;
      }
      #column6 {
        min-width: 30%;
      }
      #column7 {
        min-width: 30%;
      }
      #column8 {
        min-width: 30%;
      }
      #column9 {
        min-width: 30%;
      }
      .gym-name {
        width: 30% !important;
      }
      .gym-group {
        width: 15% !important;
      }
      .gym-final {
        min-width: 70% !important;
      }
      .supermarket {
        width: 22% !important;
      }
      .tube-line {
        min-width: 80% !important;
        padding-right: 10px;
      }
      .school-table-headers {

        h5 {
          font-size: 0.7rem;
        }
      }
      .school-table-details {
        .dividing-line {
          margin-top: 1% !important;
          margin-bottom: 1% !important;
          min-width: 100% !important;
          width: 280% !important;
        }
      }
    }
  }


  .map-grid-view {
    flex-direction: column-reverse !important;
    height: auto !important;
    .grid-list {
      width: 100% !important;
      margin-top: 3%;
      height: 200px;
    }
    .map-section {
      width: 100% !important;
      height: 550px !important;
      .mapboxgl-map {
        width: 100% !important;
      }
    }




  }




}




@media screen and (max-width: 700px) {



}


@media screen and (max-width: 600px) {

}