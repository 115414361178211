.living-inputs-form {
  display: flex;
  flex-direction: row;
  width: 100%;

  .living-modal-details {
    width: 50%;
    margin-top: 0%;
    color: #FFA7E5;

    .modal-title {
      display: flex;
      justify-content: space-between;
      .title-message {
        padding-right: 5%;
      }
      .close {
        color: #051885;
      }
    }

    h3 {
      margin-bottom: 1%;
    }

    h5 {
      color: #051885;
      margin-bottom: 2%;
    }

    .logic-buttons {
      display: flex;
      align-items: center;
      margin-bottom: 1%;
      margin-top: 3%;

      h5 {
        width: 100px;
        color: #FFA7E5;
        margin-bottom: 0%;
        margin-right: 10%;
        font-size: 1.1rem;
      }





    }

    .logic-sub-title {
      font-style: italic;
      margin-top: 1%;
    }

    .selection-logic {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 2%;

      // margin-bottom: 2% !important;
      h5 {
        margin-bottom: 0%;
      }

      button {
        width: 85px;
        border-radius: 5px;
        font-size: 0.8rem;
        padding: 5px;
        border: 1px solid black;
      }

      .delete-button {
        background-color: black;
        color: #FDF7F0;
        cursor: pointer;

      }

      .add-button {
        background-color: #FDF7F0;
        color: black;
        cursor: pointer;

      }
    }

    .selection-block {
      display: flex;
      align-items: center;
      margin-bottom: 0.5%;

      .additional-logic {
        font-weight: 400;
        margin-bottom: 0%;
        margin-right: 10px;
      }

      select {
        width: 35px;
        border-radius: 5px;
        border: 1px solid #051885;
      }

      .selection-detail {
        margin-right: 10px;

        .provider-dropdown {
          width: 100px;
          padding: 3px;
          margin-top: 0%;
          color: #051885;
          font-size: 0.7rem;
        }

        .detail-title {
          font-style: italic;
        }

        input {
          border-radius: 5px;
          border: 1px solid #051885;
          font-size: 0.7rem;
          padding: 3px;
          color: #051885;
        }
        .selection-input {
          width: 150px;

        }
        .selection-input-text {
          width: 470px;
        }
      }
    }

    #lifestyle-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0%;
      margin-top: 2%;

      h5 {
        margin-right: 10px;
        margin-bottom: 0%;
      }
    }

    .missing-title {
      font-style: italic;
    }

    .selection-block-other {
      display: flex;
      justify-content: space-between;

      .selection-detail {
        margin-top: 1%;

        .detail-title {
          font-style: italic;
        }

        .selection-input {
          width: 125px;
          border-radius: 5px;
          border: 1px solid #051885;
          font-size: 0.7rem;
          padding: 3px;
          color: #051885;
        }
      }
    }
  }

  .living-admin-image {
    width: 50%;
    display: flex;
    justify-content: center;
    margin-top: 5%;

    // align-items: center;
    .admin-image {
      background-image: url('../../../../public/website_images/wittle-living-admin-figma.png');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      // background-attachment: fixed;
      height: 600px;
      width: 295px;
    }
  }

  .living-divider {
    margin-top: 3%;
    margin-bottom: 3%;
    margin-left: 0%;
    margin-right: 0%;
  }

  .living-modal-footer {
    .footer-submit {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .submit-text {
        color: #051885;
      }

      button {
        width: 85px;
        border-radius: 5px;
        font-size: 0.8rem;
        padding: 5px;
        border: 1px solid #FFA7E5;
        background-color: #FFA7E5;
        color: #FDF7F0;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .footer-details {
      margin-bottom: 2%;

      .submit-sub-text {
        margin-top: 1%;
        color: #051885;
        font-style: italic;
      }
    }
  }
}



@media screen and (max-width: 800px) {

  .living-inputs-form {
    display: flex;
    flex-direction: column;
    width: 100%;



    .living-modal-details {
      width: 100%;




      .selection-logic {
        margin-bottom: 4% !important;
      }

      .logic-sub-title {
        margin-bottom: 3%;
      }

      .selection-block {
        flex-direction: column;
        align-items: baseline;
        margin-left: 5%;

        .selection-detail {
          display: flex;
          margin-bottom: 1%;
          align-items: center;

          .detail-title {
            width: 150px;
          }

          .selection-input {
            width: 125px;
          }
          .selection-input-text {
            width: 275px;
          }

          .provider-dropdown {
            width: 125px;
            padding: 1px;
            margin-top: 0%;
          }
        }
      }

      .selection-block-other {
        flex-direction: column;
        align-items: baseline;
        margin-left: 5%;

        .selection-detail {
          display: flex;
          margin-bottom: 0%;
          // margin-top: 0%;

          .detail-title {
            width: 150px;
          }

          .selection-input {
            width: 125px;
          }
        }
      }

      .selection-logic-2 {
        margin-left: 5%;
        display: flex;
        align-items: center;
        margin-bottom: 2%;

        .additional-logic {
          margin-bottom: 0%;
          margin-right: 20px;
        }
      }

    }

    .living-modal-footer {
      .footer-submit {
        .submit-text {
          padding-right: 10px;
        }

        .submit-button {
          width: 100px !important;
        }
      }
    }
  }
  .living-admin-image {
    width: 100% !important;
    margin-top: 10% !important;
  }
}