.profile-sidebar-open {
  width: 250px;
  display: block;
  background-color: #FDF7F0;
  position: fixed;
  top: 0%;
  height: 100vh;
  .logo-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .logo {
      background-image: url('../../../../public/white_icons/W-logo.svg');
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 110px;
      height: 105px;
      margin-top: 20%;
      cursor: pointer;
    }
  }

  .profile-buttons {
    margin-top: 20%;
    color: #1A276C;
    .profile-button-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5% 0% 5% 12%;
      cursor: pointer;

      .icon {
        width: 30px;
        height: 30px; 
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 7%;
      }
      
      h2 {
        font-size: 0.9rem;
        font-weight: 600;
      }
    }


    .profile-button-title:hover, .profile-button-title.active  {
      background-color: #1A276C;
      border-right: 6px solid #ED6B86;
      color: #FDF7F0;

      #home-icon {
        background-image: url('../../../../public/white_icons/house-beige.png');
      }
      #saved-icon {
        background-image: url('../../../../public/white_icons/White/mortgage.svg');
      }
      #listing-icon {
        background-image: url('../../../../public/white_icons/White/list-cog.svg');
      }
      #ai-icon {
        background-image: url('../../../../public/white_icons/ai-beige.png');
      }
      #finder-icon {
        background-image: url('../../../../public/white_icons/White/house-search.svg');
      }
      #account-icon {
        background-image: url('../../../../public/white_icons/White/account.svg');
      }
      #help-icon {
        background-image: url('../../../../public/white_icons/help-beige.png');
      }
      #search-icon {
        background-image: url('../../../../public/white_icons/property-insight-beige.png');
      }
    }
  }



  #home-icon {
    background-image: url('../../../../public/white_icons/house-blue.png');
  }
  #saved-icon {
    background-image: url('../../../../public/white_icons/Blue/mortgage.svg');
  }
  #listing-icon {
    background-image: url('../../../../public/white_icons/Blue/list-cog.svg');
  }
  #ai-icon {
    background-image: url('../../../../public/white_icons/ai-blue.png');
  }
  #finder-icon {
    background-image: url('../../../../public/white_icons/Blue/house-search.svg');
  }
  #account-icon {
    background-image: url('../../../../public/white_icons/Blue/account.svg');
  }
  #help-icon {
    background-image: url('../../../../public/white_icons/help-blue.png');
  }
  #search-icon {
    background-image: url('../../../../public/white_icons/property-insight-blue.png');
  }

  .progress-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 30px;
    width: 100%;
  }

  .progress-container {
    width: 80%;
    background-color: #ED6B86;
    border-radius: 10px;
    margin: 5px 0;
  }
  
  .progress-bar {
    background-color: #1A276C; /* Adjust color as needed */
    height: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    // padding: 0 10px;
    color: white; /* Adjust text color as needed */
    width: 0; /* Initial width */
  }

  .leads-remaining  {
    font-size: 0.6rem;
    color: #1A276C;
    font-weight: 700;
  }


}





@media screen and (max-width: 900px) {
  .profile-sidebar-open {
    display: none;
  }

}