.lead-generator {
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 2%;


  .property-results {
    margin-top: 2%;
    box-shadow: 1px 2px 1px 0 rgba(0, 0, 0, 0.1), -1px 2px 1px 0 rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin-bottom: 2%;
    padding-bottom: 2%;
    height: 83vh;

    .saved-message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 0.6rem;
        margin-right: 5px;
        font-weight: 500;
        color: #ED6B86;
        font-style: italic;
      }
    }

    .custom-checkbox {
      position: relative;
      font-size: 0.7rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .checkbox {
        position: absolute;
        opacity: 0;
        width: 12px;
        height: 12px;
        cursor: pointer;
        z-index: 2;
      }

      .label {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #1A276C;
        font-weight: 500;


        &::before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          border: 2px solid rgba(26, 39, 108, 0.15);
          border-radius: 3px;
          background-color: #FFF;
          transition: background-color 0.3s;
        }
      }
    }

    .checkbox:checked~.label::before {
      background-color: #ED6B86;
    }

    .results-content.highlighted-row {
      background-color: rgba(237, 107, 134, 0.2);
      width: auto;
    }

    .results-block {
      display: flex;
      flex-direction: column;

      .analytics-overview {
        display: flex;
        flex-direction: row;
        width: 94%;
        margin-left: 3%;
        margin-right: 3%;
        margin-top: 1.5%;
        margin-bottom: 1%;

        .analytics-box {
          width: 20%;
          max-width: 200px;
          margin-right: 2%;
          border: 2px solid rgba(26, 39, 108, 0.15);
          border-radius: 20px;
          text-align: center;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .box-title {
            font-size: 1rem;
            margin-bottom: 2%;
          }

          .box-sub-title {
            font-size: 0.9rem;
            font-weight: 500;
            margin-top: 2%;
          }
        }
      }

      .action-section.letter {
        margin-top: 1%;
        margin-right: 2%;
        margin-left: 3%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .template-total {
          font-size: 0.8rem;
        }

        .save-section {
          margin-right: 0% !important;
          width: 125px;
        }

        .template-select {
          padding: 8px;
          border-radius: 10px;
          // width: 150px;
          font-size: 0.75rem;
          color: #1A276C;
          font-weight: 600;
          // margin-right: 1px;
          border: 2px solid rgba(26, 39, 108, 0.1);
        }

        .template-select.letter {
          width: 150px;
          margin-right: 3%;
        }

        .credit-title {
          font-size: 0.8rem;
          font-weight: 500;
          margin-right: 2%;

          span {
            font-weight: 700;
          }
        }

        .campaign-name,
        .status-title {
          font-size: 0.8rem;
          margin-top: 0.5%;
        }

        .status-detail {
          font-size: 0.8rem;
          color: #ED6B86;
          margin-top: 0.5%;
        }
      }

      .sub-title {
        margin-top: 3%;
        margin-bottom: 1%;
        margin-left: 2%;
        font-size: 0.7rem;
        color: black;
        font-weight: 600;
      }

      .results-content {
        h5 {
          font-size: 0.6rem;
          color: black;
          font-weight: 500;
        }

        .expander {
          cursor: pointer;
        }
      }

      .results-headers,
      .results-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 2%;
        padding-right: 2%;

        .column {
          margin-top: 1%;
          margin-bottom: 1%;
          padding-right: 5px;
        }

        #column1 {
          text-align: center;
          flex: 1 0 25px
        }

        #column2 {
          flex: 1 0 210px
        }

        #column3 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          // cursor: pointer;
          flex: 1 0 80px
        }

        #column4 {
          flex: 1 0 80px
        }

        #column5 {
          flex: 1 0 80px
        }

        #column6 {
          flex: 1 0 90px
        }

        #column7 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          flex: 1 0 85px
        }

        #column8 {
          flex: 1 0 70px
        }

        #column9 {
          text-align: left;
          text-wrap: wrap;
          flex: 1 0 70px
        }


        #column9saved {
          min-width: 230px;
          display: flex;
          flex-direction: row;
          color: #ED6B86;
          flex: 1 0 230px;
          // background-color: white;
          margin-top: 0%;
          margin-bottom: 0%;
        }

        #column10 {
          min-width: 230px;
          display: flex;
          flex-direction: row;
          color: #ED6B86;
          flex: 1 0 230px;
          margin-top: 0%;
          margin-bottom: 0%;
        }

        #column10saved {
          flex: 1 0 70px
        }

        #column11 {
          flex: 1 0 25px
        }
      }

      .expansion {
        color: black;
        font-weight: 500;
        font-size: 0.7rem;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }



      .add-property {
        cursor: pointer;
        background-color: #FDF7F0;
        border: 1px solid #ED6B86;
        color: #ED6B86;
        border-radius: 5px;
        font-weight: 700;
      }

      .add-property:hover {
        background-color: #ED6B86;
        color: #FDF7F0;
      }

      .results-headers {
        h5 {
          font-size: 0.7rem;
          color: rgba(26, 39, 108, 0.5);
        }
      }

      .select-all-box {
        width: 50px;
      }

      .select-all-box.saved {
        width: 50px;
      }

      .select-all-box.hidden {
        width: 50px;
      }

      .select-all-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 50px;
        margin-right: 0%;
        width: 50px;
        position: sticky;
        position: -webkit-sticky;
        right: 0;
        background: white;
        z-index: 100;
        padding-right: 10px !important;


        h5 {
          font-size: 0.7rem;
          padding-right: 5px;
          color: rgba(26, 39, 108, 0.5);
          font-weight: 700;
          margin-bottom: 4%;
        }
      }

      .saved-select-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 1%;
      }

      .filter-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 2%;
        margin-top: 1%;
        margin-bottom: 1%;

        h3 {
          font-size: 0.7rem;
          width: 125px;
          color: #1A276C;
          font-weight: 600;
        }

        .dropdown {
          width: 220px;
          padding: 5px;
          border-radius: 7.5px;
          border: 1px solid #1A276C;
          font-size: 0.7rem;
        }
      }

      .matching-status {
        display: flex;
        flex-direction: row;
        margin-left: 2%;

        h3 {
          text-wrap: nowrap;
          border-radius: 15px;
          background-color: rgba(26, 39, 108, 0.15);
          padding: 5px 15px 5px 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 10px;
          font-size: 0.7rem;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .action-section {
        display: flex;
        flex-direction: row;
        margin-right: 5%;
      }

      .action-section.hidden {
        margin-right: 1%;
      }

      .save-section {
        border: 2px solid rgba(26, 39, 108, 0.1);
        border-radius: 12px;
        padding: 10px;
        cursor: pointer;
        margin-right: 3%;

        h3 {
          color: #ED6B86;
          font-weight: 600;
          font-size: 0.75rem;
          text-align: center;
          text-wrap: nowrap;
        }

      }

      .title-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-right: 2%;

        .sub-title {
          margin-top: 1%;
          color: #1A276C;
        }

        .tracking-actions {
          display: flex;
          flex-direction: row;
          width: 200px;

          .filter-icon {
            background-position: center;
            background-color: #FDF7F0;
            width: 38px;
            height: 38px;
            background-size: 70% 70%;
            background-repeat: no-repeat;
            background-image: url('../../../../public/white_icons/filter-blue.png');
            z-index: 999999;
            border-radius: 10px;
            border: 1px solid #1A276C;
            margin-left: 5%;
          }

          .filter-icon.active {
            background-image: url('../../../../public/white_icons/filter-white.png');

          }
        }
      }

      .search-section {
        margin-left: 2%;
        .archive-search {
          font-size: 0.7rem;
          padding: 2px 5px 2px 5px;
          border-radius: 5px;
          border: 1px solid #1A276C;
          width: 200px;
          color: #1A276C;
        }
      }

      .filter-row-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 2%;
        margin-right: 2%;
        padding-left: 1%;
        padding-right: 0.5%;
        overflow: auto;

        .filter-block {
          display: flex;
          flex-direction: row;
          align-items: center;
          // border-right: 0.5px solid #1A276C;
          padding-right: 5px;
          margin-right: 5px;

          .filter-title {
            width: 70px;
            color: #1A276C;
            font-size: 0.7rem;
          }

          select,
          .filter-input {
            width: 90px;
            font-size: 0.7rem;
            border-radius: 5px;
            border: 1px solid #1A276C;
          }

          .small {
            width: 50px;
            font-size: 0.7rem;
            border-radius: 5px;
            border: 1px solid #1A276C;
            margin-right: 5px;
          }
        }

        .clear-filters {
          background-color: #ED6B86;
          color: #FDF7F0;
          border: 1px solid #ED6B86;
          border-radius: 5px;
          font-weight: 700;
          font-size: 0.7rem;
          cursor: pointer;
        }
      }

      .analytics-filter-wrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .filter-block {
          .filter-title {
            width: 100px;
          }
        }
  
      }

      .filter-row-section.active {
        border-top:1px solid rgba(26, 39, 108, 0.25);
        border-bottom:1px solid rgba(26, 39, 108, 0.25);
        margin-top: 0.5%;
        margin-bottom: 0.5%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
      }

      .property-divider {
        visibility: visible;
        border: 0.5px solid rgba(26, 39, 108, 0.15);
        margin-left: 2%;
        margin-right: 2%;
        cursor: auto;
      }

      .results-details.explore {
        max-height: 54vh;

        .property-divider:last-of-type {
          visibility: hidden;
        }
      }

      .results-details.archive {
        max-height: 66vh;
      }

      .results-details.archive.active {
        max-height: 61vh;
      }

      .results-table {
        overflow-x: auto;
      }



      .results-headers {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1000;
        width: 100%;

        .column {
          margin-top: 0%;
          margin-bottom: 0%;
          background-color: white;
          padding-top: 1%;
          padding-bottom: 1%;
        }

      }




      .results-details {
        z-index: -1;

        .matching-title {
          margin-left: 5%;
          margin-top: 1%;
          color: #ED6B86;
          font-weight: 700;
          font-size: 0.7rem;
        }

        .expanded-section-titles,
        .expanded-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 5%;

          .column {
            margin-top: 1%;
            margin-bottom: 1%;
            font-size: 0.60rem;
            color: #ED6B86;
          }

          #column1 {
            width: 3%;
          }

          #column2 {
            width: 40%;
          }

          #column3 {
            width: 10%;
          }

          #column4 {
            width: 10%;
          }

          #column5 {
            width: 10%;
          }

          #column6 {
            width: 10%;
          }

          #column7 {
            width: 10%;
          }

          #column8 {
            width: 7%;
          }

          .add-property {
            background-color: rgba(237, 107, 134, 0.1);
            border-radius: 5px;
            border: 1px solid #ED6B86;
            color: #ED6B86;
            font-weight: 700;
          }

          .add-property:hover {
            background-color: #ED6B86;
            color: #FDF7F0;
          }


        }

        .expanded-section-titles {
          font-weight: 700;
        }

        .heart-icon {
          width: 20px;
          height: 20px;
          background-position: center;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-image: url('../../../../public/white_icons/heart.png');
          margin-bottom: 1%;
          cursor: pointer;
        }




        .expanded-tracking-details {
          width: 90%;
          margin-left: 5%;
          margin-right: 5%;
          display: flex;
          flex-direction: column;

          .expanded-tracking-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .loading-tracking {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            }
            .tracking-left {
              display: flex;
              flex-direction: column;
              width: 40%;

              .row-title {
                width: 30%;
                font-size: 0.6rem;
                font-weight: 500;
                color: black;
              }

              .row-input.wide {
                width: 60%;
              }
            }

            .tracking-middle {
              width: 25%;

              .row-title {
                width: 60%;
                font-size: 0.6rem;
                font-weight: 500;
                color: black;
              }
            }

            .tracking-right {
              width: 30%;

              textarea {
                border: 1px solid #1A276C;
                border-radius: 5px;
                padding: 2px 5px 2px 5px;
                max-width: 100%;
                min-width: 100%;
                min-height: 87px;
                font-size: 0.65rem;
                margin-top: 4px;
              }

              .row-result {
                font-size: 0.6rem;
              }
            }

            .tracking-row {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: 1%;
              min-height: 30px;

              .row-result {
                font-size: 0.6rem;
                font-weight: 500;
              }

              .row-input {
                border: 1px solid #1A276C;
                border-radius: 5px;
                padding: 2px 5px 2px 5px;
                font-size: 0.65rem;
              }

              .row-input.narrow {
                width: 30%;
              }
            }

          }

          .expanded-tracking-actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-bottom: 1%;

            button {
              width: 70px;
              font-size: 0.65rem;
              color: #FDF7F0;
              font-weight: 600;
              border-radius: 5px;
            }

            .delete {
              margin-left: 1%;
              background-color: #1A276C;
              border: 1px solid #1A276C;
            }

            .save {
              background-color: #ED6B86;
              border: 1px solid #ED6B86;
            }
          }
        }
      }

      .select-dropdown {
        font-size: 0.7rem;

        .css-13cymwt-control {
          border-radius: 10px;
          border: 1px solid #1A276C;
          cursor: pointer;
          z-index: 999999;
        }

        .css-1nmdiq5-menu {
          z-index: 999999;
        }

        .css-tr4s17-option:hover {
          cursor: pointer;
          background-color: #1A276C;
          color: #FDF7F0;
          z-index: 999999;
        }
      }

    }

    .results-details.letter {
      max-height: 65vh;
    }

    .results-details.letter.active {
      max-height: 60vh;
    }

    .results-headers.letter,
    .results-details.letter {
      #column1 {
        text-align: center;
        flex: 1 0 20px
      }

      #column2 {
        flex: 1 0 230px;
      }

      #column3 {
        flex: 1 0 120px;
      }

      #column4 {
        flex: 1 0 100px;
      }

      #column5 {
        flex: 1 0 100px;
        // select {
        //   width: 130px;
        //   font-size: 0.7rem;
        //   border-radius: 5px;
        //   border: 1px solid #1A276C;
        //   background-color: rgba(26, 39, 108, 0.1);
        // }
      }

      #column6 {
        flex: 1 0 100px;

        button {
          font-size: 0.7rem;
          background-color: #ED6B86;
          color: #FDF7F0;
          font-weight: 700;
          border: 1px solid #ED6B86;
          border-radius: 5px;
          cursor: pointer;
        }
      }

      #column7 {
        flex: 1 0 100px;

      }

      #column11 {
        flex: 1 0 50px;

        h6 {
          font-size: 0.5rem;
          text-align: center;
          color: #ED6B86;
          font-weight: 600;
        }
      }
    }

    .property-table-loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      h3 {
        font-size: 0.8rem;
        margin-top: 1%;
      }
    }



    .results-headers.analytics,
    .results-content.analytics {
      display: flex;
      flex-direction: row;

      #column1 {
        text-align: center;
        flex: 1 0 15px;

      }

      #column2 {
        flex: 1 0 200px;
      }

      #column3 {
        flex: 1 0 80px;
        text-align: center;
        display: block;
      }

      .open-pdf-button, #column7 {
        cursor: pointer;
      }

      #column4,
      #column5,
      #column6,
      #column7
      {
      flex: 1 0 80px;
      text-align: center;
      display: block;
    }


  }

  .results-details.analytics {
    overflow: auto;
    max-height: 38vh;
  }
  .results-details.letter.campaign {
    max-height: 57vh;
  }

  .results-content.analytics {
    padding-left: 0% !important;
    padding-right: 0% !important;
    margin-left: 2%;
    margin-right: 2%;
    background-color: rgba(237, 107, 134, 0.15);
    // border: 1px solid rgba(26, 39, 108, 0.05);
    margin-bottom: 1%;
    border-radius: 20px;
  }
}


.lead-gen-inputs {
  width: 45%;
  padding-left: 2%;
  padding-right: 2%;
  max-height: 77vh;
  overflow-y: auto;

  .sub-title {
    margin-top: 3%;
    margin-bottom: 3%;
    font-size: 0.8rem;
    color: black;
    font-weight: 600;
  }

  .single-title-double {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3%;

    h3 {
      color: #1A276C;
      font-weight: 600;
      font-size: 0.8rem;
      margin-bottom: 10px
    }

    .double-dropdowns {
      display: flex;
      flex-direction: row;
      width: 100%;

      .dropdown {
        width: 50%;
        padding: 7.5px;
        border-radius: 7.5px;
        border: 1px solid #1A276C;
        font-size: 0.8rem;
      }

      .dropdown:nth-of-type(odd) {
        margin-right: 2%;
      }

      .dropdown:nth-of-type(even) {
        margin-left: 2%;
      }
    }
  }

  .double-input-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 3%;

  }

  .single-input-block {
    margin-bottom: 3%;
  }

  .input-block {
    display: flex;
    flex-direction: column;

    h3 {
      color: #1A276C;
      font-weight: 600;
      font-size: 0.8rem;
      margin-bottom: 10px
    }

    p {
      font-size: 0.65rem;
      font-weight: 500;
      font-style: italic;
      margin-bottom: 2%;
      color: black;
    }

    input,
    select,
    textarea {
      padding: 7.5px;
      border-radius: 7.5px;
      border: 1px solid #1A276C;
      font-size: 0.8rem;
    }

    select {
      width: 100%;
    }
  }

  .lead-gen-save {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .save-details {
      width: 175px;
      padding: 7.5px;
      border-radius: 7.5px;
      background-color: #ED6B86;
      border: 1px solid #ED6B86;
      color: #FDF7F0;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .see-more-section {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

}

.map-section {
  height: 600px;
  width: 100%;
  position: relative;
  z-index: 99;

  .mapboxgl-map {
    height: 100%;
  }
}

}

.popup-swal-title {
  color: #1A276C;
  font-size: 1.6rem;
  font-weight: 600;
  font-family: 'Poppins', 'sans-serif;';
}

.popup-swal-body {
  color: #1A276C;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: 'Poppins', 'sans-serif;';
}

.popup-swal-confirm {
  font-family: 'Poppins', 'sans-serif;';
  padding: 7.5px 12.5px;
  background-color: #ED6B86;
}


@media screen and (max-width: 1300px) {
  .results-table {

    .property-divider {
      width: 1050px !important;
    }
  }

}


@media screen and (max-width: 1200px) {
  .lead-gen-inputs {
    width: 70% !important;
  }

  #manual-matcher {
    display: none;
  }
}





@media screen and (max-width: 700px) {
  .lead-gen-inputs {
    width: 100% !important;
  }

}


@media screen and (max-width: 650px) {

  .matching-status {
    overflow: auto;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .property-finder {
    .property-insight-nav {
      flex-direction: column !important;

      .property-insight-buttons {
        width: 100% !important;

        .insight-button {
          width: 50% !important;
          padding: 2% 0% 2% 0% !important;
          text-align: center;
        }
      }

      .action-section {
        margin-top: 1% !important;
        margin-bottom: 1% !important;
        width: 100% !important;
        margin-right: 0% !important;
        justify-content: center;

        .save-section {
          width: 45% !important;
          margin-right: 2.5% !important;
          margin-left: 2.5% !important;
        }

      }
    }

    .results-content {
      .column {
        h5 {
          font-size: 0.6rem !important;
        }
      }
    }

    .results-details {
      max-height: 48vh !important;
    }

  }

  .results-block {
    .property-divider {
      margin-top: 0% !important;
    }
  }

}