.register-content{
  width: 340px;
  background-color: #FDF7F0;
  color: #213159;
  display: flex;
  justify-content: center;
  margin-top: -30%;
  .form-detail{
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    margin-bottom: 10%;
    width: 80%;
  }
  h1 {
    font-size: 1.1rem;
    text-align: left;
    margin-bottom: 5px;

    color: #051885;
  }
  p {
    text-align: left;
    font-size: 0.8rem;
    margin-top: 3%;
  }
  .error {
    color: #FFA7E5;
    font-weight: 700;
    margin-top: 0%;
  }

  input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 2px !important;
    border-bottom: 2px solid #213159;
    // margin-bottom: 3%;
    background-color: white;
    width: 100%;
    margin-left: 0%;
    margin-top: 0%
  }
  button {
    background-color: #FFA7E5;
    color: white;
    border: 1px solid #FFA7E5;
    padding: 5px;
    margin-top: 10px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  h5 {
    text-align: center;
    margin-top: 5%;
    color: #FDF7F0;
    span {
      color: #FFA7E5;
    }
  }
}