.normal-results-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h1 {
    margin-top: 15%;
    color: #051885;
    font-size: 2rem;
  }
  h3 {
    font-size: 1.2rem;
    margin-top: 1%;
    color: #FFA7E5;
  }
  button {
    width: 150px;
    padding: 5px;
    background-color: #FFA7E5;
    border: 2px solid #FFA7E5;
    border-radius: 10px;
    color: white;
    font-weight: 700;
    cursor: pointer;
    margin-top: 2%;
  }
}





@media screen and (max-width: 800px) { 
  .normal-results-page {
    h1 {
      margin-top: 30%;
      padding: 0 3% 0 3%;
    }
    h3 {
      margin-top: 5%;
      padding: 0 2% 0 2%;
      margin-bottom: 5%;
    }
  }


}