.fade.deleting-modal.modal.show {
  margin-left: 30%;
  margin-top: 7%;
  width: 40%;
  height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  border-radius: 10px !important;
}
.fade.favouriting-modal.modal.show {
  margin-left: 65%;
  margin-top: 0%;
  width: 35%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  border-radius: 0px !important;
}

  // .modal-dialog {
  //   .modal-content {
  //     .modal-body {
        .favourite-modal-body {
          width: 95%;

          margin: 2.5%;
          .title-message {
            color: #FFA7E5;
            font-weight: 600;
            font-size: 1.2rem;
          }
          .favourites-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2.5%;
          
            .closing-x {
              font-size: 1.2rem;
              color: #051885;
              cursor: pointer;
            }
          }
          .favourites-recent {
            .favourite-property {
              display: flex;
              .property-image {
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 130px;
                height: 110px;
                border-radius: 10px;
              }
              .property-content {
                margin-left: 10px;
                h3 {
                  font-size: 0.6rem;
                  color: #051885;
                  margin-bottom: 5px;
                  font-weight: 600;
                  text-decoration: underline transparent;
                }
              }
            }
          }
          .favourite-section-splitter {
            margin-top: 5%;
            margin-bottom: 5%;
            // border: 0.5px solid #051885;
          }

          .favourites-other {
            // margin-top: 10%;
            .title-message {
              margin-bottom: 2.5%;
            }
            .favourite-property {
              display: flex;
              // margin-bottom: 5%;
              
              .property-image {
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 130px;
                height: 110px;
                border-radius: 10px;
              }
              .property-content {
                margin-left: 10px;
                h3 {
                  font-size: 0.6rem;
                  color: #051885;
                  margin-bottom: 5px;
                  font-weight: 600;
                }
              }
            }
            .favourite-property-splitter {
              margin-top: 2.5%;
              margin-bottom: 2.5%;
            }
      
          }

          .favourites-non-auth {
            h3 {
              color: #051885;
            }

            .non-auth-buttons {
              button {
                margin-top: 4%;
                margin-right: 2%;
                width: 150px;
                padding: 5px;
                background-color: #FFA7E5;
                border: 2px solid #FFA7E5;
                border-radius: 10px;
                color: white;
                font-weight: 700;
                cursor: pointer;
              }
            }
          }

        }




@media screen and (max-width: 900px) {

.fade.favouriting-modal.modal.show {
  margin-left: 0%;
  margin-top: 0%;
  width: 100%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  border-radius: 0px !important;
}

.fade.deleting-modal.modal.show {
  margin-left: 0%;
  margin-top: 0%;
  width: 100%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  border-radius: 0px !important;
}

.fade.favourite-auth-modal.modal.show {
  margin-left: 0%;
  margin-top: 0%;
  width: 100%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  border-radius: 0px !important;
}
}