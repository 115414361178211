.account-section {
  color: #1A276C;
  // margin-right: 5%;
  margin-top: 1%;
  // margin-left: 3%;
  height: 90vh;



  .details-section {
    margin-top: 5%;
    margin-left: 3%;
    box-shadow: 1px 2px 1px 0 rgba(0, 0, 0, 0.1), -1px 2px 1px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 60%;
    min-height: 75vh;

    .sub-title {
      font-size: 0.9rem;
      margin-left: 3%;
      color: black;

    }

    .second {
      margin-top: 10%;
    }

    .info-block {
      display: flex;
      margin-top: 3%;
      margin-bottom: 3%;
      margin-left: 5%;
      font-size: 0.9rem;

      .title {
        width: 300px;
        color: black;
        font-weight: 500;
      }

      .detail {
        font-weight: 600;
      }
    }
  }
}


@media screen and (max-width: 1100px) {
  .details-section {
    width: 80% !important;
  }
}



@media screen and (max-width: 900px) {
  .account-section {
    .title-line {
      display: none;
    }

  }

  .details-section {
    width: 95% !important;
    margin-left: 2.5% !important;
    margin-right: 2.5% !important;
  }

  .info-block {
    .title {
      width: 40% !important;
      padding-right: 2%;
    }

    .detail {
      width: 60% !important;
    }
  }
}