.fade.heatmap-details.modal.show {
  width: 100%;
  height: 100%;
  margin: 0%;
}



    .modal-body {
      .heatmap-overall {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;

        .heatmap-rows {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          h2 {
            font-size: 0.5rem;
            // margin-right: 10px;
            min-width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .heatmap-content {
            margin: 1px;
            .heatmap-property-title {
              min-width: 30px;
              // margin-right: 5px;
              padding-left: 5px;
              padding-right: 5px;
              min-height: 75px;
              display: flex;
              justify-content: center;
              align-items: center;
              .property-names {
                font-size: 0.5rem;
                
              }
              .property-numbers {
                font-size: 0.6rem;
                display: flex;
                color: transparent;
              }
            }
          }
      }
      }
    }


#id1.heatmap-content {
  background-color: rgba(255, 167, 229, 0.9);
}
#id2.heatmap-content {
  background-color: rgba(255, 167, 229, 0.7);
}
#id3.heatmap-content {
  background-color: rgba(255, 167, 229, 0.5);
}
#id4.heatmap-content {
  background-color: rgba(255, 167, 229, 0.3);
}
#id5.heatmap-content {
  background-color: rgba(255, 167, 229, 0.1);
}
#id6.heatmap-content {
  background-color: rgba(5, 24, 133, 0.1);
}
#id7.heatmap-content {
  background-color: rgba(5, 24, 133, 0.3);
}
#id8.heatmap-content {
  background-color: rgba(5, 24, 133, 0.5);
}
#id9.heatmap-content {
  background-color: rgba(5, 24, 133, 0.7);
}
#id10.heatmap-content {
  background-color: rgba(5, 24, 133, 0.9);
}
