.homepage-wrapper {
  max-width: 100vw;

}

.waitlist-consumer {
  margin-top: 5%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  // align-items: baseline;
  .waitlist-email {
    padding: 12px;
    width: 80%;
    border-radius: 10px;
    border: 1px solid #FDF7F0;
    margin-right: 3%;
    background-color: #1A276C;
    color: #FDF7F0;
  }

  .consumer-sign-up {
    background-color: #ED6B86;
    padding: 12px;
    width: 20%;
    color: #FDF7F0;
    border-radius: 10px;
    border: 1px solid #ED6B86;
    font-weight: bold;
    cursor: pointer;
  }
}

.website-opening {
  // background-color: #FDF7F0;
  background-color: #1A276C !important;


  .content-wrapper {
    display: flex;
    flex-direction: column;
    // align-items: center;

  }

  .headline-title-section {
    display: flex;
    flex-direction: row;
    // align-items: center;
    padding-top: 7.5%;
    height: 95vh;
    background-image: url('../../../public/white_icons/login-w.svg');
    background-position: right;
    /* position to the right and center vertically */
    background-repeat: no-repeat;
    background-size: 55% 100%;


    .mobile-logo {
      display: none;
    }

    .headline-left {
      display: flex;
      flex-direction: column;
      // align-items: center;
      text-align: left;
      width: 55%;
      margin-left: 10%;
      padding-right: 7%;
      height: 75%;

      h1 {
        font-size: 2.5rem;
        color: #FDF7F0;
        font-weight: 600;
        margin-bottom: 10%;

      }

      h4 {
        font-size: 1rem;
        color: #FDF7F0;
        font-weight: 500;
      }

      h5 {
        font-size: 1rem;
        color: #ED6B86;
        margin-top: 5%;
        font-weight: 500;
        margin-bottom: 5%;
      }

      .waitlist-consumer {
        margin-top: 12.5%;
      }


    }

    .headline-right {
      width: 50%;
      margin-right: 10%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 18%;

      .headline-image {
        // background-image: url('../../../public/white_icons/house-sticker.svg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 140px;
        height: 140px;
      }
    }



  }



  .consumer-process {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .process-steps {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-top: 8%;
      padding-bottom: 8%;

      .mobile-description {
        display: none;
      }

      .arrow {
        display: none;
      }

      .process-item {
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 60%;

        h5 {
          color: #1A276C;
          font-size: 1.5rem;
          text-align: left;
          font-weight: 500;
          display: block;
        }

        .process-line {
          height: 5px;
          width: 100px;
          background-color: #ED6B86;
          border-radius: 2.5px;
          margin-top: 5%;
        }

        .process-text {
          width: 50%;

        }

        .process-screen {
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          height: 480px;
          width: 250px;
          // background-size: 100% 100%;

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }

        #screen1 {
          background-image: url('../../../public/white_icons/mobile-choices-umer.png');
        }

        #screen2 {
          background-image: url('../../../public/white_icons/mobile-details-umer.png');
        }

        #screen3 {
          background-image: url('../../../public/white_icons/mobile-results-umer.png');
        }

        #screen4 {
          background-image: url('../../../public/white_icons/mobile-comparison-umer.png');
        }
      }
    }


    .process-item:nth-child(odd) {
      margin-left: 35%;

      .process-text {
        padding-right: 10%;
        padding-left: 10%;
        margin-right: 5%;
      }
    }

    .process-item:nth-child(even) {
      margin-right: 15%;

      .process-text {
        padding-left: 10%;
        padding-right: 10%;

      }
    }

    .process-item:nth-child(2),
    .process-item:nth-child(4) {
      margin-top: -10%;
    }

    .process-steps:nth-child(1) {
      background-color: #FDF7F0;

      h5 {
        color: #1A276C;
      }

      .process-line {
        background-color: #ED6B86;
      }
    }

    .process-steps:nth-child(2) {
      background-color: #ED6B86;

      h5 {
        color: #FDF7F0;
      }

      .process-line {
        background-color: #1A276C;
      }
    }
  }

  .blog-section-summary {
    margin-bottom: 5%;
    padding-top: 7%;
    padding-bottom: 10%;
    min-height: 525px;
    text-align: center;
    width: 100%;
    background-color: #FDF7F0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    // background-color: #1A276C;
    .blog-section-title {
      color: #ED6B86;
      font-size: 1.5rem;
      margin-bottom: 5%;
      font-weight: 500;
    }

    .blog-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      // background-color: #FDF7F0;
      .blog-item:hover {
        box-shadow: 3px 5px 5px 0 rgba(5, 24, 133, 0.15), -3px 5px 5px 0 rgba(5, 24, 133, 0.15);

      }

      .blog-item {
        width: 250px;
        // height: 225px;
        margin-left: 2%;
        margin-right: 2%;
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.1), -2px 3px 2px 0 rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        cursor: pointer;


        .blog-image-box {
          width: 250px;
          min-height: 190px;
          background-color: #FDF7F0;
          // z-index: 99;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        .blog-content {
          border: 0px !important;
          margin-top: -3%;
          // background-color: #FDF7F0;
          text-align: left;
          padding-left: 10%;
          padding-right: 10%;

          h2 {
            color: #ED6B86;
            font-size: 0.8rem;
            font-style: italic;
            padding-top: 15%;
          }

          .blog-item-summary {
            color: #1A276C;
            font-size: 0.7rem;
            margin-top: 7%;
            margin-bottom: 7%;
            min-height: 50px;
          }

          p {
            color: black;
            font-size: 0.7rem;
            margin-bottom: 10%;

          }
        }
      }
    }
  }


  .consumer-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 5%;
    background-color: #1A276C;
    color: #ED6B86;

    .wittle-logo {
      width: 165px;
      height: 70px;
      background-image: url('../../../public/white_icons/login-logo.svg');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .waitlist-consumer {
      justify-content: center;
    }

    .waitlist-email {
      width: 350px;
      margin-right: 1%;
    }

    .consumer-sign-up {
      width: 125px;
    }
  }


}


.content-wrapper-new {
  background: linear-gradient(to bottom, #1A276C 0%, #1A276C 14%, #ED6B86 22%, #ED6B86 28%, #FDF7F0 50%, #FDF7F0 95%, #1A276C 100%);
  min-height: 300vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .headline-top-section {
    min-height: 91vh;
    // background-color: #1A276C;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url('../../../public/white_icons/login-w.svg');
    background-position: right;
    /* position to the right and center vertically */
    background-repeat: no-repeat;
    background-size: 55% 100%;

    .main-message {
      font-size: 4rem;
      color: #ED6B86;
      padding-left: 15%;
      padding-right: 15%;
      font-weight: 900;
    }

    .secondary-message {
      color: #FDF7F0;
      padding-left: 25%;
      padding-right: 25%;
      margin-top: 1%;
    }

    .cta-buttons {
      margin-top: 2%;
      display: flex;
      flex-direction: row;

      button {
        width: 150px;
        padding: 10px;
        border-radius: 20px;
        border: 1px solid #ED6B86;
        font-weight: 700;
        cursor: pointer;
      }

      .start {
        background-color: #ED6B86;
        color: #FDF7F0;
        margin-right: 5%;
      }

      .more {
        background-color: #FDF7F0;
        color: #ED6B86;
        margin-left: 5%;
      }
    }

    .top-line-benefits {
      margin-top: 2%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;

      .benefit-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 2%;
        margin-right: 2%;
        width: 200px;

        h3 {
          color: #FDF7F0;
          font-weight: 500;
          font-size: 0.9rem;
          text-wrap: nowrap;
          text-align: left;
          width: 160px;
        }

        .tick {
          margin-right: 5px;
          background-image: url('../../../public/white_icons/tick-red.png');
          background-position: center;
          background-size: 100% 85%;
          background-repeat: no-repeat;
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .product-image-section {
    height: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .laptop-image {
      max-width: 100%;
      max-height: 85%;
    }
  }

  .client-testamonials {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      color: #1A276C;
      margin-bottom: 5%;
      font-size: 1.5rem;
      font-weight: 600;
      padding-left: 5%;
      padding-right: 5%;
      text-align: center;
    }

    .testimonial-slider {
      width: 90%;
      height: 400px;
      display: flex;
      flex-direction: row;

      .single-testimonial {
        width: 50%;
        height: 100%;
        background-color: white;
        border-radius: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2%;
        // margin-left: 1%;
        margin-right: 1%;

        .testimonial-image {
          max-width: 45%;
          height: 100%;
          border-radius: 50px;
          object-fit: cover;
        }

        .testimonial-body {
          margin-left: 2%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .quotes {
            background-image: url('../../../public/white_icons/open.png');
            background-position: center;
            /* position to the right and center vertically */
            background-repeat: no-repeat;
            background-size: cover;
            height: 40px;
            width: 40px;
          }

          .feedback {
            color: #1A276C;
            font-weight: 600;
            font-size: 1.1rem;
            margin-top: 5%;
            margin-bottom: 5%;
          }

          .name {
            font-size: 1.1rem;
            color: #ED6B86;
            font-weight: 900;
          }

          .title {
            font-size: 1rem;
            color: #ED6B86;
            font-weight: 700;
          }
        }
      }
    }
  }

  .cta-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .cta-buttons {
      margin-top: 5%;
      margin-bottom: 5%;
      display: flex;
      flex-direction: row;

      button {
        width: 150px;
        padding: 10px;
        border-radius: 20px;
        border: 1px solid #ED6B86;
        font-weight: 700;
        cursor: pointer;
      }

      .start {
        background-color: #ED6B86;
        color: #FDF7F0;
        margin-right: 5%;
      }

      .more {
        background-color: #FDF7F0;
        color: #ED6B86;
        margin-left: 5%;
      }
    }
  }

  .benefits {
    width: 90%;
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    // background-color: white;
    border-radius: 50px;
    margin-bottom: 5%;

    .benefit-array {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 2%;

      .benefit-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;

        .single-benefit {
          width: 25%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          h3 {
            font-size: 2rem;
            color: #1A276C;
            text-align: center;
            padding-left: 10%;
            padding-right: 10%;
            // width: 80%;
          }

          h1 {
            color: #ED6B86;
            font-size: 5rem;
            margin: 0px;
          }

          p {
            color: #1A276C;
            font-weight: 600;
            padding-left: 15%;
            padding-right: 15%;
            font-size: 1.3rem;
            text-align: center;
          }
        }
        .single-benefit:nth-child(2) {
          p {
            padding-left: 25%;
            padding-right: 25%;
          }
        }
      }
    }
  }

  .product-section {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 1250px;
    margin-left: 5%;
    margin-right: 5%;
    .product {
      display: flex;
      flex-direction: row;
      padding: 2%;
      background-color: white;
      margin-bottom: 5%;
      border-radius: 50px;
      border: 2px solid rgba(26,39,108,.15);

      .product-image {
        width: 50%;
        height: 400px;
        border-radius: 50px;
        border: 1px solid rgba(26,39,108,.15);

      }
      .product-info {
        padding-left: 3%;
        padding-right: 3%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .info-title {
          font-size: 3rem;
          color: #1A276C;
        }
        .cta-buttons {
          margin-top: 5%;
          margin-bottom: 5%;
          display: flex;
          flex-direction: row;
    
          button {
            width: 150px;
            padding: 10px;
            border-radius: 20px;
            border: 1px solid #ED6B86;
            font-weight: 700;
            cursor: pointer;
          }
    
          .start {
            background-color: #ED6B86;
            color: #FDF7F0;
            margin-right: 5%;
          }
    
          .more {
            background-color: #FDF7F0;
            color: #ED6B86;
            margin-left: 5%;
          }
        }
      }
    }
    .product:nth-of-type(even) {
      flex-direction: row-reverse;
    }
  }
}


@media screen and (max-width: 1150px) {
  .content-wrapper-new {
    .testimonial-body {
      .feedback {
        font-size: 1rem !important;
      }
    }
    .single-benefit {
      p {
        padding-left: 10% !important;
        padding-right: 10% !important;
      }
    }
  }

}

@media screen and (max-width: 1000px) {
  .content-wrapper-new {
    .main-message {
      font-size: 3rem !important;
      padding-left: 10% !important;
      padding-right: 10% !important;
    }
    .secondary-message {
      padding-left: 15% !important;
      padding-right: 15% !important;
    }
    .testimonial-body {
      .feedback {
        font-size: 0.9rem !important;
      }
      .name {
        font-size: 0.9rem !important;
      }
      .title {
        font-size: 0.8rem !important;
      }
    }
    .single-benefit {
      p {
        padding-left: 10% !important;
        padding-right: 10% !important;
      }
    }
    .product-section {
      .info-title {
        font-size: 2.2rem !important;
      }
    }
  }

}

@media screen and (max-width: 850px) {
  .content-wrapper-new {
    .headline-top-section {
      background-size: 85% 100% !important;    
    }
    .testimonial-slider {
      flex-direction: column !important;
      width: 500px !important;
      height: auto !important;
      .single-testimonial {
        width: 100% !important;
        margin-top: 10% !important;
        .testimonial-body {
          margin-bottom: 2% !important;
        }

      }
    }
    .benefits {
      width: 95% !important;
      margin-left: 2.5% !important;
      h3 {
        font-size: 1.5rem !important;
      }

      h1 {
        font-size: 3rem !important;
      }
      p {
        font-size: 1rem !important;
      }
    }
  }

}

@media screen and (max-width: 750px) {
  .content-wrapper-new {
    .product-section {
      .product {
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        width: 80%;
        .product-image {
          width: 100% !important;
        }
      }
    }

    .product-image-section {
      height: 300px !important;
    }
  }

}

@media screen and (max-width: 650px) {
  .content-wrapper-new {

    .product-section {
      .product {
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        width: 90%;
        .product-image {
          width: 100% !important;
        }
        .info-title {
          font-size: 2.5rem;
        }
      }
    }
  }

}

@media screen and (max-width: 550px) {
  .content-wrapper-new {
    .headline-top-section {
      background-size: 110% 120% !important; 
      .main-message {
        margin-bottom: 5%;
      }   
      .secondary-message {
        margin-bottom: 5%;
      }
      .cta-buttons {
        margin-bottom: 5%;
      }
 
      .top-line-benefits {
        flex-direction: column !important;
        align-items: center;
        .benefit-row {
          width: 250px !important;
          margin-bottom: 5% !important;
          h3 {
            width: auto !important;
          }
        }
      }
    }
    .cta-section {
      margin-top: 10% !important;
      margin-bottom: 10% !important;
    }
    .client-testamonials {
      h2 {
        // margin-bottom: 7% !important;
        color: #FDF7F0 !important;
      }
    }
    .testimonial-slider {
      width: 95% !important;
    }
    .product-section {
        .info-title {
          font-size: 2rem !important;
        }
    }
    .benefit-list {
      flex-direction: column !important;
      .single-benefit {
        width: 90% !important;
        h3 {
          font-size: 2rem !important;
        }
        h1 {
          font-size: 4rem !important;
        }
        p {
          font-size: 1.3rem !important;
        }
      }
    }
  }

}
@media screen and (max-width: 500px) {
  .content-wrapper-new {
    .main-message {
      font-size: 2.5rem !important;
      padding-left: 5% !important;
      padding-right: 5% !important;
    }
    .secondary-message {
      padding-left: 5% !important;
      padding-right: 5% !important;
      font-size: 0.8rem !important;
    }

 

    .testimonial-slider {
      width: 90% !important;
      .single-testimonial {
        flex-direction: column !important;
        .testimonial-image {
          width: 100% !important;
          max-width: 100% !important;
          height: 350px !important;
        }
        .testimonial-body {
          padding-left: 5%;
          padding-right: 5%;
          margin-top: 2%;
        }
      }
    }
    .product-section {
      width: 90% !important;
      .product {
        width: 100% !important;
      }
        .info-title {
          font-size: 1.5rem !important;
        }
        .product-image {
          height: 300px !important;
          margin-bottom: 2%;
        }
    }
    .benefit-list {
      flex-direction: column !important;
      .single-benefit {
        width: 90% !important;
        h3 {
          font-size: 2rem !important;
        }
        h1 {
          font-size: 4rem !important;
        }
        p {
          font-size: 1.3rem !important;
        }
      }
    }
  }

}






@media screen and (max-width: 1010px) {
  .blog-section-summary {
    // width: 70% !important;
    padding-bottom: 5%;
    height: auto !important;

    .blog-list {
      flex-direction: column !important;
      align-items: center !important;
    }

    .blog-section-title {
      margin-bottom: 5%;
    }

    .blog-item {
      height: 100% !important;
      margin-bottom: 10%;

      // width: 50% !important;
      .blog-item-summary {
        margin-top: 5% !important;
        min-height: auto !important;
        // margin-bottom: 10% !important;
      }
    }
  }

}



@media screen and (max-width: 900px) {
  .website-opening {
    .headline-title-section {
      .headline-top {

        // margin-bottom: 10%;
        h1 {
          font-size: 2.2rem;
          margin-bottom: 2%;
          padding-left: 2%;
          padding-right: 2%;
        }

        h4 {
          margin-bottom: 1%;
          padding-left: 8%;
          padding-right: 8%;
        }

        h5 {
          padding-left: 5%;
          padding-right: 5%;
        }
      }

    }

    .consumer-process {
      align-items: center;

      .process-steps {
        // width: 390px;
        padding-bottom: 15%;
        padding-top: 15%;

        .arrow {
          display: block;
        }

        .mobile-description {
          display: block;
          font-size: 1.5rem;
          font-weight: 500;
          padding: 0 5% 0 5%;
          text-align: center;
          width: 390px;

        }

        .process-item {
          display: flex;
          flex-direction: row-reverse;
          justify-content: center;
          width: 390px;
          padding: 0%;

          .process-text {
            h5 {
              display: none;
              visibility: hidden;
            }

            .process-line {
              display: none;
            }
          }

          .process-screen {
            background-position: center left;
            background-size: cover;
            background-repeat: no-repeat;

            height: 400px;
            width: 62%;

          }
        }

        .process-item:nth-child(odd) {
          margin-left: 0px;
          padding-left: 40px;
        }

        .process-item:nth-child(even) {
          margin-right: 40px;
          margin-top: -220px;
        }
      }

    }

    .process-steps:nth-child(1) {
      background-color: #FDF7F0;
      background-image: url('../../../public/white_icons/login-w-red.png');
      background-position: center bottom;
      background-size: cover;
      background-repeat: no-repeat;
      background-size: 150% 70%;
    }

    .consumer-bottom {
      padding-top: 7%;

      .wittle-logo {
        display: block !important;
        width: 165px !important;
        height: 70px !important;
        // padding-bottom: 10% !important;
      }

      .waitlist-consumer {
        justify-content: center;
        margin-top: 15%;
        margin-bottom: 10%;
        padding-right: 5%;
        padding-left: 5%;
      }

      .waitlist-email {
        width: 300px;
        margin-right: 1%;
      }

      .consumer-sign-up {
        width: 125px;
      }
    }

    .headline-title-section {
      background-size: 150% 90%;
      position: left;
    }

    .headline-left {
      width: 100% !important;
      align-items: center;
      text-align: center;
      justify-content: space-between;
      padding-right: 0% !important;
      margin-right: 10%;

      // padding-bottom: 45%;
      h1 {
        font-size: 1.8rem !important;
        text-align: center;
        // margin-bottom: 10% !important;
      }

      h4 {
        text-align: center;
        font-size: 1.0rem !important;
      }

      h5 {
        text-align: center;
      }


      .mobile-logo {
        display: block;
        background-image: url('../../../public/white_icons/login-logo.svg');
        background-position: center;
        /* position to the right and center vertically */
        background-repeat: no-repeat;
        background-size: cover;

        width: 140px;
        height: 60px;
      }
    }

    .headline-right {
      width: 0% !important;
      margin-right: 0% !important;
    }
  }
}


#arrow1 {
  width: 230px;
  height: 110px;
  background-image: url('../../../public/white_icons/arrow1.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: -20px;
  margin-left: -50px;
}

#arrow2 {
  width: 260px;
  height: 110px;
  background-image: url('../../../public/white_icons/arrow2.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -35px;
  margin-left: -40px;

}

#arrow3 {
  width: 180px;
  height: 80px;
  background-image: url('../../../public/white_icons/arrow3.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: -100px;

}

#arrow4 {
  width: 110px;
  height: 140px;
  background-image: url('../../../public/white_icons/arrow4.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -120px;
  margin-left: -180px;

}