
.main-form-pages {
  // background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),url('../../../../public/website_images/islington.png');
  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  background-color: #FDF7F0;
  // height: 100vh;
  position: relative;
  z-index: 0;
  // padding-top: 1%;
  .title-section {
    .selectors {
      padding: 5px 6px 5px 6px;
      border-radius: 10px;
      margin: 0px 10px 10px 0px;
      background-color: rgba(255, 167, 229, 0.2);
      border: 1px solid  #FFA7E5;
      cursor: pointer;
      color: #051885;
    }
  }
}

.form-input-page{
  min-height: 100vh;
}

.title-section-profile, .title-section {
  padding-top: 0%;
  h1{
    text-align: left;
    font-size: 1rem;
    color: #051885;
    span{
      color: #051885;
    }
  }
  p {
    font-size: 0.8rem;
    color: #051885;
  }
}

// .title-section {
//   h1 {
//     text-align: center !important;
//     font-size: 1.7rem;
//     color: #FFA7E5;
//     padding-left: 5% !important;
//     padding-right: 5% !important;
//   }
// }


.main-content-profile {
  .form-selection {
    padding-left: 0% !important;
    .form-cards {
      h3 {
        margin-left: 0%;
      }
    }

  }
  .key-image{
    display: none;
  }

}


.main-content, .main-content-profile{
  margin-top: 1%;
  display: flex;
  flex-direction: row;
  z-index: 99;
  width: 100%;
  height: 50%;


  // Form section to the left of the page where user selects their inputs
  .form-selection {
    padding-left: 10%;
    // height: 60%;
    width: 55%;
    .form-selection-detail{
      width: 100%;
      margin-bottom: 5%;
    }
    .form-cards {
      // height: 100px;
      margin-bottom: 5%;
      // #
      h3{
        margin-bottom: 1%;
        // margin-left:5px;
        margin-right: 5px;
        color: #FFA7E5;
      }
      .selection-buttons {
        display: flex;
        flex-wrap: wrap;
        .selectors {
          padding: 3px 10px 3px 10px;
          border-radius: 10px;
          margin: 0px 10px 10px 0px;
          background-color: rgba(255, 167, 229, 0.2);
          border: 1px solid  #FFA7E5;
          cursor: pointer;
          color: #051885;
        }
      }
    }
    .next{
      font-weight: 600;
      margin-left: 5px;
      width: 90px;
      padding: 5px;
      background-color: black;
      border: 2px solid black;
      border-radius: 10px;
      color: white;
      cursor: pointer;
    }
  }

  .form-cards:last-child {
    margin-bottom: 0% !important;
  }

  // Image section to the right of the page
  .key-image{
    width: 35%;
    background-color: white;
    border: 1px solid #051885;
    border-radius: 30px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),url('../../../../public/website_images/first-search.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
} 



@media screen and (max-width: 900px) {
  .title-section-profile {
    margin-bottom: 5%;
    h1{
      text-align: left;

      font-size: 1rem;
      color: #051885;
    }
    p {
      font-size: 0.8rem;
      color: #051885;
    }
  }
  .main-content, .main-content-profile{
    
    display: flex;
    flex-direction: column;
    .form-selection{
      width: calc(90%);
      padding-left: 5%;

    }
  
    .key-image{
    visibility: hidden;
    }
  }
  .form-selection-detail{
    margin-bottom: 5% !important;
  }


  .profile-main-section {
    .title-section {
      padding-top: 0%;
      h1 {
        padding-left: 5% !important;
        padding-right: 5% !important;
        margin-bottom: 2%;
        font-size: 1rem;
        text-align: left;
        color: #051885;
      }
    }
    .form-selection {
      padding: 0%;
      h3 {
        margin-left: 0% !important;
        font-size: 1rem;
        margin-bottom: 2% !important;
      }
    }
  }

}