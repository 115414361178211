.menu-container {
  // position: relative;
  // width: 100vw;
  // padding-top: 1%;
  margin-right: 5%;
  display: flex;
  flex-direction: row-reverse;
  // position: fixed;
  font-size: 0.9rem;
  display: none;

  .cta {
    color: #FDF7F0;
    cursor: pointer;
  }
}

.menu {
  background: #FDF7F0;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 225px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 99;
  margin-right: 5%;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  // margin-right: 6%;
}

.menu ul {
  list-style: none;
  padding: 0;
  // padding-right: 2%;
  margin: 0;
}



.menu li {
  // border-bsottom: 1px solid #dddddd;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
  cursor: pointer;
  color: #1A276C;

}

.dropdowns:last-child a {
  border-top: 2px solid #FDF7F0 !important;
  cursor: pointer;
}


.menu-trigger {
  display: block;
  width: 40px;
  background-color: transparent;
  cursor: pointer;


  span {
    margin: 0%;
    font-size: 0px;

  }

  .burger-icon {
    display: flex;
    flex-direction: column;

    .burger-icon-line {
      margin-top: 2.5px;
      margin-bottom: 2.5px;
      border: 1.5px solid #FDF7F0;
      border-radius: 3px;
      width: 80%;
      margin-left: 10%;
      visibility: visible;
    }
  }
}



.menu-trigger span {
  font-weight: 700;
  vertical-align: middle;
  font-size: 14px;
  margin: 0 10px;
}



.menu .form-detail {
  list-style: none;
  padding: 0;
  // padding-right: 2%;
  margin: 0;
  display: flex;
  flex-direction: column;

  p {
    margin-left: 12%;
    margin-top: 8%;
    font-size: 0.8rem;
    color: #1A276C;
    // color: #FDF7F0;
    font-weight: bold;
  }

  .input {
    margin-top: 8%;
    width: 75%;
    margin-left: 12%;
    color: #1A276C;
    background-color: rgb(232, 240, 254);

  }



  .sign-up {
    margin-top: 8%;
    width: 75%;
    margin-left: 12%;
    border-radius: 5px;
    background-color: #FDF7F0;
    border: 2px solid #FDF7F0;
    color: white;
    font-weight: bold;
    padding: 3px;
    cursor: pointer;
    // margin-bottom: 10px;
  }

  h5 {
    width: 75%;
    margin-left: 12%;
    margin-top: 8%;
    margin-bottom: 8%;
    color: #1A276C;
    font-weight: 400;

    span {
      color: #FDF7F0;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.modal-body {
  .form-detail {}
}


.nav-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding-top: 3%;
  padding-left: 10%;
  padding-right: 10%;

  // background-color: #1A276C;
  .nav-action {
    margin-right: 5%;
    background-color: #1A276C;
    color: #FDF7F0;
    border-radius: 5px;
    padding: 5px;
    // padding: 3px 10px 3px 10px;
    width: 125px;
    border: 1px solid #1A276C;
    font-weight: 700;
    cursor: pointer;
    font-size: 1rem;
  }

  .left-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    // padding: 10%;

    .logo-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 140px;
      height: 60px;

      .wittle-logo {
        width: 100%;
        height: 100%;
        background-image: url('../../../../public/white_icons/login-logo.svg');
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
  }

  h4 {
    margin-left: 5%;
    color: #FDF7F0;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
  }

  .nav-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 300px;
    .agent-login {
      display: block;
      color: #FDF7F0;
      font-weight: 500;
      width: 170px;
      padding: 7.5px 15px 7.5px 15px;
      border-radius: 7.5px;
      border: 1.5px solid #FDF7F0;
      background-color: #1A276C;
      font-size: 1rem;
      cursor: pointer;
    }
    .pricing {
      width: 130px;
      // color: #ED6B86;
      color: #FDF7F0;
      font-size: 1rem;
      cursor: pointer;
      font-weight: 500;
    }
  }
}







@media screen and (max-width: 900px) {

  .nav-section {
    justify-content: flex-end;
    .logo-section {
      margin-bottom: 0% !important;
    }
  }

  .left-section {
    // width: 50% !important;
    h4 {
      font-size: 0.8rem;

    }
  }
}



@media screen and (max-width: 600px) {
  .nav-section {
    .left-section {
      // display: none !important;
    }
    .logo {
      h2 {
        font-size: 2.0rem;
      }
    }

    h4 {
      display: none;
    }

    .menu-container {
      display: block !important;
    }

    .agent-login {
      display: none;
    }

    .nav-right {
      display: none;
    }
  }


}