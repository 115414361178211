
.denied-page{
  // background-image: linear-gradient(to bottom, rgba(253, 247, 240, 0.9), rgba(253, 247, 240, 0.8)), url('../../../../public/website_images/derelict.png');
  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  background-color: #FDF7F0;
  min-height: 100vh;
  .nav-section {
    display: flex;
    width: 100vw;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 3px solid #efe7df;
    height: 72px;
    // background-color: #FDF7F0;

    .logo {
      color: #FFA7E5;
      padding-top: 3px;
      padding-left: 5%;
      cursor: pointer;

      h2 {
        font-size: 2.2rem;
        color: #FFA7E5;
      }
    }

    .profile-btn {
      padding: 7px;
      width: 150px;
      background-color: #FFA7E5;
      border: none;
      border-radius: 7px;
      color: white;
      font-weight: bold;
      cursor: pointer;
      margin-right: 75px;
    }
  }


  .denied-section {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
    width: 80%;

    // display: flex;
    // justify-content: center;
    .no-access-body {
      background-color: white;
      border-radius: 50px;
      color: #1A276C;
      .no-access-title {
        text-align: center;
      }
      // height: 60vh;
    }
    button {
      background-color: #ED6B86;
      color: #FDF7F0;
      padding: 7.5px;
      border: 1px solid #ED6B86;
      border-radius: 10px;
      width: 125px;
      font-weight: 700;
      cursor: pointer;
    }
  }

}


@media screen and (max-width: 600px) {
  .denied-page {
    .denied-section {
      .denied-top {
        display: flex;
        flex-direction: column;
        align-content: center;
        h1 {
          font-size: 3rem;
        }
        h3 {
          margin-left: 7%;
          margin-right: 7%;
        }
      }
      // width: 90%;
      button {
        font-size: 0.9rem;
      }

    }
  }
}