.login-page {
  background-image: url('../../../../public/white_icons/login-w.svg');
  background-position: left top center;
  /* position to the right and center vertically */
  background-repeat: no-repeat;
  background-color: #1A276C;
  background-size: 50% 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .login-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
  }

  .login-content.login {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 300px;
    height: 500px;
    margin-left: 40%;
    // width
  }

  .pricing-top {
    color: #FDF7F0;
    margin-bottom: 2%;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .pricing-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    margin-left:10%;
    margin-right: 10%;
    // margin-top: 15%;s
    // background-color: rgba(253, 247, 240, 0.5);
    // border: 1px solid rgba(253, 247, 240, 0.5);
    // background-color: #1A276C;
    height: 310px;
    width: 300px;

    .pricing-headers {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      .pricing-option {
        background-color: #ED6B86;
        width:25%;
        font-size: 0.9rem;
        font-weight: 600;
        text-align: center;
        padding: 5px;
        cursor: pointer;
        border-left: 1px solid #ED6B86;
        border-right: 1px solid #ED6B86;
      }
      .pricing-option:nth-child(1){
        border-top-left-radius: 30px;
      }
      .pricing-option:nth-child(4){
        border-top-right-radius: 30px;
      }
    }

    .pricing-title {
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      box-shadow: 3px 5px 3px 0 rgba(255, 255, 255, 0.1), -3px 5px 3px 0 rgba(241, 240, 240, 0.1);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #1A276C;
      .pricing-cost {
        font-size: 1.5rem;
        color: #FDF7F0;
        text-align: center;
        margin-top: 5%;
        margin-bottom: 5%;
      }
      .pricing-subtitle {
        color:#FDF7F0;
        font-size: 0.9rem;
      }

      .monthly-offer {

        .feature-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 10%;
          margin-bottom: 10%;
  
          .feature-icon {
            width: 30px;
  
            .icon {
              background-image: url('../../../../public/white_icons/tick-red.png');
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              height: 20px;
              width: 20px;
            }
          }
  
          .feature {
            font-size: 0.7rem;
            color: #FDF7F0;
            width: 190px;
          }
        }
      }
    }
  }

  .register-page-split {
    display: flex;
    flex-direction: column;
    margin-left:60%;
  }

    // margin-left: 40%;
    // width: 320px;

    .logo-section {
      margin-bottom: 10%;
      width: 280px;
      height: 120px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .wittle-logo {
        width: 90%;
        height: 90%;
        background-image: url('../../../../public/white_icons/login-logo.svg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }

    h1 {
      font-size: 1.5rem;
      color: #FDF7F0;
      font-weight: 500;
      margin-bottom: 10%;
    }

    .input-section {
      width: 100%;
      .login-input {
        margin-bottom: 5%;
        width:100%;

        h3 {
          font-size: 0.8rem;
          color: #FDF7F0;
          font-weight: 400;
          margin-bottom: 3%;
        }

        input {
          background-color: #FDF7F0;
          border-radius: 10px;
          padding: 10px;
          border: 1px solid #FDF7F0;
          width: 100%;
          color: #1A276C;
        }

      }



    }



    .register-section {
      width: 100%;
      .login-input {
        margin-bottom: 3%;

        h3 {
          font-size: 0.8rem;
          color: #FDF7F0;
          font-weight: 500;
          margin-bottom: 1%;
        }

        input {
          background-color: #1A276C;
          border-radius: 10px;
          padding: 3px;
          border: 1px solid #FDF7F0;
          width: 100%;
          color: #FDF7F0;
          font-size: 0.8rem;
          // min-height: 35px;
        }

        p {
          font-size: 0.7rem;
          color: #ED6B86;
          margin-top: 2%;
        }
      }

      .css-13cymwt-control {
        color: #FDF7F0;
      }
    }

    button {
      margin-top: 1%;
      border-radius: 10px;
      padding: 10px;
      color: #FDF7F0;
      background-color: #ED6B86;
      border: 1px solid #ED6B86;
      font-weight: 700;
      cursor: pointer;
      width: 100%;
    }

    p {
      font-weight: 400;
      font-size: 0.8rem;
      text-align: left;
      margin-top: 4%;
      color: #FDF7F0;

      span {
        color: #ED6B86;
        cursor: pointer;
      }
    }

    .payment-array {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .payment-button {
        width: 23%;
        margin-top: 0%;
        padding: 5px;
        background-color: #FDF7F0;
        color: #ED6B86;
      }
    }

  }
// }

#register {
  .register-title {
    h1 {
      margin-bottom: 3%;
    }
  }

  .logo-section {
    margin-bottom: 2%;
    width: 280px;
    height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .wittle-logo {
      width: 90%;
      height: 90%;
      background-image: url('../../../../public/white_icons/login-logo.svg');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
      margin-left: 10%;
    }
  }
}


@media screen and (max-width: 900px) {

  .login-content {
    margin-left: 0% !important;
    width: 300px !important;
  }

  .login-page {
    background-size: 150% 100%;
    background-position: bottom;

  }

  .wittle-logo {
    width: 70% !important;
    height: 70% !important;
  }

  .logo-section {
    margin-bottom: 5% !important;
  }

  .register-page-split{
    margin-left: 0% !important;
  }

}