.agent-specific-property {
  background-color: #FDF7F0;
  min-height: 100vh;
  padding-bottom: 5%;


  .go-back-button {
    margin-left:calc(5% + 220px);
    
    h5 {
      color: #FFA7E5;
      cursor: pointer;
      width: 175px;
    }
  }

  .property-options {
    margin-left:calc(5% + 220px);
    margin-right: 5%;
    margin-top: 1.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #051885;
    .left-section {
      display: flex;
      flex-direction: row;
      width: 50%;
    }
    // border-bottom: 1px solid #051885;
    h5 {
      margin-right: 5%;
      font-size: 1rem;
      cursor: pointer;

      // padding-bottom: 0.5%;
    }
    .print-button {
      cursor: pointer;
    }
  }

  .property-details {
    margin-left:calc(5% + 220px);
    margin-right: 5%;
    margin-top: 1%;
    display: flex;
    .property-image {
      width: 50%;
      height: 300px;
      border: 1px solid #051885;
      object-fit: cover;
      object-position: center;
      // background-image: url('../../../../public/website_images/islington.png');
      // background-position: center;
      // background-size: cover;
      // background-repeat: no-repeat;
      border-radius: 10px;
    }
    .property-content {
      margin-left: 3%;
      color: #051885;
      width: 50%;
      display: flex;
      flex-direction: column;
      .content-blocks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: auto;
        .content-summary {
          display: flex;
          flex-direction: column;
          .content-top-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          h3 {
            font-weight: 500;
            font-style: italic;
          }
        }
        .content-details {
          .content-details-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.5%;
          }
          .content-details-bottom {
            display: flex;
            justify-content: space-between;
          }
        }
      }
     
      
    }
  }

  .property-summary-sections {
    margin-left:calc(5% + 220px);
    margin-right: 5%;
    margin-top: 3%;
    margin-bottom: 5%;
    .highlight-separator {
      visibility: visible;
      margin-top: 1%;
      margin-bottom: 1%;
      border: 0.5px solid rgba(5, 24, 133, 0.2);
    }
    .property-highlight {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #051885;
      cursor: pointer;
      h3 {
        font-size: 1rem;
      }

      .click-downs {
        display: flex;
        justify-content: flex-end;
        width: 15%;
        button {
          width: 100px;
          background-color: #FFA7E5;
          color: #FDF7F0;
          border-radius: 5px;
          border: 1px solid #FFA7E5;
          font-size: 0.7rem;
          font-weight: bold;
        }
        h4 {
          margin-left: 10%;
        }
      }
    }
    .box-highlights {
      display: flex;
      flex-direction: column;
      margin-top: 1%;
      .row {
        display: flex;
        flex-direction: row;
        margin-bottom: 2%;
        .column {
          width: 50%;
          padding-right: 5%;
          .block-title {
            color: #FFA7E5;
            font-size: 1rem;
            font-weight: 700;
            margin-top: 0%;
          }
          h5 {
            margin-top: 2%;
            margin-bottom: 3%;
            color: #051885;
            font-size: 0.8rem;
            font-weight: 500;
          }
        }
      }
    }

    .school-highlights {
      .school-block {
        display: flex;
        flex-direction: column;
        margin-top: 1%;
        #column1 {
          width: 3%;
        }
        #column2 {
          width: 47%;
        }
        #column3 {
          width: 20%;
        }
        #column4 {
          width: 20%;
        }
        #column5 {
          width: 10%;
        }
        .block-title {
          color: #FFA7E5;
          font-size: 1rem;
          font-weight: 700;
          margin-top: 0%;
        }
        .school-table-headers {
          display: flex;
          flex-direction: row;
          color: #051885;
          margin-top: 1%;
          margin-bottom: 0.5%;

        }
        .school-table-details {
          display: flex;
          flex-direction:column;
          color: #051885;
          margin-bottom: 0.75%;
          .school-content {
            display: flex;
            flex-direction: row;
            align-items: center;

            h5 {
              font-weight: 500;
              font-size: 0.8rem;
            }
            .column {
              margin-top: 0.75%;
              margin-bottom: 0.75%;
              cursor: pointer;
            }

          }
        } 
      }
    }


    .summary-section {
      display: flex;
      flex-direction: column;
      .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 2%;
        .individual-box {
          display: flex;
          flex-direction: row;
          align-content: center;
          height: 100px;
          max-width: 33%;
          min-width: 33%;
          .pie-chart {
            height: 100px;
          }
          .content {
            height: 100px;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #051885;
            h1 {
              margin-top: 10px;
              font-weight: 500;
              font-size: 0.8rem;
            }
          }
        }
      }
    }

  }

  // .detail-slider {
  //   margin-left:calc(5% + 220px);
  //   margin-top: 1%;
  //   display: flex;
  //   overflow-x: scroll;
  //   h5 {
  //     margin-right: 1%;
  //     border: 1px solid #FFA7E5;
  //     padding: 3px 10px 3px 10px;
  //     border-radius: 10px;
  //     color: #051885;
  //     cursor: pointer;
  //     background-color: rgba(255, 167, 229, 0.2);
  //     font-weight: 400;
  //     font-size: 0.8rem;
  //   }
  // }

  .loading-screen {
    margin-left: calc(220px);
    display: flex;
    margin-top: 12%;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    h1 {
      font-size: 1.2rem;
      color: #051885;
    }
    h3 {
      font-size: 1.3rem;
      color: #FFA7E5;
      padding-left: 15%;
      padding-right: 15%;
      text-align: center;
    }
    button {
      margin-top: 2%;
      width: 150px;
      padding: 5px;
      background-color: #FFA7E5;
      border: 2px solid #FFA7E5;
      border-radius: 10px;
      color: white;
      font-weight: 700;
      cursor: pointer;
    }
  }


}



@media screen and (max-width: 900px) {
  .summary-section {
    .individual-box{
      min-width: 50% !important;
      max-width: 50% !important;
        .content {
          h1 {
            font-size: 0.8rem !important;
            padding-right: 5%;
          }
        }
    h1 {
      font-size: 0.8rem !important;
    }
  }

}
}


@media screen and (max-width: 800px) {

  .desktop-nav {
    display: none !important;
  }
  .mobile-nav {
    display: block !important;
  }

  .go-back-button {
    margin-left: 5% !important;
    margin-top: 2% !important;
  }

  .property-options {
    margin-left: 5% !important;
    margin-top: 1% !important;
    .print-button {
      display: none;
    }
    .left-section{
      width: 100% !important;
    }
  }

  .property-details {
    margin-left: 5% !important;
    margin-top: 2% !important;
  }

  .property-summary-sections {
    margin-left: 5% !important;
  }
}




@media screen and (max-width: 700px) {

  .go-back-button {
    margin-left: 5% !important;
    margin-top: 2% !important;
  }

  .property-options {
    h5 {
      font-size: 0.8rem !important;

    }
  }
  .property-details {
    .property-image {
      height: 200px !important;
    }
  }

  .summary-section {
    .row {
      .individual-box {
        min-width: 50% !important;
        max-width: 50% !important;
        .content {
          h1 {
            font-size: 0.6rem !important;
            padding-right: 5%;
          }
        }
      }
    }

    h1 {
      font-size: 0.65rem !important;
    }
  }

  .column {
    h5 {
      font-size: 0.65rem !important;
    }
  }

  .school-highlights {
    overflow-x: scroll;
    .school-content {
      .column {
        h5 {
          font-size: 0.65rem !important;
        }
      }
    }


    #column1 {
      min-width: 10%;
    }
    #column2 {
      min-width: 65%;
      padding-right: 5%;
    }
    #column3 {
      min-width: 30%;
    }
    #column4 {
      min-width: 30%;
    }
    #column5 {
      min-width: 25%;
    }
  }

}


@media screen and (max-width: 600px) {
  .go-back-button {
    margin-top: 3% !important;
    margin-bottom: 2% !important;
  }

  .property-details {
    margin-top: 2% !important;
    .property-image {
      height: 200px !important;
    }
    .content-blocks {
      font-size: 0.7rem !important;
    }

  }

  .summary-section {
    h1 {
      font-size: 0.7rem !important;
    }
  }

  .column {
    h5 {
      font-size: 0.7rem !important;
    }
  }

  .property-highlight {
    margin-top: 5% !important;
    h3 {
      font-size: 0.9rem !important;

    }
  }

  .highlight-separator {
    margin-top: 5% !important;
  }
}