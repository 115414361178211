#property-search-insights-wrapper {
  margin-left: 0%;
  margin-top: 0%;

  .property-insights-wrapper {
    box-shadow: none;
    width: 97%;
    margin-top: 0%;
    .return-to-properties {
      margin-left: 3%;
      margin-top: 0%;
      cursor: pointer;
      h3 {
        color: #1A276C;
        font-size: 0.8rem;
      }
    }

    .insight-dropdowns {
      margin-left: 3%;
    }

    .single-property-navigation {
      display: flex;
      flex-direction: row;
      margin-left: 2%;

      .section-selectors {
        display: flex;
        flex-direction: row;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 1%;
        overflow: auto;
    
        .property-insight-buttons {
          display: flex;
          flex-direction: row;
    
        }
    
        .selector-button {
          text-wrap: nowrap;
          font-size: 0.75rem;
          color: #1A276C;
          font-weight: 500;
          padding: 10px 30px 10px 30px;
          cursor: pointer;
        }
    
        .selector-button.active {
          background-color: white;
          font-weight: 600;
        }
    
        .selector-button.inactive {
          background-color: white;
          font-weight: 600;
          background-color: rgba(26, 39, 108, 0.07);
          color: rgba(26, 39, 108, 0.6);
          ;
    
        }
    
        #left {
          border-top-left-radius: 15px;
        }
    
        #right {
          border-top-right-radius: 15px;
        }
    
      }
    }
  }
}

.insights-results-wrapper {
  min-height: 85vh;
  // max-height: 100vh;
  margin-left: 3%;

  .insights-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    padding: 0.5% 2% 0.5% 2%;
    border-radius: 10px;
    margin-top: 2%;
    background-color: rgba(26, 39, 108, 0.03);


    .direction-arrow {
      background-image: url('../../../../public/white_icons/left.svg');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    .navigation-input {
      display: flex;
      flex-direction: column;
      font-size: 0.7rem;

      .title {
        color: black;
        font-weight: 500;
      }

      .result {
        color: #ED6B86;
        font-weight: 500;

      }
    }
  }

  .property-insights-wrapper {
    margin-top: 2%;
    width: 95%;
    box-shadow: 1px 2px 1px 0 rgba(0, 0, 0, 0.1), -1px 2px 1px 0 rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin-bottom: 2%;
    padding-bottom: 0.5%;




    .insight-dropdowns {
      margin-left: 5%;
      margin-top: 2%;

      .summary-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        cursor: pointer;
        margin-top: 3%;
        margin-bottom: 3%;

        h3 {
          color: #ED6B86;
          font-size: 0.75rem;
          font-weight: 600;
          text-wrap: nowrap;
          margin-right: 5%;
        }

        .header-line {
          border: 1px solid rgba(26, 39, 108, 0.1);
          ;
          visibility: visible;
          width: 100%;
          margin-right: 5%;
        }
      }

      .summary-section {
        margin-bottom: 3%;

        .row {
          display: flex;
          flex-wrap: wrap;
          margin-top: 2%;

          .individual-box {
            width: 33%;
            display: flex;
            flex-direction: row;
            align-content: center;
            height: 100px;
            max-width: 33%;
            min-width: 33%;

            .pie-chart {
              height: 100px;
            }

            .content {
              height: 100px;
              display: flex;
              flex-direction: row;
              align-items: center;

              h1 {
                margin-top: 10px;
                font-weight: 500;
                font-size: 0.65rem;
                padding-left: 5%;
                padding-right: 5%;
                color: black;
              }
            }
          }
        }
      }


      .school-highlights {
        margin-top: 2%;

        .school-block {
          display: flex;
          flex-direction: column;
          margin-top: 1%;

          #column1 {
            width: 3%;
          }

          #column2 {
            width: 47%;
          }

          #column3 {
            width: 20%;
          }

          #column4 {
            width: 20%;
          }

          #column5 {
            width: 10%;
          }

          .variable-title {
            display: flex;
            flex-direction: row;
            align-items: center;

            .variable-icon {
              width: 25px;
              height: 25px;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
            }

            .block-title {
              color: #1A276C;
              font-size: 0.8rem;
              font-weight: 600;
              margin-top: 0%;
              margin-left: 1%;
            }
          }

          .school-table-headers {
            display: flex;
            flex-direction: row;
            color: rgba(26, 39, 108, 0.4);
            margin-top: 2%;
            margin-bottom: 0.5%;
            margin-left: 1%;
            font-size: 0.85rem;

          }

          .table-divider {
            visibility: visible;
            display: block;
            border: 0.5px solid rgba(26, 39, 108, 0.1);
            ;
            margin-right: 5%;
          }

          .school-table-details {
            display: flex;
            flex-direction: column;
            color: #1A276C;
            margin-bottom: 0.75%;
            margin-left: 1%;

            .school-content {
              display: flex;
              flex-direction: row;
              align-items: center;

              h5 {
                font-weight: 500;
                font-size: 0.65rem;
                color: black;
              }

              .column {
                margin-top: 0.75%;
                margin-bottom: 0.75%;
                cursor: pointer;
              }

            }
          }
        }
      }


      .box-highlights {
        display: flex;
        flex-direction: column;
        margin-top: 1%;
        margin-left: 1%;

        .row {
          display: flex;
          flex-direction: row;
          margin-bottom: 2%;

          .column {
            width: 50%;
            padding-right: 5%;

            .variable-title {
              display: flex;
              flex-direction: row;
              align-items: center;

              .variable-icon {
                width: 25px;
                height: 25px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
              }

              .block-title {
                color: #1A276C;
                font-size: 0.8rem;
                font-weight: 600;
                margin-top: 0%;
                margin-left: 1%;
              }
            }

            .results-details {
              li {
                font-size: 0.7rem;
                margin-top: 2%;
                margin-bottom: 2%;
              }
            }
          }
        }
      }
    }


    .property-details-wrapper {
      .detail-slider {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        margin-top: 2%;
        margin-left: 3%;
        margin-right: 3%;

        .slider-button {
          text-wrap: nowrap;
          border-radius: 15px;
          padding: 5px 15px 5px 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 10px;
          font-size: 0.75rem;
          cursor: pointer;

          .slider-icon {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }

        }

        .slider-button.active {
          background-color: #ED6B86;
          color: #FDF7F0;

          .slider-text {
            font-weight: 500;
          }

          #primaries {
            background-image: url('../../../../public/white_icons/White/child.svg');
          }

          #secondaries {
            background-image: url('../../../../public/white_icons/White/school-building.svg');
          }

          #tubes {
            background-image: url('../../../../public/white_icons/White/tube.svg');
          }

          #trains {
            background-image: url('../../../../public/white_icons/White/train.svg');
          }

          #evs {
            background-image: url('../../../../public/white_icons/White/electricity.svg');
          }

          #restaurants {
            background-image: url('../../../../public/white_icons/White/food.svg');
          }

          #pubs {
            background-image: url('../../../../public/white_icons/White/beer.svg');
          }

          #parks {
            background-image: url('../../../../public/white_icons/White/park.svg');
          }

          #gyms {
            background-image: url('../../../../public/white_icons/White/gym.svg');
          }

          #supermarkets {
            background-image: url('../../../../public/white_icons/White/supermarket.svg');
          }

          #crime {
            background-image: url('../../../../public/white_icons/White/handcuffs.svg');
          }

          #distance {
            background-image: url('../../../../public/white_icons/clock.svg');
          }

          #ofsted {
            background-image: url('../../../../public/white_icons/ofsted.svg');
          }

          #catchment {
            background-image: url('../../../../public/white_icons/world.svg');
          }

        }

        .slider-button.inactive {
          background-color: rgba(26, 39, 108, 0.1);
          color: #1A276C;

          .slider-text {
            font-weight: 500;
          }
        }


      }

      .go-back {
        margin-left: 3%;
        margin-right: 3%;
        margin-top: 3%;
        font-size: 0.9rem;
        color: #ED6B86;
        font-weight: 700;
        cursor: pointer;
      }

      .detail-table-title {
        margin-left: 3%;
        margin-right: 3%;
        margin-top: 2.5%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        h3 {
          font-size: 0.9rem;
          color: #ED6B86;
          font-weight: 500;
          // width: 18%;
        }

        .table-title-line {
          display: block;
          visibility: visible;
          width: 45%;
          max-width: 45%;
          margin-left: 2%;
          margin-right: 2%;
          border: 0.5px solid rgba(26, 39, 108, 0.15);
        }

        input {
          background-color: rgba(26, 39, 108, 0.05);
          border-radius: 10px;
          border: 1px solid rgba(26, 39, 108, 0.05);
          margin-left: 2%;
          font-size: 0.7rem;
          padding: 5px 15px 5px 15px;
          width: 25%;
        }

        .icon-box {
          width: 30px;
          height: 30px;
          border-radius: 10px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          // width: 10%;
          .icon {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
          }

          #table {
            background-image: url('../../../../public/white_icons/Blue/list.svg');

          }

          #map {
            background-image: url('../../../../public/white_icons/map.svg');
          }

        }

        .icon-box.active {
          background-color: #1A276C;

          #table {
            background-image: url('../../../../public/white_icons/table-beige.svg');

          }

          #map {
            background-image: url('../../../../public/white_icons/White/location.svg');

          }

        }

        .icon-box.inactive {
          background-color: rgba(26, 39, 108, 0.05);


        }
      }


    }

  }
}

.property-insight-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .property-insight-buttons {
    display: flex;
    flex-direction: row;

  }

  .insight-button {
    text-wrap: nowrap;
    font-size: 0.75rem;
    color: #1A276C;
    font-weight: 500;
    padding: 10px 40px 10px 40px;
    cursor: pointer;
  }

  .insight-button.active {
    background-color: white;
    font-weight: 600;
  }

  .insight-button.inactive {
    background-color: white;
    font-weight: 600;
    background-color: rgba(26, 39, 108, 0.07);
    color: rgba(26, 39, 108, 0.6);
    ;

  }

  #left {
    border-top-left-radius: 15px;
  }

  #right {
    border-top-right-radius: 15px;
  }

  .print-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px;
    border-radius: 10px;
    border: 2px solid rgba(26, 39, 108, 0.1);
    width: 80px;
    margin-right: 5%;

    .print-icon {
      background-image: url('../../../../public/white_icons/print.svg');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
    }

    h3 {
      color: #ED6B86;
      font-weight: 600;
      font-size: 0.75rem;
      margin-left: 5%;
    }
  }
}



@media screen and (max-width: 900px) {
  .insights-navigation {
    width: 90% !important;
  }

  .print-section {
    display: none !important;
  }

  .header-line,
  .table-divider {
    margin-top: 0% !important;
  }


}





@media screen and (max-width: 650px) {
  .property-insights-nav {
    flex-direction: column !important;
  }


}


@media screen and (max-width: 500px) {
  .property-insight-buttons {
    width: 100% !important;

    .insight-button {
      width: 50% !important;
      padding: 3% 0% 3% 0% !important;
      text-align: center !important;
    }
  }

  .summary-section {
    .row {
      // flex-direction: column !important;
      flex-wrap: nowrap !important;

      overflow: scroll;

      .individual-box {
        width: 100% !important;
        min-width: 200px !important
      }

    }
  }

  .box-highlights {
    .row {
      flex-direction: column !important;

      .column {
        width: 100% !important;
      }
    }
  }

  .table-title-line {
    display: none !important;
  }

}